import { Point } from '@innovyze/shared-utils/lib/pumpTools/DataSeries';
import {
  UnitsSystem,
  useIsFeatureEnabled,
  useSelectSettings,
} from '@innovyze/stylovyze';
import Color from 'color';
import Highcharts, { SVGRenderer } from 'highcharts';
import { cloneDeep } from 'lodash';
import { nanoid } from 'nanoid';
import * as React from 'react';
import styled from 'styled-components';
import { useGlobalization } from '../../../i18n';
import {
  MARKER_SIZE_MAP,
  MarkerSizes,
  MarkerTypes,
} from '../../../types/chart.types';
import {
  ChartInstanceRef,
  createChart,
  createContext,
  Status,
  useStableEventHandlers,
} from '../../core/_summaryze-chart';
import { getTheme, getThemeColor, Theme } from '../../core/utils/theme-utils';
import { convertToUnit } from '../../presets/insight-pump-performance-chart/utils';
import * as Options from './pump-performance-chart-options';
import { getPumpEfficiency, getSpecificEnergy } from './utils';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Chart
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const Chart = createChart('PumpPerformanceChartRoot');

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Chart Root
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface PumpPerformanceChartRootProps {
  children: React.ReactNode;
  enableHorizontalGrids?: boolean;
  enableVerticalGrids?: boolean;
  xAxisMin?: number;
  xAxisMax?: number;
  xAxisLabel?: string;
  yAxisLabel?: string;
  yAxisMin?: number;
  yAxisMax?: number;
  selectedTheme?: Theme;
  axisMode?: 'pressure' | 'head' | 'pressure-head';
  onSeriesVisibilityChange?: (id: string, type: 'show' | 'hide') => void;
  currentDataPoint?: PumpPerformanceDataPoint;
  setCurrentDataPoint?: React.Dispatch<
    React.SetStateAction<PumpPerformanceDataPoint>
  >;
  summaryTable?: React.JSX.Element;
}

type PumpPerformanceChartYAxisId = 'pressure' | 'head' | 'power' | 'efficiency';

type PumpPerformanceChartContext = Pick<
  PumpPerformanceChartRootProps,
  | 'enableHorizontalGrids'
  | 'enableVerticalGrids'
  | 'xAxisLabel'
  | 'xAxisMin'
  | 'xAxisMax'
  | 'yAxisLabel'
  | 'yAxisMin'
  | 'yAxisMax'
  | 'selectedTheme'
  | 'axisMode'
>;

type PumpPerformanceChartEventHandlers = Pick<
  PumpPerformanceChartRootProps,
  'onSeriesVisibilityChange'
>;

const [RootContextProvider, useRootContext] =
  createContext<PumpPerformanceChartContext>('RootContextProvider');

const removeSeriesRef = (
  seriesRef: React.MutableRefObject<Highcharts.Series>
) => {
  try {
    seriesRef.current?.remove();
    seriesRef.current = undefined;
  } catch (error) {
    console.warn('Failed to remove ref', error);
  }
};

const SummaryTableWrapper = (props: { summaryTable?: React.JSX.Element }) => {
  return <>{props?.summaryTable}</>;
};

const PumpPerformanceChartRoot = React.forwardRef<
  ChartInstanceRef,
  PumpPerformanceChartRootProps
>((props, ref): React.ReactElement => {
  const { t } = useGlobalization();
  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Event Handlers
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const eventHandlersRef =
    useStableEventHandlers<PumpPerformanceChartEventHandlers>({
      onSeriesVisibilityChange: props.onSeriesVisibilityChange,
    });

  const initialOptions = React.useMemo(() => {
    return Options.makeOptionsWithEventHandlers(
      Options.initialOptions,
      eventHandlersRef
    );
  }, [eventHandlersRef]);
  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  return (
    <RootContextProvider
      enableHorizontalGrids={props.enableHorizontalGrids}
      enableVerticalGrids={props.enableVerticalGrids}
      xAxisLabel={props.xAxisLabel}
      xAxisMin={props.xAxisMin}
      xAxisMax={props.xAxisMax}
      yAxisLabel={props.yAxisLabel}
      yAxisMin={props.yAxisMin}
      yAxisMax={props.yAxisMax}
      selectedTheme={props.selectedTheme}
      axisMode={props.axisMode}>
      <Chart.ChartRoot>
        {props?.summaryTable ? (
          <SummaryTableWrapper summaryTable={props?.summaryTable} />
        ) : null}

        <Chart.ChartInstance
          ref={ref}
          initialOptions={initialOptions}
          constructorFunction={Highcharts.chart}>
          {props.children}
        </Chart.ChartInstance>
        <ColorLegend.Root>
          <ColorLegend.LegendText>{t('Latest')}</ColorLegend.LegendText>
          <ColorLegend.LegendGradient />
          <ColorLegend.LegendText>{t('Oldest')}</ColorLegend.LegendText>
        </ColorLegend.Root>
      </Chart.ChartRoot>
    </RootContextProvider>
  );
});

PumpPerformanceChartRoot.displayName = 'PumpPerformanceChartRoot';

const ColorLegend = {
  Root: styled.div`
    align-items: center;
    bottom: 6px;
    display: flex;
    position: absolute;
    right: 12px;
  `,
  LegendText: styled.div`
    font-size: 11px;
    color: #666666;
  `,
  LegendGradient: styled.div`
    background: linear-gradient(
      to right,
      rgba(196, 196, 196, 1),
      rgba(196, 196, 196, 0.1)
    );
    height: 8px;
    margin: 5px;
    width: 40px;
    border-radius: 4px;
  `,
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Series Group Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface PumpPerformanceChartSeriesGroupProps {
  children: React.ReactNode;
  status?: Status;
}

const PumpPerformanceChartSeriesGroup = (
  props: PumpPerformanceChartSeriesGroupProps
): React.ReactElement => {
  const { t } = useGlobalization();
  const rootContext = useRootContext('StackableInstanceWrapper');
  const instanceRef = Chart.useInstance('PumpPerformanceChartSeriesGroup');
  const { companySettings } = useSelectSettings();
  const instanceSeriesProps =
    Chart.useInstanceSeriesProps<PumpPerformanceChartSeriesProps>(
      'PumpPerformanceChartSeriesGroup'
    );

  const displayMode = React.useMemo(
    () =>
      instanceSeriesProps.find((s) => s.type === 'scatter')?.displayMode ??
      'pressure',
    [instanceSeriesProps]
  );

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Set X axis labels
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const xAxisLabel = React.useMemo(() => {
    return t('Flow Rate');
  }, [t]);

  const xAxisUnit = React.useMemo(() => {
    const units: Set<string> = new Set();

    instanceSeriesProps.forEach((seriesProps) => {
      if (seriesProps.type === 'scatter' && seriesProps.xAxisUnit) {
        units.add(seriesProps.xAxisUnit);
      } else if (
        seriesProps.type === 'manufacturer-curve' &&
        seriesProps.xSeriesUnit
      ) {
        units.add(seriesProps.xSeriesUnit);
      }
    });

    return Array.from(units).join(',');
  }, [instanceSeriesProps]);

  React.useEffect(() => {
    let _xAxisLabel = rootContext.xAxisLabel || xAxisLabel;

    if (xAxisUnit) {
      _xAxisLabel += ` (${xAxisUnit})`;
    }
    instanceRef.current?.xAxis[0]?.update({ title: { text: _xAxisLabel } });
  }, [instanceRef, rootContext.xAxisLabel, xAxisLabel, xAxisUnit, t]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Set Y axis labels
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const yAxisLabels = React.useMemo(() => {
    const labels: Record<PumpPerformanceChartYAxisId, string> = {
      pressure: t('Pressure'),
      head: t('Head'),
      power: t('Power'),
      efficiency: t('Efficiency'),
    };
    return labels;
  }, [t]);

  const yAxisUnits = React.useMemo(() => {
    const units: Record<PumpPerformanceChartYAxisId, Set<string>> = {
      pressure: new Set(),
      power: new Set(),
      head: new Set(),
      efficiency: new Set(),
    };

    instanceSeriesProps.forEach((seriesProps) => {
      if (seriesProps.type === 'scatter' && seriesProps.yAxisUnit) {
        switch (seriesProps.subType) {
          case 'pressure':
          case 'pressure-head':
            units.pressure.add(seriesProps.yAxisUnit);
            break;
          case 'power':
            units.power.add(seriesProps.yAxisUnit);
            break;
        }
      } else if (
        seriesProps.type === 'manufacturer-curve' &&
        seriesProps.ySeriesUnit
      ) {
        switch (seriesProps.subType) {
          case 'pressure':
            // both pressure and head curves have the subType 'pressure'
            if (!seriesProps.id.startsWith('head_curve')) {
              units.pressure.add(seriesProps.ySeriesUnit);
            }
            break;
          case 'power':
            units.power.add(seriesProps.ySeriesUnit);
            break;
        }
      }
    });

    return {
      pressure: Array.from(units.pressure).join(','),
      power: Array.from(units.power).join(','),
      head: companySettings.UOM === 'Imperial' ? 'ft' : 'm',
      efficiency: '%',
    };
  }, [companySettings.UOM, instanceSeriesProps]);

  React.useEffect(() => {
    instanceRef.current?.yAxis?.forEach((yAxis) => {
      const yAxisId = yAxis.options.id as PumpPerformanceChartYAxisId;
      let yAxisLabel = yAxisLabels[yAxisId];
      if (rootContext.yAxisLabel) {
        // custom yAxis label
        if (
          (yAxisId === 'pressure' &&
            (displayMode === 'pressure' || displayMode === 'pressure-head')) ||
          (yAxisId === 'head' && displayMode === 'head')
        ) {
          yAxisLabel = rootContext.yAxisLabel;
        }
      }

      if (yAxisUnits[yAxisId]) {
        // add unit to yAxis label
        yAxisLabel += ` (${yAxisUnits[yAxisId]})`;
      }

      yAxis.update({ title: { text: yAxisLabel } });
    });
  }, [
    displayMode,
    instanceRef,
    rootContext.yAxisLabel,
    yAxisLabels,
    yAxisUnits,
    t,
  ]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets horizontal gridlines
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    instanceRef.current?.yAxis[0]?.update({
      gridLineWidth: rootContext.enableHorizontalGrids ? 1 : 0,
    });
  }, [instanceRef, rootContext.enableHorizontalGrids]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets vertical gridlines
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    instanceRef.current?.xAxis[0]?.update({
      gridLineWidth: rootContext.enableVerticalGrids ? 1 : 0,
    });
  }, [instanceRef, rootContext.enableVerticalGrids]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets X axis min and max
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    instanceRef.current?.xAxis[0]?.setExtremes(
      numberify(rootContext.xAxisMin),
      numberify(rootContext.xAxisMax)
    );
  }, [instanceRef, rootContext.xAxisMin, rootContext.xAxisMax]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets Y axis min and max
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    instanceRef.current?.yAxis?.forEach((yAxis) => {
      if (yAxis.options.id !== 'efficiency') {
        yAxis?.setExtremes(
          numberify(rootContext.yAxisMin),
          numberify(rootContext.yAxisMax)
        );
      }
    });
  }, [instanceRef, rootContext.yAxisMin, rootContext.yAxisMax]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets status
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (props.status === 'loading') {
      instanceRef.current?.showLoading(t('Loading'));
    } else if (props.status === 'rejected') {
      instanceRef.current?.showLoading(t('Failed to retrieve data'));
    } else {
      const scatterSeries = instanceSeriesProps.filter(
        (s): s is PumpPerformanceChartScatterSeriesProps => s.type === 'scatter'
      );

      const noData = scatterSeries.every(
        (s) => s.data === undefined || s.data.length === 0
      );

      if (noData) {
        instanceRef.current?.showLoading(t('No Data'));
      } else {
        instanceRef.current?.hideLoading();
      }
    }
  }, [props.status, instanceRef, instanceSeriesProps, t]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets Theme
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    const theme = rootContext.selectedTheme;
    const themeOptions = getTheme(theme ?? 'Default');
    instanceRef.current?.update({ ...themeOptions });
  }, [instanceRef, rootContext.selectedTheme]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  return <>{props.children}</>;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Series Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type PumpPerformanceChartSeriesProps =
  | PumpPerformanceChartScatterSeriesProps
  | PumpPerformanceChartManufacturerCurveSeriesProps
  | PumpPerformanceChartDesignPointProps
  | PumpPerformanceChartAorProps;

const PumpPerformanceChartSeries = (
  props: PumpPerformanceChartSeriesProps
): React.ReactElement | null => {
  const isPpcNewFeaturesEnabled = !!useIsFeatureEnabled(
    'info-360-ppc-new-features'
  );
  switch (props.type) {
    case 'scatter':
      return <PumpPerformanceChartScatterSeries {...props} />;
    case 'manufacturer-curve':
      return <PumpPerformanceChartManufacturerCurveSeries {...props} />;
    case 'design-point':
      return isPpcNewFeaturesEnabled ? (
        <PumpPerformanceChartDesignPoint {...props} />
      ) : null;
    case 'allowable-operating-range':
      return isPpcNewFeaturesEnabled ? (
        <PumpPerformanceChartAor {...props} />
      ) : null;
    default:
      return null;
  }
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface PumpPerformanceChartScatterSeriesProps {
  type: 'scatter';
  subType: 'pressure' | 'head' | 'pressure-head' | 'power';
  displayMode?: 'pressure' | 'head' | 'pressure-head'; // when subType is 'power', we don't know display mode from subType. DisplayMode is needed in this case.
  color?: string;
  data?: PumpPerformanceChartScatterSeriesData;
  lastKnownDataPoint?: PumpPerformanceDataPoint;
  error?: unknown;
  hidden?: boolean;
  id?: string;
  name?: string;
  status?: Status;
  xAxisSourceName?: string;
  xAxisUnit?: string;
  yAxisUnit?: string;
  markerSize?: MarkerSizes | `${MarkerSizes}`;
  markerType?: MarkerTypes | `${MarkerTypes}`;
  zIndex?: number;
  seriesType: 'flow-pressure' | 'flow-power';
  brakeHP?: number;
  brakeHPUnit?: string;
  onDataPointClick?: (dataPoint: PumpPerformanceDataPoint) => void;
}
export type PumpPerformanceDataPoint = {
  timestamp: number;
  x: number;
  y: number | null;
};

type PumpPerformanceChartScatterSeriesData = PumpPerformanceDataPoint[];

const PumpPerformanceChartScatterSeries = (
  props: PumpPerformanceChartScatterSeriesProps
): null => {
  const { t } = useGlobalization();
  const { companySettings } = useSelectSettings();
  const seriesRef = Chart.useSeries(props, 'PumpPerformanceChartSeries');
  const extraSeriesRef = React.useRef<Highcharts.Series>();
  const rootContext = useRootContext('PumpPerformanceChartSeries');
  const instanceRef = Chart.useInstance('PumpPerformanceChartSeries');

  const headUnit = React.useMemo(() => {
    return companySettings.UOM === 'Imperial' ? 'ft' : 'm';
  }, [companySettings.UOM]);

  const xAxisSourceName = React.useMemo(() => {
    return props.xAxisSourceName ?? t('Flow Rate');
  }, [props.xAxisSourceName, t]);

  const yAxisLabels = React.useMemo(() => {
    return {
      pressure: t('Pressure'),
      head: t('Head'),
      power: t('Power'),
    };
  }, [t]);

  const yAxisSourceName = React.useMemo(() => {
    return props.subType === 'pressure-head'
      ? yAxisLabels.pressure
      : yAxisLabels[props.subType];
  }, [props.subType, yAxisLabels]);

  const dateFormat = React.useMemo(
    () =>
      companySettings.dateFormat
        .replace('DD', '%e')
        .replace('MM', '%m')
        .replace('YYYY', '%Y'),
    [companySettings.dateFormat]
  );

  const timeFormat = React.useMemo(
    () => (companySettings.hourCycle12 ? '%I:%M %p' : '%H:%M'),
    [companySettings.hourCycle12]
  );

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   *	Sets pressure-head series
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (props.subType === 'pressure-head') {
      extraSeriesRef.current = instanceRef.current?.addSeries({
        type: 'scatter',
        yAxis: 'head',
        id: nanoid(),
        enableMouseTracking: false,
        showInLegend: false,
        marker: {
          enabled: false,
          states: { hover: { enabled: false } },
        },
      });

      seriesRef.current?.update({
        type: 'scatter',
        yAxis: 'pressure',
        events: {
          show: function () {
            extraSeriesRef.current?.show();
          },
          hide: function () {
            extraSeriesRef.current?.hide();
          },
        },
      });

      return () => {
        removeSeriesRef(extraSeriesRef);
      };
    }
  }, [instanceRef, seriesRef, extraSeriesRef, props.subType]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series Y axis
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    seriesRef.current?.update({
      yAxis: props.subType !== 'pressure-head' ? props.subType : 'pressure',
    } as Highcharts.SeriesOptionsType);
  }, [seriesRef, props.subType]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series name
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    seriesRef.current?.update({
      name: props.name,
    } as Highcharts.SeriesOptionsType);
  }, [seriesRef, props.name]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series marker type
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    seriesRef.current?.update({
      marker: { symbol: props.markerType ?? MarkerTypes.Circle },
    } as Highcharts.SeriesOptionsType);
  }, [seriesRef, props.markerType]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series marker size
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    seriesRef.current?.update({
      marker: {
        radius: props.markerSize
          ? MARKER_SIZE_MAP[props.markerSize]
          : undefined,
      },
    } as Highcharts.SeriesOptionsType);
  }, [seriesRef, props.markerSize]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series visibility
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    seriesRef.current?.update({
      visible: !props.hidden,
    } as Highcharts.SeriesOptionsType);
  }, [seriesRef, props.hidden]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series data
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    const isLastKnownDataPoint = (x: number, y: number, timestamp: number) => {
      if (
        props.lastKnownDataPoint?.timestamp === undefined ||
        props.lastKnownDataPoint?.x === undefined ||
        props.lastKnownDataPoint?.y === undefined
      ) {
        return false;
      }
      return (
        x === props.lastKnownDataPoint.x &&
        y === props.lastKnownDataPoint.y &&
        timestamp === props.lastKnownDataPoint.timestamp
      );
    };

    const seriesColor =
      props?.color ??
      getThemeColor(
        rootContext.selectedTheme,
        seriesRef.current?.options?.index
      ) ??
      (seriesRef.current?.options?.color as string | undefined);

    let data: Highcharts.PointOptionsObject[] = [];
    if (props.data) {
      let colors: Map<number, string> | undefined = undefined;

      if (seriesColor) {
        const timestamps = props.data.map(({ timestamp }) => timestamp);
        const gradient = makeColorGradient(seriesColor, 10, 0.1);
        colors = mapGradientToTimestamps(gradient, timestamps);
      }

      data = props.data.map(({ x, y, timestamp }) => {
        const head = convertPressureToHead(
          y,
          props.yAxisUnit,
          companySettings.UOM
        );

        const pumpEfficiency = getPumpEfficiency({
          pumpFlow: { value: x, unit: props.xAxisUnit },
          breakHP: { value: props.brakeHP, unit: props.brakeHPUnit },
          dischargePressure: { value: y, unit: props.yAxisUnit },
          seriesType: props.seriesType,
        });

        const specificEnergy = getSpecificEnergy({
          pumpFlow: { value: x, unit: props.xAxisUnit },
          breakHP: { value: props.brakeHP, unit: props.brakeHPUnit },
          seriesType: props.seriesType,
        });

        const defaultData: Highcharts.PointOptionsObject = {
          color: colors?.get(timestamp),
          custom:
            props.subType === 'pressure-head' || props.subType === 'head'
              ? {
                  timestamp,
                  pumpEfficiency: pumpEfficiency.value,
                  specificEnergy: specificEnergy.value,
                  specificEnergyUnit: specificEnergy.unit,
                  name: props.name,
                  head,
                }
              : {
                  timestamp,
                  pumpEfficiency: pumpEfficiency.value,
                  specificEnergy: specificEnergy.value,
                  specificEnergyUnit: specificEnergy.unit,
                  name: props.name,
                },
          x,
          y: props.subType === 'head' ? head : y,
          className: `x:${Math.round(x * 100) / 100}-y:${Math.round(y * 100) / 100}`,
        };

        if (isLastKnownDataPoint(x, y, timestamp)) {
          return {
            ...defaultData,
            custom: {
              ...defaultData.custom,
              name: `${props.name} (${t('latest')})`,
            },
            marker: {
              radius: MARKER_SIZE_MAP['XX-Large'],
              symbol: MarkerTypes.Circle,
              lineWidth: 3,
              lineColor: '#FFE54D',
            },
          };
        } else {
          return defaultData;
        }
      });
    }

    if (seriesColor) {
      seriesRef.current?.update({
        type: 'scatter',
        data,
        color: seriesColor,
      });
    } else {
      seriesRef.current?.update({
        type: 'scatter',
        data,
      });
    }

    if (props.subType === 'pressure-head') {
      const headData = data?.map((_data) => ({
        ..._data,
        y: _data.custom?.head,
      }));

      extraSeriesRef.current?.update({
        type: 'scatter',
        data: headData,
      });
    }
  }, [
    companySettings.UOM,
    props.brakeHP,
    props.brakeHPUnit,
    props?.color,
    props.data,
    props.lastKnownDataPoint?.timestamp,
    props.lastKnownDataPoint?.x,
    props.lastKnownDataPoint?.y,
    props.name,
    props.seriesType,
    props.subType,
    props.xAxisUnit,
    props.yAxisUnit,
    rootContext.selectedTheme,
    seriesRef,
    t,
  ]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets tooltip
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    seriesRef.current?.update({
      tooltip: {
        headerFormat: '',
        pointFormat: [
          '<span style="color:{point.color}">● </span>',
          '<b style="font-size: 10px">{point.custom.name}</b><br />',
          `${xAxisSourceName}: {point.x:.2f} ${props.xAxisUnit}<br />`,
          `${yAxisSourceName}: {point.y:.2f} ${
            props.subType === 'head' ? headUnit : props.yAxisUnit
          }<br />`,
          ...(props.subType === 'pressure-head'
            ? [`${yAxisLabels.head}: {point.custom.head:.2f} ${headUnit}<br />`]
            : []),
          ...(props.seriesType === 'flow-pressure'
            ? [
                `${t('Efficiency')}: {point.custom.pumpEfficiency:.2f}%<br />`,
                `${t('Specific Energy')}: {point.custom.specificEnergy:.2f} {point.custom.specificEnergyUnit}<br />`,
              ]
            : []),
          `{point.custom.timestamp:${dateFormat} - ${timeFormat}}`,
        ].join(''),
      },
      events: {
        click: (event) => {
          const e = event as any;
          const { x, y } = event.point;
          const { timestamp } = e.point.custom;
          if (props.onDataPointClick) {
            props.onDataPointClick({ timestamp, x, y });
          }
        },
      },
    } as Highcharts.SeriesScatterOptions);
  }, [
    companySettings.UOM,
    dateFormat,
    headUnit,
    props.name,
    props.subType,
    props.xAxisSourceName,
    props.xAxisUnit,
    props.yAxisUnit,
    seriesRef,
    t,
    timeFormat,
    xAxisSourceName,
    yAxisLabels.head,
    yAxisLabels.pressure,
    yAxisSourceName,
  ]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  return null;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type PumpPerformanceChartManufacturerCurveSeriesProps = {
  type: 'manufacturer-curve';
  subType: 'pressure' | 'power' | 'efficiency' | 'head';
  color?: string;
  markerSize?: MarkerSizes | `${MarkerSizes}`;
  markerType?: MarkerTypes | `${MarkerTypes}`;
  data?: PumpPerformanceChartManufacturerCurveSeriesData;
  error?: unknown;
  hidden?: boolean;
  id?: string;
  name?: string;
  status?: Status;
  zIndex?: number;
  xSeriesUnit?: string | null;
  ySeriesUnit?: string | null;
};

type PumpPerformanceChartManufacturerCurveSeriesData =
  | { x: number; y: number | null }[]
  | [x: number, y: number | null][];

const PumpPerformanceChartManufacturerCurveSeries = (
  props: PumpPerformanceChartManufacturerCurveSeriesProps
): null => {
  const { t } = useGlobalization();
  const seriesRef = Chart.useSeries(props, 'PumpPerformanceChartSeries');
  const extraSeriesRef = React.useRef<Highcharts.Series>();
  const instanceRef = Chart.useInstance('PumpPerformanceChartSeries');
  const { axisMode } = useRootContext('PumpPerformanceChartSeries');
  const { companySettings } = useSelectSettings();
  const isPpcNewFeaturesEnabled = !!useIsFeatureEnabled(
    'info-360-ppc-new-features'
  );
  const headUnit = React.useMemo(() => {
    return companySettings.UOM === 'Imperial' ? 'ft' : 'm';
  }, [companySettings.UOM]);
  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   *	Sets manufacturer head series
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (axisMode === 'pressure-head') {
      extraSeriesRef.current = instanceRef.current?.addSeries({
        type: 'scatter',
        yAxis: 'head',
        id: nanoid(),
        enableMouseTracking: false,
        showInLegend: false,
        marker: {
          enabled: false,
          states: { hover: { enabled: false } },
        },
      });

      seriesRef.current?.update({
        type: 'line',
        yAxis: props.subType,
        events: {
          show: function () {
            extraSeriesRef.current?.show();
          },
          hide: function () {
            extraSeriesRef.current?.hide();
          },
        },
      } as Highcharts.SeriesOptionsType);

      return () => {
        removeSeriesRef(extraSeriesRef);
      };
    }
  }, [instanceRef, axisMode, seriesRef, extraSeriesRef, props.subType]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets manufacturer curve name
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    seriesRef.current?.update({
      name: props.name,
    } as Highcharts.SeriesLineOptions);
  }, [seriesRef, props.name]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series marker type
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (!isPpcNewFeaturesEnabled) return;
    seriesRef.current?.update({
      marker: { symbol: props.markerType ?? MarkerTypes.Circle },
    } as Highcharts.SeriesOptionsType);
  }, [seriesRef, props.markerType, isPpcNewFeaturesEnabled]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series marker size
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (!isPpcNewFeaturesEnabled) return;
    seriesRef.current?.update({
      marker: {
        radius: props.markerSize
          ? MARKER_SIZE_MAP[props.markerSize]
          : undefined,
      },
    } as Highcharts.SeriesOptionsType);
  }, [seriesRef, props.markerSize, isPpcNewFeaturesEnabled]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets manufacturer curve data
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    const getCurveColor = (
      subType: 'pressure' | 'power' | 'efficiency' | 'head',
      color?: string
    ): string => {
      const colors: Record<
        PumpPerformanceChartManufacturerCurveSeriesProps['subType'],
        string
      > = {
        head: '#017ca0',
        pressure: '#00abd1',
        power: '#aa0000',
        efficiency: '#4b7e03',
      };
      const curveColor = color ?? colors[subType];
      return curveColor;
    };

    const data = cloneDeep(props.data ?? []); // deep clone is needed to avoid error in seriesRef.current?.update
    if (props.subType === 'pressure') {
      // Pressure or Head Curve
      const headData = data.map(
        (d: { x: number; y: number | null } | [number, number | null]) => {
          const x = Array.isArray(d) ? d[0] : d.x;
          const y = Array.isArray(d) ? d[1] : d.y;
          return [
            x,
            convertPressureToHead(y, props.ySeriesUnit, companySettings.UOM),
          ] as [number, number | null];
        }
      );
      if (axisMode === 'pressure-head') {
        const dataWithCustom = data.map(
          (d: { x: number; y: number | null } | [number, number | null]) => {
            const x = Array.isArray(d) ? d[0] : d.x;
            const y = Array.isArray(d) ? d[1] : d.y;
            return {
              x,
              y,
              custom: {
                head: convertPressureToHead(
                  y,
                  props.ySeriesUnit,
                  companySettings.UOM
                ),
              },
            };
          }
        );
        seriesRef.current?.update({
          type: 'line',
          yAxis: 'pressure',
          data: dataWithCustom,
          color: getCurveColor(props.subType, props.color),
          className: `locatorCurve_pressure`,
        });
        extraSeriesRef.current?.update({
          type: 'scatter',
          yAxis: 'head',
          data: headData,
        });
      } else if (axisMode === 'head') {
        seriesRef.current?.update({
          type: 'line',
          yAxis: 'head',
          data: headData,
          color: getCurveColor(props.subType, props.color),
          className: `locatorCurve_head`,
        });
      } else {
        // axisMode is 'pressure'
        seriesRef.current?.update({
          type: 'line',
          yAxis: 'pressure',
          data: data,
          color: getCurveColor(props.subType, props.color),
          className: `locatorCurve_pressure`,
        });
      }
    } else {
      // Power or Efficiency Curve (there is no subType 'head')
      seriesRef.current?.update({
        type: 'line',
        yAxis: props.subType,
        data: data,
        color: getCurveColor(props.subType, props.color),
        className: `locatorCurve_${props.subType}`,
      });
    }
  }, [
    companySettings.UOM,
    props.color,
    props.data,
    props.subType,
    props.ySeriesUnit,
    axisMode,
    seriesRef,
  ]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets tooltip
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    let pointFormat = `${t('Flow Rate')}: {point.x:.2f} ${props.xSeriesUnit}<br />`;
    if (props.subType === 'efficiency') {
      pointFormat = `Efficiency: {point.y:.2f} ${props.ySeriesUnit}<br />`;
    } else if (props.subType === 'power') {
      pointFormat = `Power: {point.y:.2f} ${props.ySeriesUnit}<br />`;
    } else {
      // Pressure or Head Curve
      if (axisMode === 'pressure') {
        pointFormat = `Pressure: {point.y:.2f} ${props.ySeriesUnit}<br />`;
      } else if (axisMode === 'head') {
        pointFormat = `Head: {point.y:.2f} ${headUnit}<br />`;
      } else if (axisMode === 'pressure-head') {
        pointFormat = [
          `Pressure: {point.y:.2f} ${props.ySeriesUnit}<br />`,
          `Head: {point.custom.head:.2f} ${headUnit}<br />`,
        ].join('');
      }
    }
    try {
      seriesRef.current?.update({
        tooltip: {
          headerFormat: [
            '<span style="color:{point.color}">● </span>',
            `<b style="font-size: 10px">${props.name}</b><br />`,
          ].join(''),
          pointFormat,
        },
      } as Highcharts.SeriesScatterOptions);
    } catch (error) {
      console.error('Failed to update curve tooltip', error);
    }
  }, [
    axisMode,
    headUnit,
    props.name,
    props.subType,
    props.xSeriesUnit,
    props.ySeriesUnit,
    seriesRef,
    t,
  ]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series visibility
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    seriesRef.current?.update({
      visible: !props.hidden,
    } as Highcharts.SeriesOptionsType);
  }, [seriesRef, props.hidden]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  return null;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Generate color gradient
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const numberify = (something: unknown): number | undefined => {
  if (typeof something === 'number') return something;
  if (typeof something === 'string' && something.trim() !== '')
    return Number(something);

  return undefined;
};

const makeColorGradient = (color: string, shades: number, ratio = 0.8) => {
  return new Array<string>(shades)
    .fill(color)
    .map((_color, index) => new Color(_color).lighten(index * ratio).hex())
    .reverse();
};

const mapGradientToTimestamps = (gradient: string[], timestamps: number[]) => {
  const colors: Map<number, string> = new Map();

  timestamps.sort().forEach((timestamp, index) => {
    const colorIndex = Math.floor(
      (index * gradient.length) / timestamps.length
    );
    colors.set(timestamp, gradient[colorIndex]);
  });

  return colors;
};

const convertPressureToHead = (
  value: number | null,
  unit: string | undefined,
  UOM: UnitsSystem
): number | null => {
  // convert to ft or m
  if (unit === undefined || value === null) return value;
  try {
    const targetUnit = UOM === 'Imperial' ? 'ft' : 'm';
    const convertedValue = convertToUnit(value, unit, targetUnit);
    return convertedValue;
  } catch (e) {
    console.warn('Failed to convert pressure to head', e);
    return value;
  }
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Design Point
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type PumpPerformanceChartDesignPointProps = {
  type: 'design-point';
  flowRate?: number;
  flowRateUnit?: string;
  pressure?: number;
  pressureUnit?: string;
  efficiency?: number;
  efficiencyUnit?: string;
  brakeHP?: number;
  brakeHPUnit?: string;
  chartXValue?: number;
  chartXUnit?: string;
  chartYValue?: number;
  chartYUnit?: string;
};

const PumpPerformanceChartDesignPoint = (
  props: PumpPerformanceChartDesignPointProps
): null => {
  const { t } = useGlobalization();
  const instanceRef = Chart.useInstance('PumpPerformanceChartSeries');
  const { axisMode } = useRootContext('PumpPerformanceChartSeries');
  const designPointRef = React.useRef<Highcharts.Series>();
  const { companySettings } = useSelectSettings();
  const headUnit = React.useMemo(() => {
    return companySettings.UOM === 'Imperial' ? 'ft' : 'm';
  }, [companySettings.UOM]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   *	Sets design point series
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
  React.useEffect(() => {
    designPointRef.current = instanceRef.current?.addSeries({
      type: 'scatter',
      yAxis: 'pressure',
      id: nanoid(),
      name: t('Pump Rated Capacity'),
      showInLegend: false,
      marker: {
        radius: MARKER_SIZE_MAP['XX-Large'],
        symbol: MarkerTypes.Circle,
      },
      color: '#83bc40',
    });
    return () => {
      removeSeriesRef(designPointRef);
    };
  }, [instanceRef, designPointRef, t]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series data
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
  React.useEffect(() => {
    if (props.chartXValue === undefined || props.chartYValue === undefined)
      return;
    designPointRef.current?.update({
      data: [{ x: props.chartXValue, y: props.chartYValue }],
    } as Highcharts.SeriesScatterOptions);
  }, [props.chartXValue, props.chartYValue, props.chartYUnit]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets Y axis and tooltip
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
  React.useEffect(() => {
    const getPointFormat = () => {
      const pointFormatArray = [
        `${t('Flow Rate')}: {point.x:.2f} ${props.chartXUnit}`,
      ];
      if (props.flowRateUnit !== props.chartXUnit) {
        // display the original flow rate value and unit
        pointFormatArray.push(` (${props.flowRate} ${props.flowRateUnit})`);
      }
      if (axisMode === 'pressure') {
        pointFormatArray.push(
          '<br />',
          `${t('Pressure')}: {point.y:.2f} ${props.chartYUnit}`
        );
      } else if (axisMode === 'head') {
        pointFormatArray.push(
          '<br />',
          `${t('Pressure')}: {point.y:.2f} ${props.chartYUnit} (${props.pressure} ${props.pressureUnit})`
        );
      } else {
        // axisMode is 'pressure-head'
        const head = convertPressureToHead(
          props.pressure,
          props.pressureUnit,
          companySettings.UOM
        );
        pointFormatArray.push(
          '<br />',
          `${t('Pressure')}: {point.y:.2f} ${props.chartYUnit} (${head.toFixed(2)} ${headUnit})`
        );
      }
      if (props.efficiency && props.efficiencyUnit) {
        pointFormatArray.push(
          '<br />',
          `${t('Efficiency')}: ${props.efficiency?.toFixed(2)} ${props.efficiencyUnit}`
        );
      }
      if (props.brakeHP && props.brakeHPUnit) {
        pointFormatArray.push(
          '<br />',
          `${t('Brake HP')}: ${props.brakeHP?.toFixed(2)} ${props.brakeHPUnit}`
        );
      }
      return pointFormatArray.join('');
    };

    const newYAxis = axisMode !== 'pressure-head' ? axisMode : 'pressure';
    designPointRef.current?.update({
      yAxis: newYAxis,
      tooltip: {
        headerFormat: [
          '<span style="color:{point.color}">● </span>',
          `<b style="font-size: 10px">${t('Pump Rated Capacity')}</b><br />`,
        ].join(''),
        pointFormat: getPointFormat(),
      },
    } as Highcharts.SeriesScatterOptions);
  }, [
    axisMode,
    companySettings.UOM,
    headUnit,
    props.brakeHP,
    props.brakeHPUnit,
    props.chartXUnit,
    props.chartYUnit,
    props.efficiency,
    props.efficiencyUnit,
    props.flowRate,
    props.flowRateUnit,
    props.pressure,
    props.pressureUnit,
    t,
  ]);

  return null;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Allowable Operating Range (AOR) and Preferred Operating Range (POR)
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type PumpPerformanceChartAorProps = {
  type: 'allowable-operating-range';
  aor: { min: Point; max: Point };
  por: { min: Point; max: Point };
  xAxisUnit: string;
  yAxisUnit: string;
};

const PumpPerformanceChartAor = (props: PumpPerformanceChartAorProps): null => {
  const { t } = useGlobalization();
  const instanceRef = Chart.useInstance('PumpPerformanceChartSeries');
  const { axisMode } = useRootContext('PumpPerformanceChartSeries');
  const aOrRef = React.useRef<Highcharts.Series>();
  const pOrRef = React.useRef<Highcharts.Series>();

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   *	Sets AOR series
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
  React.useEffect(() => {
    if (!Number.isNaN(props.aor.max.x) || !Number.isNaN(props.aor.min.x)) {
      // Define the custom "x" symbol path
      SVGRenderer.prototype.symbols.cross = function (x, y, w, h) {
        return [
          'M',
          x,
          y,
          'L',
          x + w,
          y + h,
          'M',
          x + w,
          y,
          'L',
          x,
          y + h,
          'z',
        ];
      };
      aOrRef.current = instanceRef.current?.addSeries({
        type: 'scatter',
        yAxis: 'pressure',
        id: nanoid(),
        name: t('AOR'),
        marker: {
          radius: MARKER_SIZE_MAP['XX-Large'],
          symbol: 'cross',
          lineColor: null,
          lineWidth: 3,
        },
        color: '#aa0000',
      });
      return () => {
        removeSeriesRef(aOrRef);
      };
    }
  }, [instanceRef, props.aor.max.x, props.aor.min.x, t]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   *	Sets POR series
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
  React.useEffect(() => {
    if (!Number.isNaN(props.por.max.x) || !Number.isNaN(props.por.min.x)) {
      pOrRef.current = instanceRef.current?.addSeries({
        type: 'scatter',
        yAxis: 'pressure',
        id: nanoid(),
        name: t('POR'),
        marker: {
          radius: MARKER_SIZE_MAP['XX-Large'],
          symbol: MarkerTypes.Triangle,
        },
        color: '#4a7e04',
      });
      return () => {
        removeSeriesRef(pOrRef);
      };
    }
  }, [instanceRef, props.por.max.x, props.por.min.x, t]);

  const newYAxis = axisMode !== 'pressure-head' ? axisMode : 'pressure';
  const yAxisLabels: Partial<Record<PumpPerformanceChartYAxisId, string>> = {
    pressure: t('Pressure'),
    head: t('Head'),
  };
  const yAxisLabel = yAxisLabels[newYAxis];

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets AOR data, yAxis and tooltip
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
  React.useEffect(() => {
    const data: { x: number; y: number }[] = [];
    if (!Number.isNaN(props.aor.max.x)) {
      data.push({
        x: props.aor.max.x,
        y: props.aor.max.y,
      });
    }
    if (!Number.isNaN(props.aor.min.x)) {
      data.push({
        x: props.aor.min.x,
        y: props.aor.min.y,
      });
    }
    if (data.length > 0) {
      aOrRef.current?.update({
        data,
        yAxis: newYAxis,
        tooltip: {
          headerFormat: [
            '<span style="color:{point.color}">● </span>',
            `<b style="font-size: 10px">${t('AOR')}</b><br />`,
          ].join(''),
          pointFormat: [
            `${t('Flow Rate')}: {point.x:.2f} ${props.xAxisUnit}`,
            '<br />',
            `${yAxisLabel}: {point.y:.2f} ${props.yAxisUnit}`,
          ].join(''),
        },
      } as Highcharts.SeriesScatterOptions);
    }
  }, [
    newYAxis,
    props.aor.max.x,
    props.aor.max.y,
    props.aor.min.x,
    props.aor.min.y,
    props.xAxisUnit,
    props.yAxisUnit,
    t,
    yAxisLabel,
  ]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets POR data, yAxis and tooltip
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
  React.useEffect(() => {
    const data: { x: number; y: number }[] = [];
    if (!Number.isNaN(props.por.max.x)) {
      data.push({
        x: props.por.max.x,
        y: props.por.max.y,
      });
    }
    if (!Number.isNaN(props.por.min.x)) {
      data.push({
        x: props.por.min.x,
        y: props.por.min.y,
      });
    }
    if (data.length > 0) {
      pOrRef.current?.update({
        data,
        yAxis: newYAxis,
        tooltip: {
          headerFormat: [
            '<span style="color:{point.color}">● </span>',
            `<b style="font-size: 10px">${t('POR')}</b><br />`,
          ].join(''),
          pointFormat: [
            `${t('Flow Rate')}: {point.x:.2f} ${props.xAxisUnit}`,
            '<br />',
            `${yAxisLabel}: {point.y:.2f} ${props.yAxisUnit}`,
          ].join(''),
        },
      } as Highcharts.SeriesScatterOptions);
    }
  }, [
    newYAxis,
    props.por.max.x,
    props.por.max.y,
    props.por.min.x,
    props.por.min.y,
    props.xAxisUnit,
    props.yAxisUnit,
    t,
    yAxisLabel,
  ]);

  return null;
};
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export {
  PumpPerformanceChartRoot,
  PumpPerformanceChartSeries,
  PumpPerformanceChartSeriesGroup,
};

export type {
  PumpPerformanceChartAorProps,
  PumpPerformanceChartContext,
  PumpPerformanceChartDesignPointProps,
  PumpPerformanceChartEventHandlers,
  PumpPerformanceChartManufacturerCurveSeriesData,
  PumpPerformanceChartManufacturerCurveSeriesProps,
  PumpPerformanceChartRootProps,
  PumpPerformanceChartScatterSeriesData,
  PumpPerformanceChartScatterSeriesProps,
  PumpPerformanceChartSeriesGroupProps,
  PumpPerformanceChartSeriesProps,
};
