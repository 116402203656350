/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Color from 'color';
import Highcharts, { dateFormat, numberFormat } from 'highcharts';
import type {
  AsyncSensorData,
  SensorDataRecords,
} from '../../../core/types/data.types';

import type { PumpPerformanceSeries } from '../../../core/types/pumpPerformance.types';
import { readingIndex } from '../../../core/utils/data.utils';
import { isSeriesVisible } from '../../../core/utils/hiddenSeries.utils';
import {
  ManufacturerCurve,
  MarkerTypes,
  MARKER_SIZE_MAP,
  Readings,
} from '../../../types';
import type { HiddenSeries } from '../../../types/chartState';
import { PumpPerformanceChartDisplayOptions } from '../../../types/pumpPerformanceChart.types';

type DataRecords = Record<
  'x' | 'downstream' | 'upstream',
  AsyncSensorData | undefined
>;

const colors = {
  'flow-pressure': '#017CA0',
  'flow-power': '#AA0000',
  'flow-efficiency': '#4B7E03',
};

const pressureToHead: Record<string, number> = {
  psi: 2.31,
  'kg/cm2': 10,
  kpa: 0.1019,
  bar: 10.2,
  m: 1,
};

const convertToHead = (value: number, unit?: string): number => {
  if (!unit) {
    throw new Error('Cannot convert Pressure to Head. Data unit missing.');
  }

  const multiplier = pressureToHead[unit.toLowerCase()];

  if (multiplier === undefined) {
    throw new Error(
      `Cannot convert Pressure to Head. Invalid data unit.
			Got: ${unit}.
			Expected: ${Object.keys(pressureToHead).join(', ')}.`
    );
  }

  return value * multiplier;
};

// Even tho the chart is only rendered when data is loaded, sometimes
// the component gets call right before storing the data on redux.
// In those cases, the convertToHead function will throw because
// unit is not set.
// This function is used to make sure that the chart doesn't break
// before data is loaded.
const hasLoaded = (data: AsyncSensorData | undefined): boolean => {
  if (!data) return false;
  return data.status === 'rejected' || data.status === 'resolved';
};

const makeYAxisOptions = (
  series: PumpPerformanceSeries[],
  seriesDataRecords: DataRecords[],
  curves: ManufacturerCurve[] = [],
  displayOptions?: PumpPerformanceChartDisplayOptions,
  accountUnitSystem?: 'Metric' | 'Imperial'
) => {
  const { mode = 'pressure', showYGrid, yRange, yLabel } = displayOptions ?? {};
  let yAxisOptions = [] as Highcharts.YAxisOptions[];
  let pressureUnit: string | undefined = undefined;
  let powerUnit: string | undefined = undefined;

  const seriesTypes = new Set(
    series.map((_series) => _series.type ?? 'flow-pressure')
  );

  const curveTypes = new Set(
    curves.map((_curve) => _curve.type ?? 'flow-pressure')
  );

  const pressureSeriesIndex = series.findIndex(
    (series) => series.type !== 'flow-power'
  );

  const powerSeriesIndex = series.findIndex(
    (series) => series.type === 'flow-power'
  );

  const powerCurveIndex = curves.findIndex(
    (curve) => curve.type === 'flow-power'
  );

  if (pressureSeriesIndex !== -1) {
    pressureUnit =
      seriesDataRecords[pressureSeriesIndex].downstream?.data?.unit;
  }

  if (powerSeriesIndex !== -1) {
    powerUnit = seriesDataRecords[powerSeriesIndex].downstream?.data?.unit;
  }

  if (!powerUnit && powerCurveIndex !== -1) {
    powerUnit = curves[powerCurveIndex].unit;
  }

  if (
    (seriesTypes.has('flow-pressure') && mode !== 'head') ||
    curveTypes.has('flow-pressure')
  ) {
    yAxisOptions = [
      {
        ...yRange,
        gridLineWidth: showYGrid ? 1 : 0,
        id: 'flow-pressure',
        opposite: false,
        title: {
          style: {
            color: colors['flow-pressure'],
          },
          text: yLabel || `Pressure${pressureUnit ? ` (${pressureUnit})` : ''}`,
        },
      },
    ];
  }

  if (seriesTypes.has('flow-pressure') && mode !== 'pressure') {
    const options: Highcharts.YAxisOptions = {
      ...yRange,
      gridLineWidth: 0,
      id: 'flow-head',
      opposite: false,
      title: {
        style: {
          color: colors['flow-pressure'],
        },
        text: `Head (${accountUnitSystem === 'Imperial' ? 'ft' : 'm'})`,
      },
    };

    if (mode === 'pressure-head') {
      // Id must be changed or highcharts won't update the formatter function correctly
      options.id = `linked-${options.id}`;
      options.linkedTo = 0;
      options.labels = {
        formatter: function (ctx) {
          if (!hasLoaded(seriesDataRecords[0].downstream)) {
            return `${ctx.value}`;
          }
          const unit = seriesDataRecords[0].downstream!.data!.unit;
          if (!unit) {
            return `${ctx.value}`;
          }
          const value = convertToHead(Number(ctx.value), unit);
          return Number.isInteger(value) ? `${value}` : numberFormat(value, 2);
        },
      };
    }

    yAxisOptions.push(options);
  }

  if (seriesTypes.has('flow-power') || curveTypes.has('flow-power')) {
    const isMainAxis = yAxisOptions.length === 0;
    const defaultAxisLabel = `Power${powerUnit ? ` (${powerUnit})` : ''}`;

    yAxisOptions.push({
      ...yRange,
      gridLineWidth: isMainAxis ? (showYGrid ? 1 : 0) : 0,
      id: 'flow-power',
      opposite: true,
      title: {
        style: {
          color: colors['flow-power'],
        },
        text: isMainAxis ? yLabel || defaultAxisLabel : defaultAxisLabel,
      },
    });
  }

  if (curveTypes.has('flow-efficiency')) {
    yAxisOptions.push({
      gridLineWidth: 0,
      min: 0,
      max: 100,
      id: 'flow-efficiency',
      opposite: true,
      endOnTick: false,
      title: {
        style: {
          color: colors['flow-efficiency'],
        },
        text: 'Efficiency (%)',
      },
    });
  }

  return yAxisOptions;
};

export const generatePumpPerformanceOptions = (
  sensorDataRecords: SensorDataRecords,
  isBoostModuleEnabled: boolean,
  pumpPerformanceSeries: PumpPerformanceSeries[],
  hiddenSeries: HiddenSeries,
  displayOptions?: PumpPerformanceChartDisplayOptions,
  manufacturerCurves?: ManufacturerCurve[],
  dateFormatString?: string,
  hourCycle12?: boolean,
  accountUnitSystem?: 'Metric' | 'Imperial'
): Highcharts.Options => {
  const seriesDataRecords = pumpPerformanceSeries.map((series) => {
    const { dataSource } = series;
    const records: DataRecords = {
      x: sensorDataRecords[dataSource.x.sensorId]?.[dataSource.x.resolution],
      downstream: undefined,
      upstream: undefined,
    };

    if (dataSource.downstream) {
      records.downstream =
        sensorDataRecords[dataSource.downstream.sensorId]?.[
          dataSource.downstream.resolution
        ];
    }

    if (dataSource.upstream) {
      records.upstream =
        sensorDataRecords[dataSource.upstream.sensorId]?.[
          dataSource.upstream.resolution
        ];
    }

    return records;
  });

  const chartOptions: Highcharts.ChartOptions = {
    type: 'scatter',
    zoomType: 'xy',
  };

  const boostOptions: Highcharts.BoostOptions = {
    enabled: isBoostModuleEnabled,
    useGPUTranslations: isBoostModuleEnabled,
  };

  const yAxisOptions = makeYAxisOptions(
    pumpPerformanceSeries,
    seriesDataRecords,
    manufacturerCurves,
    displayOptions,
    accountUnitSystem
  );

  const xUnit = seriesDataRecords[0].x?.data?.unit;

  const xAxisOptions: Highcharts.XAxisOptions = {
    title: {
      text: displayOptions?.xLabel || `Flow Rate${xUnit ? ` (${xUnit})` : ''}`,
    },
    startOnTick: true,
    endOnTick: true,
    type: 'linear',
    gridLineWidth: displayOptions?.showXGrid ? 1 : 0,
    min: displayOptions?.xRange?.min,
    max: displayOptions?.xRange?.max,
  };

  const seriesOptions: Highcharts.SeriesOptionsType[] = [];

  pumpPerformanceSeries.forEach((series, seriesIndex) => {
    const {
      downstreamCorrection = 0,
      upstreamCorrection = 0,
      reading = Readings.Close,
    } = series;
    const dataRecords = seriesDataRecords[seriesIndex];
    const data: Highcharts.PointOptionsObject[] = [];
    const color =
      series.displayOptions?.color ?? colors[series.type ?? 'flow-pressure'];
    const { markerType = MarkerTypes.Circle, markerSize = 'Medium' } =
      series.displayOptions ?? {};

    if (hasLoaded(dataRecords.downstream) && hasLoaded(dataRecords.upstream)) {
      if (
        dataRecords.downstream?.data?.unit !== dataRecords.upstream?.data?.unit
      ) {
        throw new Error(
          `Cannot convert Pressure to Head. Units for downstream and upstream sensors don't match.
					Downstream ${dataRecords.downstream?.data?.unit}.
					Upstream ${dataRecords.upstream?.data?.unit}.
					`
        );
      }
    }

    if (
      hasLoaded(dataRecords.x) &&
      (!dataRecords.downstream || hasLoaded(dataRecords.downstream)) &&
      (!dataRecords.upstream || hasLoaded(dataRecords.upstream))
    ) {
      if (dataRecords.x?.data?.timestamps.length) {
        for (let i = 0; i < dataRecords.x.data.timestamps.length; i++) {
          let y: number | undefined = undefined;
          const timestamp = dataRecords.x.data.timestamps[i];

          const x =
            dataRecords.x?.data?.measurements[timestamp]?.[
              readingIndex[reading]
            ];

          const downstreamValue =
            dataRecords.downstream?.data?.measurements[timestamp]?.[
              readingIndex[reading]
            ];

          const upstreamValue =
            dataRecords.upstream?.data?.measurements[timestamp]?.[
              readingIndex[reading]
            ];

          if (
            (downstreamValue !== undefined || upstreamValue !== undefined) &&
            series.type !== 'flow-power'
          ) {
            const downstreamY = (downstreamValue ?? 0) - downstreamCorrection;
            const upstreamY = (upstreamValue ?? 0) - upstreamCorrection;

            y = downstreamY - upstreamY;

            if (displayOptions?.mode === 'head') {
              y = convertToHead(y, dataRecords.downstream?.data?.unit);
            }
          } else if (downstreamValue !== undefined) {
            y = downstreamValue;
          }

          if (x !== undefined && y !== undefined) {
            let appliedDateFormat = '%m/%d/%Y';
            let appliedDateTimeFormat = '%I:%M %p';

            if (dateFormatString) {
              appliedDateFormat = dateFormatString
                .replace('DD', '%d')
                .replace('MM', '%m')
                .replace('YYYY', '%Y');
            }

            if (hourCycle12 === false) {
              appliedDateTimeFormat = '%H:%M';
            }

            data.push({
              x,
              y,
              custom: {
                date: dateFormat(
                  `${appliedDateFormat} - ${appliedDateTimeFormat}`,
                  timestamp
                ),
                ...(displayOptions?.mode === 'pressure-head' &&
                  series.type !== 'flow-power' && {
                    head: convertToHead(y, dataRecords.downstream?.data?.unit),
                  }),
              },
            });
          }
        }
      }
    }

    // Assigns color shades to datapoints based on timestamp

    if (data.length > 0) {
      const lightness = 0.08;

      const gradient = new Array<string>(10)
        .fill(color)
        .map((c, index) => {
          const co = new Color(c);
          return index === 0 ? co.hex() : co.lighten(index * lightness).hex();
        })
        .reverse();

      for (let i = 0; i < data.length; i++) {
        const colorIndex = Math.floor((i * gradient.length) / data.length);

        const assignedColor = gradient[colorIndex];

        data[i].color = assignedColor;
      }
    }

    seriesOptions.push({
      id: series.id,
      name: series.name,
      type: 'scatter',
      boostThreshold: 20000,
      turboThreshold: 20000,
      color,
      data,
      states: {
        inactive: {
          opacity: 1,
        },
      },
      yAxis:
        series.type === 'flow-power'
          ? 'flow-power'
          : displayOptions?.mode === 'head'
            ? 'flow-head'
            : 'flow-pressure',
      tooltip: {
        headerFormat: `<b>${series.name || 'Pump Performance'}</b><br>`,
        pointFormat: [
          `${series.xSensorAlias}: {point.x:.2f} ${
            dataRecords.x?.data?.unit ?? 'Unknown Unit'
          }`,
          '<br>',
          `${
            series.type === 'flow-power'
              ? 'Power'
              : displayOptions?.mode === 'head'
                ? 'Head'
                : 'Pressure'
          }: {point.y:.2f} ${
            displayOptions?.mode === 'head' && series.type !== 'flow-power'
              ? 'ft'
              : (dataRecords.downstream?.data?.unit ?? 'Unknown Unit')
          }`,
          ...(displayOptions?.mode === 'pressure-head' &&
          series.type !== 'flow-power'
            ? [
                '<br>',
                `Head: {point.custom.head:.2f} ${
                  accountUnitSystem === 'Imperial' ? 'ft' : 'm'
                }`,
              ]
            : []),
          '<br>',
          '{point.custom.date}',
        ].join(''),
      },
      visible: isSeriesVisible(
        hiddenSeries,
        seriesIndex,
        series.id,
        series.name
      ),
      marker: {
        symbol: markerType,
        radius: MARKER_SIZE_MAP[markerSize],
      },
    });
  });

  // MANUFACTURER CURVE

  manufacturerCurves?.forEach((curve) => {
    seriesOptions.push({
      color: colors[curve.type],
      data: curve.data,
      id: curve.id,
      marker: { enabled: false },
      name: curve.alias || curve.id,
      type: 'line',
      yAxis: curve.type,
    });
  });

  return {
    legend: { enabled: true },
    navigator: { enabled: false },
    rangeSelector: { enabled: false },
    chart: chartOptions,
    boost: boostOptions,
    series: seriesOptions,
    xAxis: xAxisOptions,
    yAxis: yAxisOptions,
    plotOptions: {
      series: {
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    },
  };
};
