import React, { forwardRef, useMemo, SetStateAction } from 'react';
import { TimeRangeSelection } from '../../../_next/core/_insight-chart';
import { Theme } from '../../../_next/core/utils/theme-utils';
import {
  InsightPumpPerformanceChart,
  InsightPumpPerformanceChartAor,
  InsightPumpPerformanceChartDesignPoint,
  InsightPumpPerformanceChartManufacturerCurveSeriesProps,
  InsightPumpPerformanceChartScatterSeriesProps,
  InsightPumpPerformanceChartSeriesProps,
  InsightPumpPerformanceChartStatusFilter,
} from '../../../_next/presets/insight-pump-performance-chart';
import useManufacturerCurves from '../../../core/hooks/useManufacturerCurves';
import { useGlobalization } from '../../../i18n';
import {
  GlobalPassthroughs,
  PumpPerformanceChartConnected,
} from '../../../types';
import { ChartingErrorBoundary } from '../../atoms';
import { generatePumpPerformanceSeries } from './ConnectedPumpPerformanceChart.utils';
import { PumpPerformanceDataPoint } from '../../../_next/modules/pump-performance-chart/pump-performance-chart';

export type ConnectedPumpPerformanceChartProps = PumpPerformanceChartConnected &
  GlobalPassthroughs & {
    alwaysFetchData?: boolean;
    timeRangeSelection?: TimeRangeSelection;
    selectedTheme?: Theme;
    currentDataPoint?: PumpPerformanceDataPoint;
    setCurrentDataPoint?: React.Dispatch<
      SetStateAction<PumpPerformanceDataPoint | undefined>
    >;
  };

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Version Switch
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const ConnectedPumpPerformanceChart = (
  props: ConnectedPumpPerformanceChartProps,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: any
): React.ReactElement => {
  return <PumpPerformanceChartV2 {...props} ref={ref} />;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * New Chart
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const PumpPerformanceChartV2 = React.forwardRef<
  { chart: Highcharts.Chart | undefined },
  ConnectedPumpPerformanceChartProps
>((props, ref): React.ReactElement => {
  const { t } = useGlobalization();
  const curves = useManufacturerCurves(t, props.manufacturerCurves);

  const scatterSeries = useMemo(() => {
    const primarySeries = {
      ...props.primarySeries,
      displayOptions: {
        // Add color, markerSize and markerType to primary series
        color: props.displayOptions?.color,
        markerSize: props.displayOptions?.markerSize,
        markerType: props.displayOptions?.markerType,
      },
    };
    const generatedSeries = generatePumpPerformanceSeries(
      [primarySeries, ...(props.referenceSeries ?? [])],
      props.resolution
    );

    return generatedSeries.map((series) => {
      const getSubType = (
        seriesType?: 'flow-pressure' | 'flow-power',
        displayOptionsMode?: 'pressure' | 'head' | 'pressure-head'
      ) => {
        if (seriesType === 'flow-power') {
          return 'power';
        } else {
          return displayOptionsMode ?? 'pressure';
        }
      };

      return {
        id: series.id,
        type: 'scatter',
        subType: getSubType(series.type, props.displayOptions.mode),
        name: series.name,
        color: series.displayOptions?.color,
        markerSize: series.displayOptions?.markerSize,
        markerType: series.displayOptions?.markerType,
        baseSource: {
          sensorId: series.dataSource.x.sensorId,
          resolution: series.dataSource.x.resolution,
          reading: series.reading,
          customData: series.dataSource.x.customData,
        },
        downstreamCorrection: series.downstreamCorrection,
        upstreamCorrection: series.upstreamCorrection,
        downstreamSource: {
          sensorId: series.dataSource.downstream?.sensorId ?? '',
          resolution: series.dataSource.downstream?.resolution ?? '15-MINUTE',
          reading: series.reading,
          customData: series.dataSource.downstream?.customData,
        },
        ...(series.dataSource.upstream && {
          upstreamSource: {
            sensorId: series.dataSource.upstream.sensorId,
            resolution: series.dataSource.upstream.resolution,
            reading: series.reading,
            customData: series.dataSource.upstream?.customData,
          },
        }),
        hidden: series.id ? props.hiddenSeries?.includes(series.id) : false,
        displayMode: props.displayOptions.mode,
        seriesType: series.type,
        brakeHP: props?.pumpProperties?.brakeHP,
        brakeHPUnit: props?.pumpProperties?.brakeHPUnit,
      } as InsightPumpPerformanceChartScatterSeriesProps;
    });
  }, [
    props.displayOptions?.color,
    props.displayOptions?.markerSize,
    props.displayOptions?.markerType,
    props.displayOptions.mode,
    props.hiddenSeries,
    props.primarySeries,
    props?.pumpProperties?.brakeHP,
    props?.pumpProperties?.brakeHPUnit,
    props.referenceSeries,
    props.resolution,
  ]);

  const curveSeries = useMemo(() => {
    return curves.data?.map((curve) => {
      const curveProp = props.manufacturerCurves?.find(
        (c) => c.id === curve.curveId
      );

      const LEGACY_YAXIS_ID_MAP = {
        'flow-pressure': 'pressure',
        'flow-efficiency': 'efficiency',
        'flow-power': 'power',
      } as const;

      const YAXIS_ID_MAP = {
        Pressure: 'pressure',
        Efficiency: 'efficiency',
        Percent: 'efficiency',
        Power: 'power',
        // Head: 'head',
      } as const;

      let xSeriesUnit: string | undefined = undefined;
      let ySeriesUnit: string | undefined = undefined;
      let subType: InsightPumpPerformanceChartManufacturerCurveSeriesProps['subType'] =
        'pressure';

      // Support for legacy curves that passed the type from the app
      if (curveProp?.type && LEGACY_YAXIS_ID_MAP[curveProp.type]) {
        subType = LEGACY_YAXIS_ID_MAP[curveProp.type];
      }

      if (curveProp?.unit) {
        ySeriesUnit = curveProp?.unit;
      }

      // Use asset API data if available
      if (curve.ySeries && YAXIS_ID_MAP[curve.ySeries.type]) {
        subType = YAXIS_ID_MAP[curve.ySeries.type];
      }

      if (curve.xSeries?.unit) {
        xSeriesUnit = curve.xSeries?.unit;
      }

      if (curve.ySeries?.unit) {
        ySeriesUnit = curve.ySeries?.unit;
      }

      return {
        id: curve._id,
        type: 'manufacturer-curve',
        data: curve.data,
        name: curve.curveId,
        hidden: props.hiddenSeries?.includes(curve._id),
        subType,
        ySeriesUnit,
        xSeriesUnit,
        color: curveProp?.displayOptions?.color,
        markerSize: curveProp?.displayOptions?.markerSize,
        markerType: curveProp?.displayOptions?.markerType,
      } as InsightPumpPerformanceChartManufacturerCurveSeriesProps;
    });
  }, [props.manufacturerCurves, props.hiddenSeries, curves.data]);

  const filterSeries = useMemo(() => {
    return props?.pumpStatusFilters?.map((pump) => {
      let reading = props.primarySeries.reading ?? 'Close';
      if (reading === 'Average' || reading === 'Sum') reading = 'Close';
      return {
        sensorId: pump.sensorId,
        status: pump.status,
        resolution: props.resolution,
        reading: reading,
        type: 'pump-filter',
      } as InsightPumpPerformanceChartStatusFilter;
    });
  }, [props.pumpStatusFilters, props.resolution, props.primarySeries.reading]);

  const designPoint: InsightPumpPerformanceChartDesignPoint | undefined =
    useMemo(() => {
      if (
        props?.pumpProperties?.flowRate !== undefined &&
        props?.pumpProperties?.flowRateUnit !== undefined &&
        props?.pumpProperties?.pressure !== undefined &&
        props?.pumpProperties?.pressureUnit !== undefined
      ) {
        const point: InsightPumpPerformanceChartDesignPoint = {
          type: 'design-point',
          flowRate: props?.pumpProperties?.flowRate,
          flowRateUnit: props?.pumpProperties?.flowRateUnit,
          pressure: props?.pumpProperties?.pressure,
          pressureUnit: props.pumpProperties.pressureUnit,
          efficiency: props?.pumpProperties?.efficiency,
          efficiencyUnit: props?.pumpProperties?.efficiencyUnit,
          brakeHP: props?.pumpProperties?.brakeHP,
          brakeHPUnit: props?.pumpProperties?.brakeHPUnit,
        };
        return point;
      }
      return undefined;
    }, [
      props.pumpProperties?.brakeHP,
      props.pumpProperties?.brakeHPUnit,
      props.pumpProperties?.efficiency,
      props.pumpProperties?.efficiencyUnit,
      props.pumpProperties?.flowRate,
      props.pumpProperties?.flowRateUnit,
      props.pumpProperties?.pressure,
      props.pumpProperties?.pressureUnit,
    ]);

  const allowableOperatingRange: InsightPumpPerformanceChartAor | undefined =
    useMemo(() => {
      // pressure and flowRate are needed for AOR and POR calculations, do not render if they are not available
      if (
        props?.pumpProperties?.flowRate !== undefined &&
        props?.pumpProperties?.flowRateUnit !== undefined &&
        props?.pumpProperties?.pressure !== undefined &&
        props?.pumpProperties?.pressureUnit !== undefined &&
        (props?.pumpProperties?.maxAOR !== undefined ||
          props?.pumpProperties?.minAOR !== undefined ||
          props?.pumpProperties?.maxPOR !== undefined ||
          props?.pumpProperties?.minPOR !== undefined)
      ) {
        const aor: InsightPumpPerformanceChartAor = {
          type: 'allowable-operating-range',
          pumpProperties: {
            flowRate: props.pumpProperties.flowRate,
            flowRateUnit: props.pumpProperties.flowRateUnit,
            pressure: props.pumpProperties.pressure,
            pressureUnit: props.pumpProperties.pressureUnit,
            maxAOR: props.pumpProperties.maxAOR,
            minAOR: props.pumpProperties.minAOR,
            maxPOR: props.pumpProperties.maxPOR,
            minPOR: props.pumpProperties.minPOR,
          },
        };
        return aor;
      }
      return undefined;
    }, [
      props.pumpProperties?.flowRate,
      props.pumpProperties?.flowRateUnit,
      props.pumpProperties?.maxAOR,
      props.pumpProperties?.maxPOR,
      props.pumpProperties?.minAOR,
      props.pumpProperties?.minPOR,
      props.pumpProperties?.pressure,
      props.pumpProperties?.pressureUnit,
    ]);

  const series = useMemo(() => {
    const s: InsightPumpPerformanceChartSeriesProps[] = [];

    for (const _s of scatterSeries) s.push(_s);

    if (filterSeries) {
      for (const _s of filterSeries) s.push(_s);
    }

    return s;
  }, [scatterSeries, filterSeries]);

  return (
    <ChartingErrorBoundary chartProps={props}>
      <InsightPumpPerformanceChart
        ref={ref}
        series={series}
        curveSeries={curveSeries}
        designPoint={designPoint}
        allowableOperatingRange={allowableOperatingRange}
        summaryTable={props.summaryTable}
        enableHorizontalGrids={props.displayOptions?.showYGrid}
        enableVerticalGrids={props.displayOptions?.showXGrid}
        xAxisLabel={props.displayOptions?.xLabel}
        yAxisLabel={props.displayOptions?.yLabel}
        xAxisMax={props.displayOptions?.xRange?.max}
        xAxisMin={props.displayOptions?.xRange?.min}
        yAxisMax={props.displayOptions?.yRange?.max}
        yAxisMin={props.displayOptions?.yRange?.min}
        timeRangeSelection={props.timeRangeSelection}
        currentDataPoint={props?.currentDataPoint}
        setCurrentDataPoint={props?.setCurrentDataPoint}
        onSeriesVisibilityChange={(seriesId, type) => {
          const hiddenSeries = props.hiddenSeries
            ? [...props.hiddenSeries]
            : [];

          const newHiddenSeries =
            type === 'hide'
              ? [...hiddenSeries, seriesId]
              : hiddenSeries.filter((series) => series !== seriesId);

          props.onHiddenSeriesChange?.(newHiddenSeries);
        }}
        selectedTheme={props.selectedTheme}
        axisMode={props.displayOptions?.mode}
      />
    </ChartingErrorBoundary>
  );
});

PumpPerformanceChartV2.displayName = 'PumpPerformanceChartV2';

export default forwardRef(ConnectedPumpPerformanceChart);
