import type * as Highcharts from 'highcharts';
import type { PumpPerformanceChartEventHandlers } from './pump-performance-chart';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Initial Options
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const initialOptions: Highcharts.Options = {
  chart: { type: 'scatter', height: null, zoomType: 'xy', animation: false },
  credits: { enabled: false },
  exporting: { enabled: false },
  title: { text: '' },
  plotOptions: {
    line: {
      animation: false,
      events: {
        afterAnimate: function () {
          this.points.forEach((point) => {
            //@ts-ignore
            const { x, y } = point;
              if (point.options) {
                point.options.className = `selector_x:${Math.round(x * 100) / 100}-y:${Math.round(y * 100) / 100}`;
              }
          });
        },
      },
      marker: { enabled: false },
    },
    scatter: {
      animation: false,
      events: {
        afterAnimate: function () {
          this.points.forEach((point) => {
            //@ts-ignore
            const { x, y, graphic } = point;
            graphic?.attr({
              cy: `x:${Math.round(x * 100) / 100}-y:${Math.round(y * 100) / 100}`,
            });
          });
        },
      },
    },
  },

  colors: [
    '#00abd1',
    '#768D95',
    '#E66B45',
    '#EFB302',
    '#83BC3F',
    '#876CE7',
    '#017CA0',
    '#4A6067',
    '#AA0000',
    '#AA5D00',
    '#4B7E03',
    '#4C3DAD',
    '#61DDFF',
    '#A5BDC6',
    '#FF9C72',
    '#FFE54D',
    '#B6EF70',
    '#BAA6FF',
  ],
  yAxis: [
    {
      id: 'pressure',
      alignTicks: true,
      endOnTick: false,
      gridLineWidth: 0,
      opposite: false,
      showEmpty: false,
      startOnTick: false,
      title: { text: '', style: { color: '#00abd1' } },
    },
    {
      id: 'head',
      alignTicks: true,
      endOnTick: false,
      gridLineWidth: 0,
      opposite: false,
      showEmpty: false,
      startOnTick: false,
      title: { text: '', style: { color: '#00abd1' } },
    },
    {
      id: 'power',
      alignTicks: true,
      endOnTick: false,
      gridLineWidth: 0,
      opposite: true,
      showEmpty: false,
      startOnTick: false,
      title: { text: '', style: { color: '#AA0000' } },
    },
    {
      id: 'efficiency',
      alignTicks: false,
      gridLineWidth: 0,
      max: 100,
      min: 0,
      opposite: true,
      showEmpty: false,
      title: { text: '', style: { color: '#4B7E03' } },
    },
  ],
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Options with event handlers
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const makeOptionsWithEventHandlers = (
  options: Highcharts.Options,
  eventHandlersRef: React.MutableRefObject<PumpPerformanceChartEventHandlers>
): Highcharts.Options => {
  return {
    ...options,
    plotOptions: {
      ...options.plotOptions,
      series: {
        ...options.plotOptions?.series,
        events: {
          ...options.plotOptions?.series?.events,
          show: function (event) {
            if (
              event.target &&
              (!('baseSeries' in event.target) ||
                event.target.baseSeries === undefined) &&
              (event.type === 'show' || event.type === 'hide')
            ) {
              eventHandlersRef.current.onSeriesVisibilityChange?.call(
                this,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                event.target.options.id,
                'show'
              );
            }

            options.plotOptions?.series?.events?.show?.call(this, event);
          },
          hide: function (event) {
            if (
              event.target &&
              (!('baseSeries' in event.target) ||
                event.target.baseSeries === undefined) &&
              (event.type === 'show' || event.type === 'hide')
            ) {
              eventHandlersRef.current.onSeriesVisibilityChange?.call(
                this,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                event.target.options.id,
                'hide'
              );
            }

            options.plotOptions?.series?.events?.hide?.call(this, event);
          },
        },
      },
    },
  };
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { initialOptions, makeOptionsWithEventHandlers };
