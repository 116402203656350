import {
  InsightHistoricalChart,
  InsightHistoricalChartSeriesProps,
} from '../../../_next/presets/insight-historical-chart';
import { ChartingErrorBoundary } from '../../atoms';
import { generateHistoricalSeries } from './ConnectedHistoricalChart.utils';
import { TimeRangeSelection } from '../../../_next/core/_insight-chart';
import React, { forwardRef, useMemo } from 'react';
import type { GlobalPassthroughs } from '../../../types/global.types';
import type { HistoricalChartConnected } from '../../../types/historicalChart.types';
import type { HistoricalSeries } from '../../../core/types/historical.types';
import type { StackableChartRef } from '../../../core/components/StackableChart';

export type ConnectedHistoricalChartProps = HistoricalChartConnected &
  GlobalPassthroughs & {
    dataGap?: boolean;
    timeRangeSelection?: TimeRangeSelection;
    separateYAxes: {
      id: string;
      label?: string;
      max?: number;
      min?: number;
      side?: 'left' | 'right';
      color?: string;
    }[];
    onTimeRangeSelectionChange?: (
      timeRangeSelection: TimeRangeSelection
    ) => void;
  };

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Version Switch
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const ConnectedHistoricalChart = (
  props: ConnectedHistoricalChartProps,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: any
): React.ReactElement => {
  return <HistoricalChartV2 {...props} ref={ref} />;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * New Chart
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const HistoricalChartV2 = React.forwardRef<
  { chart: Highcharts.Chart | undefined },
  ConnectedHistoricalChartProps
>((props, ref): React.ReactElement => {
  const historicalSeries = useMemo<HistoricalSeries[]>(() => {
    return generateHistoricalSeries(props.primarySeries, props.referenceSeries);
  }, [props.primarySeries, props.referenceSeries]);

  const insightSeries = React.useMemo(() => {
    const _insightSeries: InsightHistoricalChartSeriesProps[] = [];

    historicalSeries.forEach((series, seriesIndex) => {
      const readings = ['Close', 'Open', 'High', 'Low', 'Average', 'Sum'];
      const analytic = readings.includes(series.func)
        ? series.func
        : { type: series.func, params: series.params };

      _insightSeries.push({
        id: series.uniqueId,
        type: series.displayOptions?.chartType ?? 'line',
        name: series.alias,
        sensorId: series.simulation
          ? series.simulation.objectId
          : series.dataSource.sensorId,
        resolution: series.dataSource.resolution,
        collectionInterval: series.collectionInterval,
        analytic: analytic,
        color: series.displayOptions?.color,
        dataOverride: series.dataOverride,
        customData: series.customData,
        dashStyle: series.displayOptions?.dashStyle,
        separateYAxisId: series.separateYAxisId,
        enableDataLabels: series.displayOptions?.showDataLabels,
        enableMarkers: series.displayOptions?.showMarkers,
        enableTrendline: !!series.displayOptions?.trendline,
        trendlineColor: series.displayOptions?.trendline?.color,
        trendlineDashStyle: series.displayOptions?.trendline?.dashStyle,
        trendlinePeriods: series.displayOptions?.trendline?.periods,
        trendlineForecastPeriods:
          series.displayOptions?.trendline?.forecastPeriods,
        hidden:
          series.uniqueId === undefined
            ? undefined
            : props.hiddenSeries?.includes(series.uniqueId),
        hiddenTrendlineSeries:
          series.uniqueId === undefined
            ? undefined
            : props.hiddenSeries?.includes(series.uniqueId + '_trendline'),
        // On app, first series' display options are stored on root display options
        maxTimestamp: series.maxTimestamp,
        minTimestamp: series.minTimestamp,
        referenceDataSource: series.referenceDataSource,
        simulationDatabaseId: series.simulation?.databaseId,
        simulationResultId: series.simulation?.resultId,
        simulationTableId: series.simulation?.tableId,
        simulationObjectId: series.simulation?.objectId,
        simulationAttribute: series.simulation?.attribute,
      } as InsightHistoricalChartSeriesProps);
    });

    return _insightSeries;
  }, [historicalSeries, props.hiddenSeries]);

  const stacked = props.displayOptions?.overlay === false;

  return (
    <ChartingErrorBoundary chartProps={props}>
      <InsightHistoricalChart
        ref={ref}
        series={insightSeries}
        dataGap={props.dataGap}
        enableChartStack={stacked}
        separateYAxes={props.separateYAxes}
        enableColumnSeriesStack={props.displayOptions?.stacked}
        enableHorizontalGrids={props.displayOptions?.showYGrid}
        enableVerticalGrids={props.displayOptions?.showXGrid}
        timeRangeSelection={props.timeRangeSelection}
        showLiveDataButton={props.showLiveDataButton}
        onTimeRangeSelectionChange={props.onTimeRangeSelectionChange}
        isLiveData={props.isLiveData}
        isForecast={props.referenceSeries?.some(
          (s) => s?.seriesDataType === 'forecast'
        )}
        onIsLiveDataChange={props.onIsLiveDataChange}
        onPointClick={props.onClickPoint}
        onSeriesVisibilityChange={(seriesId, type) => {
          const hiddenSeries = props.hiddenSeries
            ? [...props.hiddenSeries]
            : [];

          const newHiddenSeries =
            type === 'hide'
              ? [...hiddenSeries, seriesId]
              : hiddenSeries.filter((series) => series !== seriesId);

          props.onHiddenSeriesChange?.(newHiddenSeries);
        }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resolutionOptions={
          props.resolutionOptions
            ? {
                onChange: props.onResolutionChange,
                options: props.resolutionOptions,
                selected: props.primarySeries.resolution,
              }
            : undefined
        }
        selectedTheme={props.selectedTheme}
      />
    </ChartingErrorBoundary>
  );
});

HistoricalChartV2.displayName = 'HistoricalChartV2';

export default forwardRef<StackableChartRef, ConnectedHistoricalChartProps>(
  ConnectedHistoricalChart
);
