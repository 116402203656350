import { InputBase } from '@mui/material';
import { NativeSelect } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Dropdown
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface DropdownProps<Value = string | number> {
  dataCy?: string;
  selected: Value;
  options: { value: Value; label: string }[];
  onChange?: (value: Value) => void;
  label?: string;
}

const Dropdown = <Value extends string | number>(
  props: DropdownProps<Value>
): React.ReactElement => {
  const selectRef = React.useRef<HTMLSelectElement>(null);
  const spanRef = React.useRef<HTMLSpanElement>(null);

  const handleNativeSelectChange = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      props.onChange?.(event.target.value as Value);
    },
    [props.onChange]
  );

  return (
    <>
      <DropdownStyles.Helper ref={spanRef} aria-hidden="true" />
      <DropdownStyles.Root>
        <NativeSelect
          data-cy={props.dataCy}
          ref={selectRef}
          input={<DropdownStyles.SelectInputBase />}
          value={props.selected}
          onChange={handleNativeSelectChange}>
          {props.label ? (
            <option disabled value="">
              {props.label}
            </option>
          ) : null}
          {props.options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </NativeSelect>
      </DropdownStyles.Root>
    </>
  );
};

const DropdownStyles = {
  Root: styled('div')<{ withTitle?: boolean }>`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  `,
  SelectInputBase: styled(InputBase).attrs(() => ({
    margin: 'none',
    size: 'small',
  }))`
    background-color: #e6ebf5;
    border-radius: 2px;
    border: none;
    color: #4a6067 !important;
    cursor: pointer !important;
    font-family: 'Lucida Grande', sans-serif !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    padding: 4px 6px;
    padding-right: 16px;

    &:hover {
      background-color: #e1e4ea;
    }

    & .MuiNativeSelect-select {
      padding: 0 !important;
      background-color: transparent !important;
    }

    & .MuiSvgIcon-root {
      height: 0.8em !important;
      top: calc(50% - 0.4em) !important;
      width: 0.8em !important;
    }
  `,
  Label: styled('label')`
    color: #4a6067;
    font-family: 'Lucida Grande', sans-serif;
    font-size: 12px;
    font-weight: 400;
    margin-right: 6px;
    width: max-content;
  `,
  Helper: styled('span')`
    position: absolute;
    top: 0;
    left: -99999px;
  `,
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { Dropdown };

export type { DropdownProps };
