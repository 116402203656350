import * as math from 'mathjs';
import * as InsightChart from '../../core/_insight-chart';
import * as TimeSeriesDataOld from '../../core/time-series-data-old';
import {
  PumpPerformanceChartScatterSeriesData,
  PumpPerformanceDataPoint,
} from '../../modules/pump-performance-chart';
import {
  InsightPumpPerformanceChartSeriesProps,
  InsightPumpPerformanceChartStatusFilter,
} from './insight-pump-performance-chart';

/**
 * Some of the units we use in the system are not supported by math
 * and cannot be directly aliased with `createUnit` since they contain
 * special characters. This map is used to manually pick a valid alias
 * name.
 */
export const UNIT_MAP: Record<string, string> = {
  'ft3/m': 'ft3m',
  'L/m': 'Lmin',
  'L/s': 'Lsec',
  'kg/cm2': 'kgcm2',
  ft: 'ftH2O',
  m: 'mH2O',
};

export const setCustomUnits = (): void => {
  /**
   * Normally, these custom units should be predefined in the 'Stylovyze'
   * and 'shared-utils' packages. However, there are instances where the
   * library may not include these units, leading to the error:
   * 'Failed to convert units. SyntaxError: Unit “UNIT_NAME” not found.'
   * To prevent this, we redefine these units here to avoid any runtime errors.
   *
   * built-in units: https://mathjs.org/docs/datatypes/units.html#reference
   */
  try {
    math.createUnit({
      mm: { definition: '0.001 m', aliases: ['mm'] },
      cm: { definition: '0.01 m', aliases: ['cm'] },
      // mega gallons
      mgal: {
        definition: '1e6 gal',
        aliases: ['Mgal', 'megagallon', 'megagallons', 'MG'],
      },
      // short form of day was missing
      d: { definition: '1 day' },
      mAD: { definition: '1 m' },
      ft2: { definition: '1 sqft' },
      ft3: { definition: '1 cuft' },
      ftAD: { definition: '1 ft' },
      // flow rate units
      gpm: { definition: '1 gal/minute' },
      cfs: { definition: '1 ft^3/s' },
      cfm: { definition: '1 ft^3/minute' },
      ft3m: { definition: '1 ft^3/minute' },
      Lmin: { definition: '1 L/minute' },
      Lsec: { definition: '1 L/second' },
      gph: { definition: '1 gal/hr' },
      gpd: { definition: '1 gal/day' },
      MGD: { definition: '1 MG/day' },
      // pressure units
      kgcm2: { definition: '98.0665 kPa' },
      // head units
      mH2O: { definition: '100 cmH2O', aliases: ['mh2o'] },
      ftH2O: { definition: '30.48 cmH2O' },
      // power units
      Hp: { definition: '1 hp' },
      Watt: { definition: '1 W' },
    });
  } catch (e) {
    console.warn('custom units already defined');
  }
};

export function limit(
  value: string | null | undefined,
  defaultValue = 500
): number {
  if (typeof value === 'string' || value === null) return defaultValue;
  if (isNaN(Number(value))) return defaultValue;
  return Number(value);
}

export type EdgeSource = {
  sensorId: string;
  resolution: TimeSeriesDataOld.Resolution;
  reading: TimeSeriesDataOld.Reading;
};

export function edgeSourceStringifier(source: EdgeSource): string {
  return `${source.sensorId}:${source.resolution}:${source.reading}`;
}

const makeMap = (
  data: [timestamp: number, ...values: number[]][]
): TimeSeriesDataOld.TimeSeriesDataMap => {
  const map = new Map();
  data.forEach((item) => {
    map.set(item[0], item[1]);
  });
  return map;
};

export const getBaseData = (
  baseSource: {
    sensorId: string;
    resolution: InsightChart.Resolution;
    reading?: InsightChart.Reading;
    customData?: TimeSeriesDataOld.ResponseDataEntry;
  },
  edgeData?: Map<string, { data: Map<number, number>; unit: string | null }>
): [
  TimeSeriesDataOld.TimeSeriesDataMap | undefined,
  string | null | undefined,
] => {
  const { sensorId, resolution, customData } = baseSource;
  const { reading = 'Close' } = baseSource;
  if (customData) {
    return [makeMap(customData.data), null];
  } else {
    const s = { category: 'Base', sensorId, resolution, reading };
    const k = edgeSourceStringifier(s);
    const e = edgeData?.get(k);
    return [e?.data, e?.unit];
  }
};

export const getDownstreamData = (
  downstreamSource: {
    sensorId: string;
    resolution: InsightChart.Resolution;
    reading?: InsightChart.Reading;
    customData?: TimeSeriesDataOld.ResponseDataEntry;
  },
  edgeData?: Map<string, { data: Map<number, number>; unit: string | null }>
): [
  TimeSeriesDataOld.TimeSeriesDataMap | undefined,
  string | null | undefined,
] => {
  const { sensorId, resolution, customData } = downstreamSource;
  const { reading = 'Close' } = downstreamSource;
  if (customData) {
    return [makeMap(customData.data), null];
  } else {
    const s = { category: 'Down', sensorId, resolution, reading };
    const k = edgeSourceStringifier(s);
    const e = edgeData?.get(k);
    return [e?.data, e?.unit];
  }
};

export const getUpstreamDataMap = (
  upstreamSource: {
    sensorId: string;
    resolution: InsightChart.Resolution;
    reading?: InsightChart.Reading;
    customData?: TimeSeriesDataOld.ResponseDataEntry;
  },
  edgeData?: Map<string, { data: Map<number, number>; unit: string | null }>
): TimeSeriesDataOld.TimeSeriesDataMap | undefined => {
  if (!upstreamSource) return;
  const { sensorId, resolution, customData } = upstreamSource;
  const { reading = 'Close' } = upstreamSource;
  if (customData) {
    return makeMap(customData.data);
  } else {
    const s = { category: 'Up', sensorId, resolution, reading };
    const k = edgeSourceStringifier(s);
    return edgeData?.get(k)?.data;
  }
};

export const getFilterSeries = (
  series: InsightPumpPerformanceChartSeriesProps[]
): InsightPumpPerformanceChartStatusFilter[] => {
  return series.filter((s): s is InsightPumpPerformanceChartStatusFilter => {
    return s.type === 'pump-filter';
  });
};

export const getFilterDataMap = (
  filterSeries: InsightPumpPerformanceChartStatusFilter[],
  edgeData: Map<string, { data: Map<number, number>; unit: string | null }>
): WeakMap<
  InsightPumpPerformanceChartStatusFilter,
  TimeSeriesDataOld.TimeSeriesDataMap
> => {
  const map: WeakMap<
    InsightPumpPerformanceChartStatusFilter,
    TimeSeriesDataOld.TimeSeriesDataMap
  > = new WeakMap();

  if (filterSeries === undefined || filterSeries.length === 0) {
    return undefined;
  }

  for (const filter of filterSeries) {
    const { sensorId, resolution, reading } = filter;

    const s = { category: 'Filter', sensorId, resolution, reading };
    const k = edgeSourceStringifier(s);
    const d = edgeData?.get(k)?.data;
    if (d) map.set(filter, d);
  }

  return map;
};

export const getScatterSeriesData = (
  filterSeries: InsightPumpPerformanceChartStatusFilter[],
  filtersDataMap: WeakMap<
    InsightPumpPerformanceChartStatusFilter,
    TimeSeriesDataOld.TimeSeriesDataMap
  >,
  baseDataMap: TimeSeriesDataOld.TimeSeriesDataMap,
  downstreamDataMap: TimeSeriesDataOld.TimeSeriesDataMap,
  upstreamDataMap: TimeSeriesDataOld.TimeSeriesDataMap,
  subType: 'pressure' | 'head' | 'pressure-head' | 'power',
  downstreamCorrection?: number,
  upstreamCorrection?: number,
  baseUnit?: string,
  mainBaseUnit?: string,
  downstreamUnit?: string,
  mainDownstreamUnit?: string
): PumpPerformanceChartScatterSeriesData => {
  const d: PumpPerformanceChartScatterSeriesData = [];
  if (
    baseDataMap !== undefined &&
    baseDataMap.size > 0 &&
    downstreamDataMap !== undefined &&
    downstreamDataMap.size > 0
  ) {
    for (const [baseTimestamp, baseValue] of baseDataMap) {
      const downstreamValue = downstreamDataMap.get(baseTimestamp);
      const upstreamValue = upstreamDataMap?.get(baseTimestamp);

      if (
        baseValue !== null &&
        downstreamValue !== undefined &&
        downstreamValue !== null
      ) {
        let y = downstreamValue - (downstreamCorrection || 0);

        if (
          subType !== 'power' &&
          upstreamValue !== undefined &&
          upstreamValue !== null
        ) {
          y -= upstreamValue - (upstreamCorrection || 0);
        }

        const isVisible: boolean[] = [];
        if (filterSeries !== undefined && filterSeries.length > 0) {
          for (const filter of filterSeries) {
            const filterValue = filtersDataMap?.get(filter)?.get(baseTimestamp);
            if (filterValue !== undefined && filterValue !== null) {
              // if filter is ON/TRUE should show 1's and hide 0's
              // if filter is OFF/FALSE should show 0's and hide 1's
              const visibleOnCurrent =
                filter.status === !!filterValue ? true : false;
              isVisible.push(visibleOnCurrent);
            } else {
              //if no value then point should not be visible
              isVisible.push(false);
            }
          }
        }

        if (
          isVisible.length === 0 ||
          //makes an AND to all the showable and no showable filter  values
          isVisible.every((val) => val === true)
        ) {
          d.push({ timestamp: baseTimestamp, x: baseValue, y });
        }
      }
    }
  }

  return d.map((scatterData) => {
    return {
      ...scatterData,
      x:
        baseUnit && mainBaseUnit && baseUnit !== mainBaseUnit
          ? convertToUnit(scatterData.x, baseUnit, mainBaseUnit)
          : scatterData.x,
      y:
        downstreamUnit &&
        mainDownstreamUnit &&
        downstreamUnit !== mainDownstreamUnit
          ? convertToUnit(scatterData.y, downstreamUnit, mainDownstreamUnit)
          : scatterData.y,
    };
  });
};

export const convertToUnit = (
  value: number,
  sourceUnit: string,
  targetUnit: string
): number => {
  const _sourceUnit = UNIT_MAP[sourceUnit] ?? sourceUnit;
  const _targetUnit = UNIT_MAP[targetUnit] ?? targetUnit;
  if (_sourceUnit === _targetUnit) return value;
  try {
    return math.unit(value, _sourceUnit).toNumber(_targetUnit);
  } catch (error) {
    console.warn('Failed to convert units.', error);
    return value;
  }
};

export const getLastKnownDataPoint = (
  data: PumpPerformanceChartScatterSeriesData
): PumpPerformanceDataPoint => {
  return data.reduce(
    (latestDataPoint, dataPoint) => {
      if (!latestDataPoint?.timestamp) {
        return dataPoint;
      }
      if (latestDataPoint.timestamp < dataPoint.timestamp) {
        return dataPoint;
      }
    },
    { timestamp: undefined, x: undefined, y: undefined }
  );
};
