import { createUnit, unit as parseUnit } from 'mathjs';
import type { Unit } from 'mathjs';

const metricImperialMapping = {
    // mH2O - meter of water column
    mH2O: 'psi',
    m: 'ft',
    cm: 'in',
    mm: 'in',
    'l/s': 'Mgal/d',
    'm3/s': 'ft3/s',
    // eslint-disable-next-line prettier/prettier
    degC: 'degF',
    cost: 'cost',
    'cost/m': 'cost/ft',
    'm/s': 'ft/s',
    m2: 'ft2',
    m3: 'ft3',
    mAD: 'ftAD',
};

export interface ConvertedUnit {
    value: string;
    unit: string;
    formatted: string;
}

// filling in missing unit definitions
try {
    createUnit({
        mm: { definition: '0.001 m', aliases: ['mm'] },
        cm: { definition: '0.01 m', aliases: ['cm'] },
        // mega gallons
        mgal: {
            definition: '1e6 gal',
            aliases: ['Mgal', 'megagallon', 'megagallons', 'MG'],
        },
        // short form of day was missing
        d: { definition: '1 day' },
        mAD: { definition: '1 m' },
        ft2: { definition: '1 sqft' },
        ft3: { definition: '1 cuft' },
        ftAD: { definition: '1 ft' },
        // flow rate units
        gpm: { definition: '1 gal/minute' },
        cfs: { definition: '1 ft^3/s' },
        cfm: { definition: '1 ft^3/minute' },
        ft3m: { definition: '1 ft^3/minute' },
        Lmin: { definition: '1 L/minute' },
        Lsec: { definition: '1 L/second' },
        gph: { definition: '1 gal/hr' },
        gpd: { definition: '1 gal/day' },
        MGD: { definition: '1 MG/day' },
        // pressure units
        kgcm2: { definition: '98.0665 kPa' },
        // head units
        mH2O: { definition: '100 cmH2O', aliases: ['mh2o'] },
        ftH2O: { definition: '30.48 cmH2O' },
        // power units
        Hp: { definition: '1 hp' },
        Watt: { definition: '1 W' },
    });
    createUnit('cost');
} catch (e) {
    console.warn('custom units already defined');
}

// Match number | space | start of string before unit to make sure not finding wrong unit
const beforeUnit = '([0-9\\s]{1,}|^)';
// Match space | end of string after unit
const afterUnit = '(\\s|$)';

/**
 * Get the imperial unit from the metric one
 * Will warn if unit does not appear in imperial/metric list
 * @param unit unit or dimensioned quantity/number to convert to imperial
 */
export const getImperialUnit = (unit: string): string | undefined => {
    for (const [key, value] of Object.entries(metricImperialMapping)) {
        const regExp = new RegExp(`${beforeUnit}${key}${afterUnit}`, 'i');
        if (regExp.test(unit)) {
            return value;
        }
    }
    // only warn if unit isn't already imperial
    if (
        !Object.values(metricImperialMapping).find((value) =>
            new RegExp(`${beforeUnit}${value}${afterUnit}`, 'i').test(unit),
        )?.length
    ) {
        console.warn(`Imperial unit for "${unit}" not found in mapping.`);
    }
};

/**
 * Get the metric unit from the imperial one
 * Will warn if unit does not appear in imperial/metric list
 * @param unit unit or dimensioned quantity/number to convert to metric
 */
export const getMetricUnit = (unit: string): string | undefined => {
    for (const [key, value] of Object.entries(metricImperialMapping)) {
        const regExp = new RegExp(`${beforeUnit}${value}${afterUnit}`, 'i');
        if (regExp.test(unit)) {
            return key;
        }
    }
    // only warn if unit isn't already metric
    if (
        !Object.keys(metricImperialMapping).find((key) =>
            new RegExp(`${beforeUnit}${key}${afterUnit}`, 'i').test(unit),
        )?.length
    ) {
        console.warn(`Metric unit for "${unit}" not found in mapping.`);
    }
};

/**
 * format value to a maximum of decimal places,
 * if not define will return original number of decimals
 * @param value number to format
 * @param decimals maxium number of decimal places to show
 */
export const toDecimalPlaces = (
    value: number | string,
    decimals?: number,
): string => {
    if (decimals == null) return `${value}`;
    const asNumber = typeof value === 'number' ? value : parseFloat(value);
    // Although the undefined (which can be a specified locale) could be missed out,
    // the types for the function don't allow it
    return new Intl.NumberFormat(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: decimals,
    }).format(asNumber);
};

/**
 * Convert the unit object to new unit (if provided) and return value,
 * unit and formatted string
 * @param unitObject object created from `unit` function
 * @param newUnit unit to output value as
 * @param decimals decimal places to display value
 */
export const convertTo = (
    unitObject: Unit,
    newUnit?: string,
    decimals?: number,
): ConvertedUnit => {
    const originalEmpty = unitObject.toNumeric('') == null;
    const convertedValue = newUnit ? unitObject.to(newUnit) : unitObject;
    // prevent null being converted to a string

    const value = originalEmpty
        ? ''
        : toDecimalPlaces(`${convertedValue.toNumeric('')}`, decimals);
    const unit = convertedValue.formatUnits().replace(/\s\/\s/g, '/');
    const formatted = originalEmpty ? unit : `${value} ${unit}`;

    return {
        value,
        unit,
        formatted,
    };
};
export const convertToUnit = (unitObject: Unit, newUnit: string): number => {
    return unitObject.toNumber(newUnit);
};
/**
 * Convert the unit object to new unit (if provided) and return value,
 * unit and formatted string
 * NOTE: Does not use the Intl.Format to add localized formatting
 * @param unitObject object created from `unit` function
 * @param newUnit unit to output value as
 * @param decimals decimal places to display value
 */
export const convertToNoIntlFormat = (
    unitObject: Unit,
    newUnit?: string,
    decimals?: number,
): ConvertedUnit => {
    const originalEmpty = unitObject.toNumeric('') == null;
    const convertedValue = newUnit ? unitObject.to(newUnit) : unitObject;
    // prevent null being converted to a string

    let value = '';

    if (!originalEmpty) {
        if (decimals)
            value = (+convertedValue.toNumber('').toFixed(decimals)).toString();
        else value = convertedValue.toNumber('').toString();
    }
    const unit = convertedValue.formatUnits().replace(/\s\/\s/g, '/');
    const formatted = originalEmpty ? unit : `${value} ${unit}`;

    return {
        value,
        unit,
        formatted,
    };
};

/**
 * Convert value from metric to imperial, will return original value if
 * no imperial unit is found
 * @param measurement dimensioned quantity/number
 * @param decimals decimal places to display value
 * @param imperialUnit unit to convert to
 * @example convertToImperial('20mH2O');
 */
export const convertToImperial = (
    measurement: string,
    decimals?: number,
    imperialUnit?: string,
): ConvertedUnit => {
    const parsedValue = parseUnit(measurement);
    const newUnit = imperialUnit ?? getImperialUnit(measurement);
    return convertTo(parsedValue, newUnit, decimals);
};

/**
 * Conver value from imperial to metric, will return original value if
 * no metric unit is found
 * @param measurement dimensioned quantity/number
 * @param decimals decimal places to display value
 * @param metricUnit: unit to convert to
 * @example convertToMetric('21psi');
 */
export const convertToMetric = (
    measurement: string,
    decimals?: number,
    metricUnit?: string,
): ConvertedUnit => {
    const newUnit = metricUnit ?? getMetricUnit(measurement);
    const parsedValue = parseUnit(measurement);
    return convertTo(parsedValue, newUnit, decimals);
};

/**
 * Convert value from metric to imperial, will return original value if
 * no imperial unit is found
 * NOTE: Does not use the Intl.Format to add localized formatting
 * @param measurement dimensioned quantity/number
 * @param decimals decimal places to display value
 * @param imperialUnit unit to convert to
 * @example convertToImperial('20mH2O');
 */
export const convertToImperialNoIntlFormat = (
    measurement: string,
    decimals?: number,
    imperialUnit?: string,
): ConvertedUnit => {
    const parsedValue = parseUnit(measurement);
    const newUnit = imperialUnit ?? getImperialUnit(measurement);
    return convertToNoIntlFormat(parsedValue, newUnit, decimals);
};

/**
 * Conver value from imperial to metric, will return original value if
 * no metric unit is found
 * NOTE: Does not use the Intl.Format to add localized formatting
 * @param measurement dimensioned quantity/number
 * @param decimals decimal places to display value
 * @param metricUnit unit to convert to
 * @example convertToMetric('21psi');
 */
export const convertToMetricNoIntlFormat = (
    measurement: string,
    decimals?: number,
    metricUnit?: string,
): ConvertedUnit => {
    const newUnit = metricUnit ?? getMetricUnit(measurement);
    const parsedValue = parseUnit(measurement);
    return convertToNoIntlFormat(parsedValue, newUnit, decimals);
};
