(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("i18next"), require("@innovyze/shared-utils/lib/utils/units"), require("react"), require("@innovyze/shared-utils"), require("styled-components"), require("@mui/material"), require("react-i18next"), require("@emotion/styled"), require("@emotion/react"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define(["I18next", "@innovyze/shared-utils/lib/utils/units", "React", "@innovyze/shared-utils", "styled-components", "@Mui/Material", "ReactI18next", "@Emotion/Styled", "@Emotion/React", "ReactDOM"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("i18next"), require("@innovyze/shared-utils/lib/utils/units"), require("react"), require("@innovyze/shared-utils"), require("styled-components"), require("@mui/material"), require("react-i18next"), require("@emotion/styled"), require("@emotion/react"), require("react-dom")) : factory(root["I18next"], root["@innovyze/shared-utils/lib/utils/units"], root["React"], root["@innovyze/shared-utils"], root[undefined], root["@Mui/Material"], root["ReactI18next"], root["@Emotion/Styled"], root["@Emotion/React"], root["ReactDOM"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, (__WEBPACK_EXTERNAL_MODULE__9703__, __WEBPACK_EXTERNAL_MODULE__6436__, __WEBPACK_EXTERNAL_MODULE__4639__, __WEBPACK_EXTERNAL_MODULE__3033__, __WEBPACK_EXTERNAL_MODULE__5466__, __WEBPACK_EXTERNAL_MODULE__4153__, __WEBPACK_EXTERNAL_MODULE__511__, __WEBPACK_EXTERNAL_MODULE__1425__, __WEBPACK_EXTERNAL_MODULE__1654__, __WEBPACK_EXTERNAL_MODULE__1346__) => {
return 