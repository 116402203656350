import { SensorType } from '@innovyze/shared-utils';
import { DataSeries } from '@innovyze/shared-utils/lib/pumpTools/DataSeries';
import { PumpOperationRange } from '@innovyze/shared-utils/lib/pumpTools/OperationRange';
import {
  useIsFeatureEnabled,
  useSelectSensors,
  useSelectSettings,
  useSettings,
} from '@innovyze/stylovyze';
import * as React from 'react';
import { PumpProperties } from '../../../types/pumpPerformanceChart.types';
import * as InsightChart from '../../core/_insight-chart';
import * as SeriesData from '../../core/series-data';
import * as TimeSeriesData from '../../core/time-series-data';
import * as TimeSeriesDataOld from '../../core/time-series-data-old';
import { fixCollectionInterval } from '../../core/time-series-data/utils';
import * as PumpPerformanceChart from '../../modules/pump-performance-chart';
import { makeSources } from './insight-pump-performance-chart.sources';
import {
  convertToUnit,
  EdgeSource,
  edgeSourceStringifier,
  getBaseData,
  getDownstreamData,
  getFilterDataMap,
  getFilterSeries,
  getLastKnownDataPoint,
  getScatterSeriesData,
  getUpstreamDataMap,
  limit,
  setCustomUnits,
} from './utils';

setCustomUnits();

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Chart Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface InsightPumpPerformanceChartProps
  extends Omit<PumpPerformanceChart.PumpPerformanceChartRootProps, 'children'> {
  designPoint?: InsightPumpPerformanceChartDesignPoint;
  allowableOperatingRange?: InsightPumpPerformanceChartAor;
  series: InsightPumpPerformanceChartSeriesProps[];
  curveSeries?: InsightPumpPerformanceChartManufacturerCurveSeriesProps[];
  timeRangeSelection?: InsightChart.TimeRangeSelection;
  summaryTable?: React.JSX.Element;
}

const InsightPumpPerformanceChart = React.forwardRef<
  { chart: Highcharts.Chart | undefined },
  InsightPumpPerformanceChartProps
>((props, ref): React.ReactElement => {
  const { companySettings } = useSettings();
  const { sensors, initialized: sensorsInitialized } = useSelectSensors();
  const dataLimit = useIsFeatureEnabled(
    'info-360-analytics-hp2-charts-ppc-limit'
  );

  const sources = React.useMemo(
    () => makeSources(props.series),
    [props.series]
  );

  const relevantSeries = props.series.filter(
    (series): series is InsightPumpPerformanceChartScatterSeriesProps =>
      series.type === 'scatter'
  );

  const targetXSeries = relevantSeries[0];

  // When the chart type is "Flow / Pressure", relevant series will not have target series;
  // in this case, find the target series from manufacturer curve series
  const targetYPressureSeries =
    relevantSeries.find((series) =>
      ['pressure', 'pressure-head'].includes(series.subType)
    ) ??
    props.curveSeries?.find((series) => series.id?.startsWith('pump_curve'));

  const targetYPowerSeries =
    relevantSeries.find((series) => series.subType === 'power') ??
    props.curveSeries?.find((series) => series.subType === 'power');

  const seriesData = TimeSeriesDataOld.useRetriever(sources);

  const [edgeSources] = SeriesData.useSources<
    EdgeSource & { collectionInterval: number }
  >(() => {
    if (!props.series.length) return;
    if (!sensorsInitialized) return;

    const _sources = makeSources(props.series, true);
    return _sources.map((s) => {
      const _sensor = sensors.find((_s) => _s.sensorId === s.sensorId);
      return {
        sensorId: s.sensorId,
        resolution: s.resolution ?? '15-MINUTE',
        reading: s.reading ?? 'Close',
        category: s.category,
        collectionInterval: fixCollectionInterval(_sensor?.collectionInterval),
      };
    });
  }, [props.series, sensors, sensorsInitialized]);

  const [edgeData, edgeStatus, retrieveEdgeData] = SeriesData.useRetriever<
    Map<string, { data: Map<number, number>; unit: string | null }>,
    { timeSelection?: TimeSeriesData.PartialTimeSelection }
  >(
    async (signal, params) => {
      if (!edgeSources?.length) return;

      const timeSelection = {
        from: params.timeSelection?.from ?? 'oldest',
        to: params.timeSelection?.to ?? 'latest',
      } as TimeSeriesData.TimeSelection;

      const response = await TimeSeriesData.retrieve(signal, {
        order: 'asc',
        timeSelection,
        limit: limit(dataLimit),
        timeZone: companySettings.timeZoneIANA,
        data_version: 'v3.0',
        snapping: 'oldest',
        sources: edgeSources.map((s) => {
          return {
            key: edgeSourceStringifier(s),
            sensorId: s.sensorId,
            collectionInterval: { seconds: s.collectionInterval },
            analytic: TimeSeriesData.makeAnalytic(
              s.resolution,
              s.reading ?? 'Close'
            ),
          };
        }),
      });

      const dataMap: Map<
        string,
        { data: Map<number, number>; unit: string | null }
      > = new Map();

      for (const s of edgeSources) {
        const key = edgeSourceStringifier(s);
        const result = response?.data?.results[key];
        const m = new Map(result?.data.map(([t, v]) => [t, v]));
        dataMap.set(key, { data: m, unit: result.unit });
      }

      return dataMap;
    },
    [companySettings.timeZoneIANA, dataLimit, edgeSources]
  );

  // Handles data fetching with edge analytics' API
  React.useEffect(() => {
    if (!sensorsInitialized) return;

    retrieveEdgeData({
      timeSelection: {
        from: props.timeRangeSelection?.min,
        to: props.timeRangeSelection?.max,
      },
    });
  }, [
    sensorsInitialized,
    props.timeRangeSelection?.max,
    props.timeRangeSelection?.min,
    retrieveEdgeData,
  ]);

  const seriesDataStatus = React.useMemo(() => {
    for (const series of props.series) {
      if (series.type === 'scatter' && series.baseSource.customData) {
        return 'resolved';
      }
    }
    return edgeStatus;
  }, [edgeStatus, props.series]);

  return (
    <PumpPerformanceChart.PumpPerformanceChartRoot
      {...props}
      ref={ref}
      currentDataPoint={props.currentDataPoint}
      summaryTable={props.summaryTable}>
      <PumpPerformanceChart.PumpPerformanceChartSeriesGroup
        status={seriesDataStatus}>
        {props.series?.map((seriesProps, seriesIndex) => {
          if (seriesProps.type === 'scatter') {
            return (
              <InsightPumpPerformanceChartScatterSeries
                {...seriesProps}
                key={'scatter-' + seriesIndex}
                seriesData={seriesData}
                edgeData={edgeData}
                series={props.series}
                isMainSeries={seriesIndex === 0}
                setCurrentDataPoint={props.setCurrentDataPoint}
                currentDataPoint={props.currentDataPoint}
              />
            );
          }
        })}
        {props.curveSeries?.map((curveSeries, seriesIndex) => {
          return (
            <InsightPumpPerformanceChartManufacturerCurveSeries
              {...curveSeries}
              key={'manufacturer-curve-' + seriesIndex}
              status={seriesDataStatus}
              seriesData={seriesData}
              edgeData={edgeData}
              targetXSeries={targetXSeries}
              targetYPressureSeries={targetYPressureSeries}
              targetYPowerSeries={targetYPowerSeries}
            />
          );
        })}
        {props.designPoint ? (
          <InsightPumpPerformanceChartDesignPointSeries
            {...props.designPoint}
            edgeData={edgeData}
            targetXSeries={targetXSeries}
            targetYPressureSeries={targetYPressureSeries}
          />
        ) : null}
        {props.allowableOperatingRange
          ? (() => {
              const mainSeries: InsightPumpPerformanceChartScatterSeriesProps =
                props.series.find((s) => s.type === 'scatter');

              if (mainSeries) {
                return (
                  <InsightPumpPerformanceChartAorSeries
                    {...props.allowableOperatingRange}
                    seriesData={seriesData}
                    edgeData={edgeData}
                    series={props.series}
                    curveSeries={props.curveSeries}
                    mainSeries={mainSeries}
                  />
                );
              }
            })()
          : null}
      </PumpPerformanceChart.PumpPerformanceChartSeriesGroup>
    </PumpPerformanceChart.PumpPerformanceChartRoot>
  );
});

InsightPumpPerformanceChart.displayName = 'InsightPumpPerformanceChart';

type InsightPumpPerformanceChartSeriesProps =
  | InsightPumpPerformanceChartScatterSeriesProps
  | InsightPumpPerformanceChartStatusFilter;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Scatter Series Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type InsightPumpPerformanceChartScatterSeriesProps =
  PumpPerformanceChart.PumpPerformanceChartScatterSeriesProps & {
    edge?: boolean;
    isMainSeries?: boolean;
    setCurrentDataPoint?: React.Dispatch<
      React.SetStateAction<PumpPerformanceChart.PumpPerformanceDataPoint>
    >;
    currentDataPoint?: PumpPerformanceChart.PumpPerformanceDataPoint;
    seriesData?: ReturnType<typeof TimeSeriesDataOld.useRetriever>;
    edgeData?: Map<string, { data: Map<number, number>; unit: string | null }>;
    series: InsightPumpPerformanceChartSeriesProps[];
    downstreamCorrection?: number;
    upstreamCorrection?: number;
    baseSource: {
      sensorId: string;
      resolution: InsightChart.Resolution;
      reading?: InsightChart.Reading;
      customData?: TimeSeriesDataOld.ResponseDataEntry;
    };
    downstreamSource: {
      sensorId: string;
      resolution: InsightChart.Resolution;
      reading?: InsightChart.Reading;
      customData?: TimeSeriesDataOld.ResponseDataEntry;
    };
    upstreamSource?: {
      sensorId: string;
      resolution: InsightChart.Resolution;
      reading?: InsightChart.Reading;
      customData?: TimeSeriesDataOld.ResponseDataEntry;
    };
    pumpFiltersDataEntry?: (InsightChart.SensorDataEntry & {
      filterStatus: boolean;
    })[];
  };

const InsightPumpPerformanceChartScatterSeries = (
  props: InsightPumpPerformanceChartScatterSeriesProps
): React.ReactElement => {
  const mainSeries = props.series.find((s) => s.type === 'scatter');

  const [, mainBaseUnit] = React.useMemo((): [
    TimeSeriesDataOld.TimeSeriesDataMap | undefined,
    string | null | undefined,
  ] => {
    return getBaseData(mainSeries.baseSource, props.edgeData);
  }, [mainSeries.baseSource, props.edgeData]);

  const [, mainDownstreamUnit] = React.useMemo((): [
    TimeSeriesDataOld.TimeSeriesDataMap | undefined,
    string | null | undefined,
  ] => {
    return getDownstreamData(mainSeries.downstreamSource, props.edgeData);
  }, [mainSeries.downstreamSource, props.edgeData]);

  const [baseDataMap, baseUnit] = React.useMemo((): [
    TimeSeriesDataOld.TimeSeriesDataMap | undefined,
    string | null | undefined,
  ] => {
    return getBaseData(props.baseSource, props.edgeData);
  }, [props.baseSource, props.edgeData]);

  const [downstreamDataMap, downstreamUnit] = React.useMemo((): [
    TimeSeriesDataOld.TimeSeriesDataMap | undefined,
    string | null | undefined,
  ] => {
    return getDownstreamData(props.downstreamSource, props.edgeData);
  }, [props.downstreamSource, props.edgeData]);

  const upstreamDataMap = React.useMemo(():
    | TimeSeriesDataOld.TimeSeriesDataMap
    | undefined => {
    return getUpstreamDataMap(props.upstreamSource, props.edgeData);
  }, [props.upstreamSource, props.edgeData]);

  const filterSeries = React.useMemo(() => {
    return getFilterSeries(props.series);
  }, [props.series]);

  const filtersDataMap = React.useMemo(() => {
    return getFilterDataMap(filterSeries, props.edgeData);
  }, [filterSeries, props.edgeData]);

  const [processedData, lastKnownDataPoint] = React.useMemo(() => {
    const processedData = getScatterSeriesData(
      filterSeries,
      filtersDataMap,
      baseDataMap,
      downstreamDataMap,
      upstreamDataMap,
      props.subType,
      props.downstreamCorrection,
      props.upstreamCorrection,
      baseUnit,
      mainBaseUnit,
      downstreamUnit,
      mainDownstreamUnit
    );
    const lastKnownDataPoint = getLastKnownDataPoint(processedData);
    return [processedData, lastKnownDataPoint];
  }, [
    baseDataMap,
    baseUnit,
    downstreamDataMap,
    downstreamUnit,
    filterSeries,
    filtersDataMap,
    mainBaseUnit,
    mainDownstreamUnit,
    props.downstreamCorrection,
    props.subType,
    props.upstreamCorrection,
    upstreamDataMap,
  ]);

  const { setCurrentDataPoint } = props;
  React.useEffect(() => {
    if (!props.isMainSeries || processedData.length === 0) {
      return;
    }
    const getLastKnownDataPoint =
      (): PumpPerformanceChart.PumpPerformanceDataPoint => {
        return processedData.reduce(
          (latestDataPoint, dataPoint) => {
            if (!latestDataPoint?.timestamp) {
              return dataPoint;
            }
            if (latestDataPoint.timestamp < dataPoint.timestamp) {
              return dataPoint;
            }
          },
          { timestamp: undefined, x: undefined, y: undefined }
        );
      };
    if (setCurrentDataPoint && !props.currentDataPoint) {
      const lastKnownDataPoint = getLastKnownDataPoint();
      setCurrentDataPoint(lastKnownDataPoint);
    }
  }, [
    processedData,
    setCurrentDataPoint,
    props.isMainSeries,
    props.currentDataPoint,
  ]);

  const onDataPointClick = React.useCallback(
    (dataPoint: PumpPerformanceChart.PumpPerformanceDataPoint) => {
      if (!props.isMainSeries) {
        return;
      }
      if (setCurrentDataPoint) {
        setCurrentDataPoint(dataPoint);
      }
    },
    [props.isMainSeries, setCurrentDataPoint]
  );

  return (
    <PumpPerformanceChart.PumpPerformanceChartSeries
      {...props}
      type="scatter"
      data={processedData}
      lastKnownDataPoint={lastKnownDataPoint}
      onDataPointClick={onDataPointClick}
      xAxisUnit={mainBaseUnit}
      yAxisUnit={mainDownstreamUnit}
    />
  );
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Manufacturer Curve Series Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type InsightPumpPerformanceChartManufacturerCurveSeriesProps =
  PumpPerformanceChart.PumpPerformanceChartManufacturerCurveSeriesProps & {
    edgeData?: Map<
      string,
      {
        data: Map<number, number>;
        unit: string | null;
      }
    >;
    seriesData?: ReturnType<typeof TimeSeriesDataOld.useRetriever>;
    targetXSeries?: InsightPumpPerformanceChartScatterSeriesProps;
    targetYPressureSeries?:
      | InsightPumpPerformanceChartScatterSeriesProps
      | InsightPumpPerformanceChartManufacturerCurveSeriesProps;
    targetYPowerSeries?:
      | InsightPumpPerformanceChartScatterSeriesProps
      | InsightPumpPerformanceChartManufacturerCurveSeriesProps;
  };

const InsightPumpPerformanceChartManufacturerCurveSeries = (
  props: InsightPumpPerformanceChartManufacturerCurveSeriesProps
): React.ReactElement => {
  const curveXUnit = props.xSeriesUnit;
  const curveYUnit = props.ySeriesUnit;

  const targetXUnit = React.useMemo(() => {
    const {
      sensorId,
      resolution,
      reading = 'Close',
    } = props.targetXSeries.baseSource;
    const s = { category: 'Base', sensorId, resolution, reading };
    const k = edgeSourceStringifier(s);
    const e = props.edgeData?.get(k);
    const unit = e?.unit || undefined;
    return unit;
  }, [props.targetXSeries, props.edgeData]);

  const targetYUnit = React.useMemo(() => {
    let unit: string | undefined = undefined;
    if (props.subType === 'efficiency') {
      unit = curveYUnit;
    } else {
      // pressure, head or power curve
      const target =
        props.subType === 'power'
          ? props.targetYPowerSeries
          : props.targetYPressureSeries;
      if (target?.type === 'manufacturer-curve') {
        unit = target.ySeriesUnit || undefined;
      } else if (target?.type === 'scatter') {
        const {
          sensorId,
          resolution,
          reading = 'Close',
        } = target.downstreamSource;
        const s = { category: 'Down', sensorId, resolution, reading };
        const k = edgeSourceStringifier(s);
        const e = props.edgeData?.get(k);
        unit = e?.unit || undefined;
      }
    }
    return unit;
  }, [
    props.subType,
    props.targetYPowerSeries,
    props.targetYPressureSeries,
    props.edgeData,
    curveYUnit,
  ]);

  const shouldConvertXUnit = !!(
    targetXUnit &&
    curveXUnit &&
    curveXUnit !== targetXUnit
  );
  const shouldConvertYUnit = !!(
    targetYUnit &&
    curveYUnit &&
    curveYUnit !== targetYUnit
  );

  const data = React.useMemo(() => {
    if (props.status === 'resolved') {
      return props.data?.map(
        (d: { x: number; y: number | null } | [number, number | null]) => {
          let _x = Array.isArray(d) ? d[0] : d.x;
          let _y = Array.isArray(d) ? d[1] : d.y;

          if (shouldConvertXUnit) {
            _x = convertToUnit(_x, curveXUnit, targetXUnit);
          }

          if (shouldConvertYUnit && _y !== undefined && _y !== null) {
            _y = convertToUnit(_y, curveYUnit, targetYUnit);
          }
          return [_x, _y] as [number, number | null];
        }
      );
    }
  }, [
    curveXUnit,
    curveYUnit,
    props.data,
    props.status,
    shouldConvertXUnit,
    shouldConvertYUnit,
    targetXUnit,
    targetYUnit,
  ]);

  return (
    <PumpPerformanceChart.PumpPerformanceChartSeries
      {...props}
      type="manufacturer-curve"
      data={data}
      xSeriesUnit={
        props.status === 'resolved'
          ? shouldConvertXUnit
            ? targetXUnit
            : curveXUnit
          : undefined
      }
      ySeriesUnit={
        props.status === 'resolved'
          ? shouldConvertYUnit
            ? targetYUnit
            : curveYUnit
          : undefined
      }
    />
  );
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Allowable Operating Range (AOR) and Preferred Operating Range (POR) Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type InsightPumpPerformanceChartAor = {
  type: 'allowable-operating-range';
  pumpProperties: PumpProperties;
  seriesData?: ReturnType<typeof TimeSeriesDataOld.useRetriever>;
  edgeData?: Map<string, { data: Map<number, number>; unit: string | null }>;
  series?: InsightPumpPerformanceChartSeriesProps[];
  curveSeries?: InsightPumpPerformanceChartManufacturerCurveSeriesProps[];
  mainSeries?: InsightPumpPerformanceChartScatterSeriesProps;
};

const InsightPumpPerformanceChartAorSeries = (
  props: InsightPumpPerformanceChartAor
): React.ReactElement | null => {
  const {
    baseSource,
    downstreamSource,
    upstreamSource,
    subType,
    downstreamCorrection,
    upstreamCorrection,
  } = props.mainSeries;
  const { companySettings } = useSelectSettings();

  const filterSeries = React.useMemo(() => {
    return getFilterSeries(props.series);
  }, [props.series]);

  const [baseDataMap, baseUnit] = React.useMemo((): [
    TimeSeriesDataOld.TimeSeriesDataMap | undefined,
    string | null | undefined,
  ] => {
    return getBaseData(baseSource, props.edgeData);
  }, [baseSource, props.edgeData]);

  const [downstreamDataMap, downstreamUnit] = React.useMemo((): [
    TimeSeriesDataOld.TimeSeriesDataMap | undefined,
    string | null | undefined,
  ] => {
    return getDownstreamData(downstreamSource, props.edgeData);
  }, [downstreamSource, props.edgeData]);

  const mainSeriesData = React.useMemo(() => {
    return getScatterSeriesData(
      filterSeries,
      getFilterDataMap(filterSeries, props.edgeData),
      baseDataMap,
      downstreamDataMap,
      getUpstreamDataMap(upstreamSource, props.edgeData),
      subType,
      downstreamCorrection,
      upstreamCorrection
    );
  }, [
    baseDataMap,
    downstreamCorrection,
    downstreamDataMap,
    filterSeries,
    props.edgeData,
    subType,
    upstreamCorrection,
    upstreamSource,
  ]);

  const yAxisMode: 'pressure' | 'head' =
    subType === 'head' ? 'head' : 'pressure';

  const headUnit = companySettings.UOM === 'Imperial' ? 'ft' : 'm';

  const yAxisUnit = yAxisMode === 'head' ? headUnit : downstreamUnit;

  const [aor, por] = React.useMemo(() => {
    const primaryDataSeries: DataSeries = {
      assetType: '',
      name: 'Primary Series',
      xArray: mainSeriesData.map((d) => d.x),
      yArray: mainSeriesData.map((d) =>
        convertToUnit(d.y, downstreamUnit, yAxisUnit)
      ),
      xSeries: {
        type: 'Flow Rate',
        unit: baseUnit,
      },
      ySeries: {
        type: yAxisMode === 'head' ? SensorType.Head : 'Pressure',
        unit: yAxisUnit,
      },
    };

    const pressureCurve = props.curveSeries?.find((s) => {
      // sample pressure curve id: pump_curve#pressureCurve_kPa_01_m3s
      return s.type === 'manufacturer-curve' && s.id.startsWith('pump_curve');
    });
    const headCurve = props.curveSeries?.find((s) => {
      // sample head curve id: head_curve#headCurve_ft_01_m3day
      return s.type === 'manufacturer-curve' && s.id.startsWith('head_curve');
    });
    const curve = pressureCurve ?? headCurve; // use pressure curve first, if pressure curve is undefined, use head curve
    const curveDataSeries: DataSeries | null = curve
      ? {
          assetType: curve.id.startsWith('pump_curve')
            ? 'pump_curve'
            : 'head_curve',
          name: curve.name,
          xArray: curve.data.map(
            (d: { x: number; y: number | null } | [number, number | null]) =>
              convertToUnit(
                Array.isArray(d) ? d[0] : d.x,
                curve.xSeriesUnit,
                baseUnit
              )
          ),
          yArray: curve.data.map(
            (d: { x: number; y: number | null } | [number, number | null]) =>
              convertToUnit(
                Array.isArray(d) ? d[1] : d.y,
                curve.ySeriesUnit,
                yAxisUnit
              )
          ),
          xSeries: {
            type: 'Flow Rate',
            unit: baseUnit,
          },
          ySeries: {
            type: 'Pressure',
            unit: yAxisUnit,
          },
        }
      : null;

    const pumpOperationRange = new PumpOperationRange(
      primaryDataSeries,
      curveDataSeries,
      {
        flowRate: props.pumpProperties?.flowRate,
        flowRateUnit: props.pumpProperties?.flowRateUnit,
        pressure: props.pumpProperties?.pressure,
        pressureUnit: props.pumpProperties?.pressureUnit,
        efficiency: props.pumpProperties?.efficiency,
        efficiencyUnit: props.pumpProperties?.efficiencyUnit,
        brakeHP: props.pumpProperties?.brakeHP,
        brakeHPUnit: props.pumpProperties?.brakeHPUnit,
        minAOR: props.pumpProperties?.minAOR,
        maxAOR: props.pumpProperties?.maxAOR,
        minPOR: props.pumpProperties?.minPOR,
        maxPOR: props.pumpProperties?.maxPOR,
      }
    );
    const aor = pumpOperationRange.calculateAOR();
    const por = pumpOperationRange.calculatePOR();
    return [aor, por];
  }, [
    baseUnit,
    downstreamUnit,
    mainSeriesData,
    yAxisMode,
    yAxisUnit,
    props.curveSeries,
    // somehow these properties were causing issues.
    // it would be better to fix these on workspaces,
    // but this works for now.
    props.pumpProperties?.brakeHP,
    props.pumpProperties?.brakeHPUnit,
    props.pumpProperties?.efficiency,
    props.pumpProperties?.efficiencyUnit,
    props.pumpProperties?.flowRate,
    props.pumpProperties?.flowRateUnit,
    props.pumpProperties?.maxAOR,
    props.pumpProperties?.maxPOR,
    props.pumpProperties?.minAOR,
    props.pumpProperties?.minPOR,
    props.pumpProperties?.pressure,
    props.pumpProperties?.pressureUnit,
  ]);

  return (
    <PumpPerformanceChart.PumpPerformanceChartSeries
      {...props}
      aor={aor}
      por={por}
      xAxisUnit={baseUnit}
      yAxisUnit={yAxisUnit}
    />
  );
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Design Point Series Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type InsightPumpPerformanceChartDesignPoint = {
  type: 'design-point';
  flowRate: number;
  flowRateUnit: string;
  pressure: number;
  pressureUnit: string;
  efficiency?: number;
  efficiencyUnit?: string;
  brakeHP?: number;
  brakeHPUnit?: string;
  edgeData?: Map<
    string,
    {
      data: Map<number, number>;
      unit: string | null;
    }
  >;
  targetXSeries?: InsightPumpPerformanceChartScatterSeriesProps;
  targetYPressureSeries?:
    | InsightPumpPerformanceChartScatterSeriesProps
    | InsightPumpPerformanceChartManufacturerCurveSeriesProps;
};

const InsightPumpPerformanceChartDesignPointSeries = (
  props: InsightPumpPerformanceChartDesignPoint
): React.ReactElement => {
  const designPointXUnit = props.flowRateUnit;
  const designPointYUnit = props.pressureUnit;
  const { companySettings } = useSelectSettings();

  const chartXUnit = React.useMemo(() => {
    const {
      sensorId,
      resolution,
      reading = 'Close',
    } = props.targetXSeries.baseSource;
    const s = { category: 'Base', sensorId, resolution, reading };
    const k = edgeSourceStringifier(s);
    const e = props.edgeData?.get(k);
    const unit = e?.unit || designPointXUnit;
    return unit;
  }, [props.targetXSeries, props.edgeData, designPointXUnit]);

  const chartYUnit = React.useMemo(() => {
    const isManufacturerCurve = (
      series:
        | InsightPumpPerformanceChartScatterSeriesProps
        | InsightPumpPerformanceChartManufacturerCurveSeriesProps
    ): series is InsightPumpPerformanceChartManufacturerCurveSeriesProps => {
      return series.type === 'manufacturer-curve';
    };
    let unit: string;
    const targetXDisplayMode = props.targetXSeries.displayMode;
    if (targetXDisplayMode === 'head') {
      // "Display as: Head"
      unit = companySettings.UOM === 'Imperial' ? 'ft' : 'm';
    } else {
      // "Display as: Pressure" or "Display as: Both"
      if (!props.targetYPressureSeries) {
        unit = designPointYUnit;
      } else {
        if (isManufacturerCurve(props.targetYPressureSeries)) {
          unit = props.targetYPressureSeries.ySeriesUnit;
        } else {
          const downstreamSource = props.targetYPressureSeries.downstreamSource;
          const { sensorId, resolution, reading = 'Close' } = downstreamSource;
          const s = { category: 'Down', sensorId, resolution, reading };
          const k = edgeSourceStringifier(s);
          const e = props.edgeData?.get(k);
          unit = e?.unit;
        }
      }
    }
    return unit;
  }, [
    companySettings.UOM,
    designPointYUnit,
    props.edgeData,
    props.targetXSeries.displayMode,
    props.targetYPressureSeries,
  ]);

  const chartXValue = React.useMemo(() => {
    return convertToUnit(props.flowRate, designPointXUnit, chartXUnit);
  }, [designPointXUnit, props.flowRate, chartXUnit]);

  const chartYValue = React.useMemo(() => {
    return convertToUnit(props.pressure, designPointYUnit, chartYUnit);
  }, [designPointYUnit, props.pressure, chartYUnit]);

  return (
    <PumpPerformanceChart.PumpPerformanceChartSeries
      {...props}
      chartXValue={chartXValue}
      chartXUnit={chartXUnit}
      chartYValue={chartYValue}
      chartYUnit={chartYUnit}
    />
  );
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type InsightPumpPerformanceChartStatusFilter = {
  type: 'pump-filter';
  resolution: InsightChart.Resolution;
  reading?: InsightChart.Reading;
  data?: InsightChart.SensorDataEntry;
  error?: unknown;
  sensorId: string;
  status: boolean;
};

export { InsightPumpPerformanceChart };

export type {
  InsightPumpPerformanceChartAor,
  InsightPumpPerformanceChartDesignPoint,
  InsightPumpPerformanceChartManufacturerCurveSeriesProps,
  InsightPumpPerformanceChartProps,
  InsightPumpPerformanceChartScatterSeriesProps,
  InsightPumpPerformanceChartSeriesProps,
  InsightPumpPerformanceChartStatusFilter,
};
