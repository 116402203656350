/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { getEdgeLowestResolution } from './insight-historical-chart.utils';
import _ from 'lodash';
import { DateTime, Duration } from 'luxon';
import { fixCollectionInterval } from '../../core/time-series-data/utils';
import {
  SensorV2,
  useIsFeatureEnabled,
  useSelectSensors,
} from '@innovyze/stylovyze';
import * as HistoricalChart from '../../modules/historical-chart';
import * as InsightChart from '../../core/_insight-chart';
import * as React from 'react';
import * as TimeSeriesData from '../../core/time-series-data';
import * as TimeSeriesDataOld from '../../core/time-series-data-old';
import { useNavigatorData, usePlotData } from './insight-historical-chart.data';
import { InsightHistoricalChartSeriesProps } from './insight-historical-chart.types';
import {
  stringifySource,
  useDataSources,
} from './insight-historical-chart.data-sources';
import { useTimeSelection } from './insight-historical-chart.time-selection';
import { useControllableState } from '../../utils/use-controllable-state';

const numerify = (value: unknown, defaultValue: number) => {
  if (value === null || value === undefined) return defaultValue;
  const n = Number(value);
  if (Number.isNaN(n)) return defaultValue;
  return n;
};

const AUTO_REFRESH_TIME = Duration.fromObject({ minutes: 1 }).toMillis();

// AUTO and RAW use different flows, but they're still on this map to avoid
// type issues.
export const DATA_GAP_SIZES: Record<InsightChart.Resolution, number> = {
  RAW: Duration.fromObject({ minutes: 5 }).toMillis(),
  '15-MINUTE': Duration.fromObject({ minutes: 15 }).toMillis(),
  '30-MINUTE': Duration.fromObject({ minutes: 30 }).toMillis(),
  HOURLY: Duration.fromObject({ hours: 1 }).toMillis(),
  DAILY: Duration.fromObject({ days: 1 }).plus({ hours: 2 }).toMillis(),
  WEEKLY: Duration.fromObject({ weeks: 1 }).plus({ days: 2 }).toMillis(),
  MONTHLY: Duration.fromObject({ months: 1 }).plus({ days: 5 }).toMillis(),
  AUTO: Duration.fromObject({ minutes: 5 }).toMillis(),
};

const RESOLUTION_DURATION = {
  RAW: Duration.fromObject({ minutes: 5 }).toMillis(),
  '15-MINUTE': Duration.fromObject({ minutes: 15 }).toMillis(),
  '30-MINUTE': Duration.fromObject({ minutes: 30 }).toMillis(),
  HOURLY: Duration.fromObject({ hours: 1 }).toMillis(),
  DAILY: Duration.fromObject({ days: 1 }).toMillis(),
  WEEKLY: Duration.fromObject({ weeks: 1 }).toMillis(),
  MONTHLY: Duration.fromObject({ months: 1 }).toMillis(),
  AUTO: Duration.fromObject({ minutes: 5 }).toMillis(),
} as Record<InsightChart.Resolution, number>;

const ZOOM_OPTIONS: { label: string; value: number }[] = [
  { label: '1H', value: Duration.fromObject({ hours: 1 }).toMillis() },
  { label: '3H', value: Duration.fromObject({ hours: 3 }).toMillis() },
  { label: '6H', value: Duration.fromObject({ hours: 6 }).toMillis() },
  { label: '12H', value: Duration.fromObject({ hours: 12 }).toMillis() },
  { label: '1D', value: Duration.fromObject({ days: 1 }).toMillis() },
  { label: '3D', value: Duration.fromObject({ days: 3 }).toMillis() },
  { label: '1W', value: Duration.fromObject({ weeks: 1 }).toMillis() },
  { label: '2W', value: Duration.fromObject({ weeks: 2 }).toMillis() },
  { label: '1M', value: Duration.fromObject({ months: 1 }).toMillis() },
  { label: '3M', value: Duration.fromObject({ months: 3 }).toMillis() },
  { label: '6M', value: Duration.fromObject({ months: 6 }).toMillis() },
  { label: '1Y', value: Duration.fromObject({ years: 3 }).toMillis() },
  { label: '2Y', value: Duration.fromObject({ years: 2 }).toMillis() },
  { label: '3Y', value: Duration.fromObject({ years: 3 }).toMillis() },
];

const DEFAULT_ZOOM_OPTIONS = ZOOM_OPTIONS.filter((o) =>
  ['1H', '12H', '1D', '1W'].includes(o.label)
);

const figureOutWhereToSnap = (
  prev: TimeSeriesData.Extremes | TimeSeriesData.TimeSelection | undefined,
  next: TimeSeriesData.Extremes | TimeSeriesData.TimeSelection | undefined
) => {
  let snapping: TimeSeriesData.RequestBody['snapping'] = 'oldest';
  const p = TimeSeriesData.makeExtremesFromTimeSelection(prev);
  const n = TimeSeriesData.makeExtremesFromTimeSelection(next);

  if (p && n) {
    const fromDiff = n.from - p.from;
    const toDiff = n.to - p.to;

    if (fromDiff < 0 && toDiff < 0) snapping = 'latest';
    if (fromDiff < 0) snapping = 'latest';
  }

  return snapping;
};

function calculateAutoResolution(
  sensor: SensorV2,
  visiblePoints: number,
  timeSelection: TimeSeriesData.TimeSelection
): InsightChart.Resolution {
  const { from, to } = timeSelection;
  if (typeof from !== 'number' || typeof to !== 'number') return 'RAW';

  const sr = sensor?.resolutions.map((r) => r.toLowerCase()) ?? ['RAW'];

  for (const k of Object.keys(RESOLUTION_DURATION)) {
    if (sr.includes(k.toLowerCase())) {
      let ms = RESOLUTION_DURATION[k] * visiblePoints;

      if (k === 'RAW' && sensor.collectionInterval) {
        const i = fixCollectionInterval(sensor.collectionInterval);
        ms = i * 1000 * visiblePoints;
      }

      const d = to - from;
      if (d <= ms) return k as InsightChart.Resolution;
    }
  }

  return 'MONTHLY';
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Chart Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface InsightHistoricalChartProps
  extends Omit<
    HistoricalChart.HistoricalChartRootProps,
    'children' | 'onXAxisExtremesChange' | 'xAxisMin' | 'xAxisMax'
  > {
  series: InsightHistoricalChartSeriesProps[];
  dataGap?: boolean;
  timeRangeSelection?: InsightChart.TimeRangeSelection;
  onTimeRangeSelectionChange?: (
    timeRangeSelection: InsightChart.TimeRangeSelection
  ) => void;
  isLiveData?: boolean;
  onIsLiveDataChange?: (isLiveData?: boolean) => void;
}

const InsightHistoricalChart = React.forwardRef<
  HistoricalChart.ChartInstanceRef,
  InsightHistoricalChartProps
>((props, ref): React.ReactElement => {
  const { sensors } = useSelectSensors();
  const liveIntervalRef = React.useRef<NodeJS.Timeout>();
  const [nowPointer, setNowPointer] = React.useState(DateTime.now().toMillis());

  const limit = useIsFeatureEnabled('info-360-analytics-hp2-charts-limit');

  const panningBufferRatio = useIsFeatureEnabled(
    'info-360-analytics-charts-buffer-ratio'
  );

  const panningBufferLimit = useIsFeatureEnabled(
    'info-360-analytics-charts-buffer-limit'
  );

  const panningEnabled = useIsFeatureEnabled(
    'info-360-analytics-chart-panning'
  );

  const edgeV2Enabled = !!useIsFeatureEnabled(
    'info-360-analytics-simulation-chart'
  );

  const VISIBLE_POINTS = panningEnabled
    ? numerify(panningBufferLimit, 24)
    : numerify(limit, 500);

  const BUFFER = panningEnabled ? numerify(panningBufferRatio, 2) : 0;

  const THRESHOLD = 0.25;

  // This converts the old insight time range object to the new time series data
  // time selection object. TODO: Modify the props to receive the new object shape.
  const ts: TimeSeriesData.TimeSelection = React.useMemo(() => {
    if (props.timeRangeSelection?.min || props.timeRangeSelection?.max) {
      return {
        from: props.timeRangeSelection?.min || 'oldest',
        to: props.timeRangeSelection?.max || 'latest',
      };
    }
  }, [props.timeRangeSelection?.max, props.timeRangeSelection?.min]);

  const {
    timeSelection,
    timeSelectionRef,
    prevTimeSelectionRef,
    timeSelectionChangeTriggerRef,
    endEdgeTouchedRef,
    setTimeSelection,
  } = useTimeSelection({
    prop: ts,
    defaultProp: { from: 'oldest', to: 'latest' },
    onChange: (ts) => {
      if (typeof ts.from === 'number' && typeof ts.to === 'number') {
        props.onTimeRangeSelectionChange?.({ min: ts.from, max: ts.to });
      }
    },
  });

  const {
    plotDataSources,
    plotDataSourcesRef,
    plotDataSourcesChanged,
    navigatorDataSources,
  } = useDataSources(
    {
      series: props.series,
      getAutoResolution(sensor) {
        return calculateAutoResolution(sensor, VISIBLE_POINTS, timeSelection);
      },
    },
    [timeSelection]
  );

  const {
    plotData,
    plotDataRef,
    plotDataInitializedRef,
    plotDataStatus,
    visibleData,
    retrievePlotData,
  } = usePlotData({
    requestThreshold: THRESHOLD,
    buffer: BUFFER,
    points: VISIBLE_POINTS,
    useV2: edgeV2Enabled,
    getSnapping(next) {
      if (timeSelectionChangeTriggerRef.current === 'liveOn') return 'oldest';
      return figureOutWhereToSnap(prevTimeSelectionRef.current, next);
    },
    onRequestResolvedOrRejected() {
      endEdgeTouchedRef.current = undefined;
      timeSelectionChangeTriggerRef.current = undefined;
    },
  });

  const { navigatorData, retrieveNavigatorData } = useNavigatorData();

  const autoRefresh = React.useCallback(() => {
    const now = DateTime.now();
    setNowPointer(now.toMillis());
    setTimeSelection({
      from: now.minus({ days: 4 }).toMillis(),
      to: now.plus({ days: 3 }).toMillis(),
      trigger: 'liveOn',
    });
  }, [setTimeSelection]);

  const [isLiveEnabled, setIsLiveEnabled] = useControllableState({
    prop: props.isLiveData,
    defaultProp: false,
    onChange: (e) => {
      if (e) {
        autoRefresh();
        liveIntervalRef.current = setInterval(autoRefresh, AUTO_REFRESH_TIME);
      } else {
        clearInterval(liveIntervalRef.current);
      }

      props.onIsLiveDataChange?.(e);
    },
  });

  const isAutoResolutionEnabled = React.useMemo(() => {
    return props.series?.some((s) => s.resolution === 'AUTO');
  }, [props.series]);

  const hasReachedLimit = React.useMemo(() => {
    const results = plotData?.results
      ? Object.values(plotData.results)
      : undefined;

    if (!results?.length) return;

    for (const rawData of results) {
      if (rawData?.count && rawData.count >= VISIBLE_POINTS * 0.8) {
        return true;
      }
    }

    return false;
  }, [VISIBLE_POINTS, plotData?.results]);

  const lowestResolution = React.useMemo(() => {
    if (!props.series?.length) return undefined;
    return getEdgeLowestResolution(props.series.map((s) => s.resolution));
  }, [props.series]);

  const lowestInterval = React.useMemo(() => {
    const results = plotData?.results
      ? Object.values(plotData.results)
      : undefined;

    if (!results?.length) return;
    let interval: number;

    for (const rawData of results) {
      if (rawData?.data?.length) {
        const minTimestamp = rawData.data.at(0)?.[0];
        const maxTimestamp = rawData.data.at(-1)?.[0];
        if (minTimestamp && maxTimestamp) {
          const i = maxTimestamp - minTimestamp;
          if (interval === undefined || i < interval) {
            interval = i;
          }
        }
      }
    }

    return interval;
  }, [plotData?.results]);

  const zoomMaxTimeRange = React.useMemo(() => {
    if (lowestResolution === undefined) return;
    if (lowestResolution === 'RAW' && hasReachedLimit) return lowestInterval;
    if (lowestResolution === 'RAW' && !hasReachedLimit) return undefined;
    return RESOLUTION_DURATION[lowestResolution] * VISIBLE_POINTS;
  }, [VISIBLE_POINTS, hasReachedLimit, lowestInterval, lowestResolution]);

  const zoomMinTimeRange = React.useMemo(() => {
    if (lowestResolution === undefined) return;
    return RESOLUTION_DURATION[lowestResolution] * 5;
  }, [lowestResolution]);

  const zoomOptions = React.useMemo(() => {
    const options = [] as typeof ZOOM_OPTIONS;

    if (!zoomMaxTimeRange || !zoomMinTimeRange) return DEFAULT_ZOOM_OPTIONS;

    for (const o of ZOOM_OPTIONS) {
      if (zoomMinTimeRange <= o.value && zoomMaxTimeRange >= o.value) {
        options.push(o);
      }
    }

    return options;
  }, [zoomMaxTimeRange, zoomMinTimeRange]);

  const status = React.useMemo(() => {
    const isPanning =
      timeSelectionChangeTriggerRef.current === 'pan' ||
      timeSelectionChangeTriggerRef.current === 'sync:pan';

    if (panningEnabled) {
      if (isPanning) {
        const timeSelectionExtremes =
          TimeSeriesData.makeExtremesFromTimeSelection(timeSelection);

        if (plotDataRef.current?.extremes && timeSelectionExtremes) {
          const plotDataExtremes = plotDataRef.current.extremes;
          const fromEndReached =
            timeSelectionExtremes.from <= plotDataExtremes.from;
          const toEndReached = timeSelectionExtremes.to >= plotDataExtremes.to;

          const snapping = figureOutWhereToSnap(
            prevTimeSelectionRef.current,
            timeSelectionExtremes
          );

          if (
            plotDataStatus === 'loading' &&
            snapping === 'latest' &&
            fromEndReached
          ) {
            return 'loading';
          } else if (
            plotDataStatus === 'loading' &&
            snapping === 'oldest' &&
            toEndReached
          ) {
            return 'loading';
          } else return 'resolved';
        }
      }
    }

    return plotDataStatus;
  }, [
    panningEnabled,
    plotDataRef,
    plotDataStatus,
    prevTimeSelectionRef,
    timeSelection,
    timeSelectionChangeTriggerRef,
  ]);

  const visibleDataExtremes = React.useMemo(() => {
    if (
      timeSelectionChangeTriggerRef.current === 'snapping' ||
      timeSelectionChangeTriggerRef.current === 'sync:snapping'
    ) {
      return visibleData?.extremes;
    }

    return TimeSeriesData.makeExtremesFromTimeSelection(timeSelection);
  }, [timeSelection, timeSelectionChangeTriggerRef, visibleData?.extremes]);

  // Fetches the initial data
  React.useEffect(() => {
    if (plotDataInitializedRef.current) return;

    retrievePlotData({
      sources: plotDataSources,
      timeSelection: timeSelection,
    });
  }, [
    retrievePlotData,
    plotDataInitializedRef,
    plotDataSources,
    timeSelection,
  ]);

  // Fetches data when time selection changes
  React.useEffect(() => {
    if (!plotDataInitializedRef.current) return;
    if (timeSelectionChangeTriggerRef.current === 'snapping') return;
    if (timeSelectionChangeTriggerRef.current === 'sync:snapping') return;

    if (panningEnabled) {
      if (
        timeSelectionChangeTriggerRef.current === 'pan' ||
        timeSelectionChangeTriggerRef.current === 'sync:pan'
      ) {
        const timeSelectionExtremes =
          TimeSeriesData.makeExtremesFromTimeSelection(timeSelection);

        if (plotDataRef.current?.extremes && timeSelectionExtremes) {
          const plotDataExtremes = plotDataRef.current.extremes;
          const delta = plotDataExtremes.to - plotDataExtremes.from;
          const toTarget = plotDataExtremes.to - delta * THRESHOLD;
          const fromTarget = plotDataExtremes.from + delta * THRESHOLD;
          const fromTargetReached = timeSelectionExtremes.from <= fromTarget;
          const toTargetReached = timeSelectionExtremes.to >= toTarget;

          const snapping = figureOutWhereToSnap(
            prevTimeSelectionRef.current,
            timeSelectionExtremes
          );

          if (snapping === 'latest' && !fromTargetReached) return;
          if (snapping === 'oldest' && !toTargetReached) return;
        }
      }
    }

    retrievePlotData({
      sources: plotDataSourcesRef.current,
      timeSelection: endEdgeTouchedRef.current
        ? { from: timeSelection?.from, to: 'latest' }
        : timeSelection,
    });
  }, [
    retrievePlotData,
    panningEnabled,
    plotDataInitializedRef,
    plotDataRef,
    plotDataSourcesRef,
    prevTimeSelectionRef,
    timeSelection,
    timeSelectionChangeTriggerRef,
    endEdgeTouchedRef,
  ]);

  // Fetches data when sources change
  React.useEffect(() => {
    if (!plotDataInitializedRef.current) return;
    if (!plotDataSourcesChanged) return;

    retrievePlotData({
      sources: plotDataSources,
      timeSelection: timeSelectionRef.current,
    });
  }, [
    retrievePlotData,
    plotDataInitializedRef,
    plotDataSources,
    plotDataSourcesChanged,
    timeSelectionRef,
  ]);

  // This one retrieves the data for the navigator
  React.useEffect(() => {
    retrieveNavigatorData({
      sources: navigatorDataSources,
      useV2: edgeV2Enabled,
    });
  }, [retrieveNavigatorData, navigatorDataSources, edgeV2Enabled]);

  // Sets a new time selection when snapping occurs
  React.useEffect(() => {
    if (isAutoResolutionEnabled) return;
    if (timeSelectionChangeTriggerRef.current === 'snapping') return;
    if (timeSelectionChangeTriggerRef.current === 'sync:snapping') return;
    if (timeSelectionChangeTriggerRef.current === 'pan') return;
    if (timeSelectionChangeTriggerRef.current === 'sync:pan') return;
    if (timeSelectionChangeTriggerRef.current === 'zoom') return;
    if (timeSelectionChangeTriggerRef.current === 'sync:zoom') return;
    if (visibleData?.extremes?.from === undefined) return;
    if (visibleData?.extremes?.to === undefined) return;

    setTimeSelection({ ...visibleData?.extremes, trigger: 'snapping' });
  }, [
    timeSelectionChangeTriggerRef,
    setTimeSelection,
    isAutoResolutionEnabled,
    visibleData?.extremes,
  ]);

  return (
    <HistoricalChart.HistoricalChartRoot
      {...props}
      ref={ref}
      defaultLimit={numerify(limit, 500)}
      lowestResolution={lowestResolution}
      zoomMaxTimeRange={isAutoResolutionEnabled ? undefined : zoomMaxTimeRange}
      zoomMinTimeRange={isAutoResolutionEnabled ? undefined : zoomMinTimeRange}
      selectedTheme={props.selectedTheme}
      isLiveData={isLiveEnabled}
      onLiveDataToggle={setIsLiveEnabled}
      timeStampPointer={[nowPointer]}
      status={status}
      visibleDataExtremes={visibleDataExtremes}
      zoomOptions={zoomOptions}
      onXAxisExtremesChange={(min, max, trigger, endEdgeTouched) => {
        if (trigger !== 'liveOn') {
          clearInterval(liveIntervalRef.current);
          setIsLiveEnabled(false);
          props?.onIsLiveDataChange?.(false);
        }

        setTimeSelection({
          from: min,
          to: max,
          trigger,
          endEdgeTouched,
        });
      }}>
      <HistoricalChart.HistoricalChartSeriesGroup>
        {props.series.map((seriesProps, seriesIndex) => {
          let overriddenSeriesProps = seriesProps;
          const s = sensors.find((_s) => _s.sensorId === seriesProps.sensorId);
          const autoResolution =
            seriesProps.resolution === 'AUTO' && s
              ? calculateAutoResolution(s, VISIBLE_POINTS, timeSelection)
              : undefined;

          const dataGapSize = seriesProps.dataGapSize
            ? seriesProps.dataGapSize
            : seriesProps.resolution === 'RAW'
              ? fixCollectionInterval(s?.collectionInterval) * 1000
              : autoResolution
                ? DATA_GAP_SIZES[autoResolution]
                : DATA_GAP_SIZES[seriesProps.resolution];

          const unit = s?.unit || s?.sensorUnit;

          if (seriesProps.referenceDataSource) {
            let earliest;
            let latest;
            let referenceTimestamp;
            const { timestamp, override } = seriesProps.referenceDataSource;
            const { sensorId, resolution } = seriesProps.referenceDataSource;
            const { analytic } = seriesProps;

            const simulation = TimeSeriesData.getSimulation(seriesProps);

            const key = stringifySource({
              sensorId: sensorId,
              analytic: TimeSeriesData.makeAnalytic(resolution, analytic),
              ...simulation,
            });

            const forecastEdgeDataEntry = plotData?.results?.[key];

            if (forecastEdgeDataEntry) {
              earliest = forecastEdgeDataEntry.data?.at(0)?.[0];
              latest = forecastEdgeDataEntry.data?.at(-1)?.[0];
            }

            if (timestamp === 'earliest') referenceTimestamp = earliest;
            if (timestamp === 'latest') referenceTimestamp = latest;

            if (referenceTimestamp) {
              if (override === 'min') {
                overriddenSeriesProps = {
                  ...seriesProps,
                  minTimestamp: referenceTimestamp,
                };
              }
              if (override === 'max') {
                overriddenSeriesProps = {
                  ...seriesProps,
                  maxTimestamp: referenceTimestamp,
                };
              }
            }
          }

          return (
            <InsightHistoricalChartSeries
              {...overriddenSeriesProps}
              visibleDataExtremes={visibleDataExtremes}
              key={seriesIndex}
              index={seriesIndex}
              edgeData={plotData}
              snapshotEdgeData={navigatorData}
              autoResolution={autoResolution}
              unit={unit}
              dataGapSize={props.dataGap ? dataGapSize : undefined}
            />
          );
        })}
      </HistoricalChart.HistoricalChartSeriesGroup>
    </HistoricalChart.HistoricalChartRoot>
  );
});

InsightHistoricalChart.displayName = 'InsightHistoricalChart';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Series Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const InsightHistoricalChartSeries = (
  props: InsightHistoricalChartSeriesProps
): React.ReactElement => {
  const processedSeriesData = React.useMemo(() => {
    if (props.customData) return props.customData;

    const analytic = props.type === 'candlestick' ? 'OHLC' : props.analytic;
    const resolution = props.autoResolution ?? props.resolution;

    const isSimulation = !!props.simulationObjectId;
    const simulation = isSimulation
      ? {
          database_id: props.simulationDatabaseId,
          result_id: props.simulationResultId,
          table_id: props.simulationTableId,
          object_id: props.simulationObjectId,
        }
      : {};

    const key = stringifySource({
      sensorId: !isSimulation ? props.sensorId : undefined,
      analytic: TimeSeriesData.makeAnalytic(resolution, analytic),
      ...simulation,
    });

    const entry = props.edgeData?.results?.[key];

    return withDataOverride(
      entry?.data as TimeSeriesDataOld.TimeSeriesData,
      props.dataOverride
    );
  }, [
    props.analytic,
    props.customData,
    props.dataOverride,
    props.edgeData?.results,
    props.resolution,
    props.sensorId,
    props.type,
    props.autoResolution,
    props.simulationDatabaseId,
    props.simulationResultId,
    props.simulationTableId,
    props.simulationObjectId,
  ]);

  const processedNavigatorData = React.useMemo(() => {
    const analytic = props.type === 'candlestick' ? 'OHLC' : props.analytic;

    const key = stringifySource({
      sensorId: props.sensorId,
      analytic: TimeSeriesData.makeAnalytic('DAILY', analytic),
    });

    const entry = props.snapshotEdgeData?.results?.[key];

    return entry?.data as TimeSeriesDataOld.TimeSeriesData;
  }, [
    props.analytic,
    props.sensorId,
    props.snapshotEdgeData?.results,
    props.type,
  ]);

  const processedTrendlineData = React.useMemo(() => {
    if (!props.enableTrendline) return;

    const num = (n: unknown) => (Number.isNaN(Number(n)) ? 0 : Number(n));
    const trendlinePeriods = num(props.trendlinePeriods);
    // const trendlineForecastPeriods = num(props.trendlineForecastPeriods);

    const trendlineData = InsightChart.makeTrendlineDataV2(
      processedSeriesData,
      props.visibleDataExtremes,
      trendlinePeriods
    );

    // For later
    //   if (!trendlineForecastPeriods) return trendlineData;
    //   const trendlineForecastData = [];
    return trendlineData;
  }, [
    processedSeriesData,
    props.enableTrendline,
    // props.trendlineForecastPeriods,
    props.trendlinePeriods,
    props.visibleDataExtremes,
  ]);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <HistoricalChart.HistoricalChartSeries
      {...props}
      data={processedSeriesData}
      navigatorData={processedNavigatorData}
      trendlineData={processedTrendlineData}
      navigatorDataExtremes={props.snapshotEdgeData?.extremes}
      analytic={props.analytic ?? 'Close'}
      unit={props.unit}
    />
  );
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Utils
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const withDataOverride = (
  data: [number, number | null][] | undefined,
  dataOverride?: [number, number][]
): [number, number | null][] => {
  if (!data?.length || !dataOverride?.length) return data;

  const overrideMap = new Map<number, number>(dataOverride);

  return data.map(([timestamp, value]) => [
    timestamp,
    overrideMap.has(timestamp) ? overrideMap.get(timestamp)! : value,
  ]);
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { InsightHistoricalChart };

export type { InsightHistoricalChartProps, InsightHistoricalChartSeriesProps };
