import type { AnalyticFunction } from './analytic-function';
import type { Reading, Resolution } from './series';
import type { SensorDataMap } from './sensor-data';
import * as TimeSeriesData from '../../core/time-series-data';

export const readingAt: Reading[] = [
  'Close',
  'Open',
  'Low',
  'High',
  'Average',
  'Sum',
];

const analyticTrendlineMap = (
  analytic: Reading | AnalyticFunction
): Reading => {
  const _analytic = typeof analytic === 'string' ? analytic : analytic.type;

  switch (_analytic) {
    case 'Sqrt':
    case 'sqrt':
    case 'Movingaverage':
    case 'movingaverage':
    case 'Last':
    case 'last':
    case 'Constant':
    case 'constant':
      return 'Average';
    case 'MovingSum':
    case 'movingsum':
      return 'Sum';
    default:
      return _analytic;
  }
};

/**
 * @deprecated use makeTrendlineDataV2 instead. Keeping this method just for reference.
 */
export const makeTrendlineData = (
  sensorDataMap: SensorDataMap | undefined,
  analytic: Reading | AnalyticFunction,
  resolution: Resolution,
  trendlinePeriods?: number,
  forecastPeriods?: number,
  visibleExtrems?: TimeSeriesData.Extremes
): [number, number][] | undefined => {
  if (sensorDataMap === undefined) {
    return undefined;
  }

  const dataEntries = Array.from(sensorDataMap.entries());
  let data: [number, number][] = [];

  if (dataEntries.length) {
    const _analytic = analyticTrendlineMap(analytic);
    const xValues: number[] = [];
    const yValues: number[] = [];

    const { from, to } = visibleExtrems ?? {
      from: dataEntries[0][0],
      to: dataEntries[dataEntries.length - 1][0],
    };
    for (const dataEntry of dataEntries) {
      const xVal = dataEntry[0];
      if (xVal >= from && xVal <= to) {
        xValues.push(xVal);
        yValues.push(dataEntry[1]?.get(_analytic) ?? 0);
      }
    }
    const sliceValue = trendlinePeriods * -1;
    data = calculateTrendline(
      xValues.slice(sliceValue),
      yValues.slice(sliceValue),
      forecastPeriods,
      resolution
    );
  }
  return data;
};

const calculateTrendline = (
  xValues: number[],
  yValues: number[],
  forecastPeriods: number | undefined,
  resolution: Resolution
): [number, number][] => {
  const data: [number, number][] = [];

  const xAverage =
    xValues.reduce((sum, value) => sum + value, 0) / xValues.length;
  const yAverage =
    yValues.reduce((sum, value) => sum + value, 0) / yValues.length;

  const sumXY = xValues.reduce((sum, xVal, index) => {
    sum += (xVal - xAverage) * (yValues[index] - yAverage);
    return sum;
  }, 0);

  const sumXX = xValues.reduce((sum, xVal) => {
    sum += (xVal - xAverage) * (xVal - xAverage);
    return sum;
  }, 0);

  const slope = sumXY / sumXX;
  const intercept = yAverage - slope * xAverage;

  let newX = xValues;

  if (forecastPeriods) {
    const period =
      resolution !== 'RAW' && xValues.length > 2
        ? xValues[xValues.length - 1] - xValues[xValues.length - 2]
        : 900000;
    const forecastX = [xValues[xValues.length - 1] + period];
    for (let i = 0; i < forecastPeriods - 1; i++) {
      forecastX.push(forecastX[i] + period);
    }
    newX = [...xValues, ...forecastX];
  }

  for (const x of newX) {
    const y = slope * x + intercept;
    data.push([x, y]);
  }

  return data;
};

export function makeTrendlineDataV2(
  data: [timestamp: number, ...values: (number | null)[]][],
  extremes: { from: number; to: number } | undefined,
  periods: number,
  valueIndex = 1
): [timestamp: number, value: number][] {
  if (!data?.length) return [];
  // ###########################################################################
  // Reducing the data to contain only the desired points.

  let d = [] as [timestamp: number, value: number][];
  if (!periods) return [];

  for (let i = data.length - 1; i >= 0; i--) {
    const timestamp = data[i][0];
    const value = data[i][valueIndex];
    if (extremes === undefined || timestamp <= extremes.to) {
      if (periods && d.length >= periods) break;
      d.push([timestamp, value]);
    }
  }

  if (!d.length) return [];

  d = d.reverse();

  // ###########################################################################
  // Generating the trendline data.

  let sumX = 0;
  let sumY = 0;
  let sumXY = 0;
  let sumX2 = 0;

  for (let i = 0; i < periods; i++) {
    const x = d[i][0];
    const y = d[i][1];
    if (y === null) continue;

    sumX += x;
    sumY += y;
    sumXY += x * y;
    sumX2 += x ** 2;
  }

  const slope = (periods * sumXY - sumX * sumY) / (periods * sumX2 - sumX ** 2);
  const intercept = (sumY - slope * sumX) / periods;
  const trendlineData = [] as [timestamp: number, value: number][];

  for (let i = 0; i < periods; i++) {
    const x = d[i][0];
    const y = x * slope + intercept;
    trendlineData.push([x, y]);
  }

  return trendlineData;
}

export function makeTrendlineForecastDataV2() {}
