import {
  StackableInstanceWrapperProps,
  StackableSeriesProps,
  createContext,
  createSlots,
  createStackableChart,
  useStableEventHandlers,
} from '../../core/_summaryze-chart';
import { useGlobalization } from '../../../i18n';
import * as Options from './ili-chart-options';
import * as React from 'react';
import Highcharts from 'highcharts/highstock';
import { resolutionToZoomButtons } from '../../presets/insight-historical-chart/insight-historical-chart.utils';

import type {
  IliChartEventHandlers,
  IliChartOptions,
  IliChartSeriesId,
  IliChartSeriesOptions,
} from './ili-chart-types';
import { getTheme } from '../../core/utils/theme-utils';
import { getThemeColorSchema } from '../../../utils';

const DAILY_GAP_SIZE = 86_400_000;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Chart
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const Chart = createStackableChart('IliChartRoot', 26);

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Slots
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const Slots = createSlots<'series-group'>('IliChart');

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Chart Root
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type IliChartRootProps = IliChartOptions &
  Omit<IliChartEventHandlers, 'syncDateTimeRangePicker'> & {
    children: React.ReactNode;
  };

type IliChartContext = Pick<
  IliChartOptions,
  | 'defaultLimit'
  | 'snapSelection'
  | 'enableChartStack'
  | 'enableHorizontalGrids'
  | 'enableVerticalGrids'
  | 'enableMarkers'
  | 'lowestResolution'
  | 'status'
  | 'selectedTheme'
>;

const [RootContextProvider, useRootContext] = createContext<
  IliChartContext & Pick<IliChartEventHandlers, 'onXAxisExtremesChange'>
>('RootContextProvider');

const IliChartRoot = React.forwardRef<
  { chart: Highcharts.Chart | undefined },
  IliChartRootProps
>((props, ref): React.ReactElement => {
  return (
    <Chart.ChartRoot>
      <_IliChartRoot {...props} ref={ref} />
    </Chart.ChartRoot>
  );
});

IliChartRoot.displayName = 'IliChartRoot';

const _IliChartRoot = React.forwardRef<
  { chart: Highcharts.Chart | undefined },
  IliChartRootProps
>((props, ref): React.ReactElement => {
  const eventHandlersRef = useStableEventHandlers<IliChartEventHandlers>({
    onXAxisExtremesChange: props.onXAxisExtremesChange,
    onSeriesVisibilityChange: props.onSeriesVisibilityChange,
    syncDateTimeRangePicker: Chart.useRootContext('MassBalanceChartRoot')
      .syncDateTimeRangePicker,
  });

  const stackedFooterInstanceInitialOptions = React.useMemo(() => {
    return Options.makeOptionsWithEventHandlers(
      Options.stackedFooterInstanceInitialOptions,
      eventHandlersRef
    );
  }, [eventHandlersRef]);

  const overlayInstanceInitialOptions = React.useMemo(() => {
    return Options.makeOptionsWithEventHandlers(
      Options.overlayInstanceInitialOptions,
      eventHandlersRef
    );
  }, [eventHandlersRef]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  return (
    <RootContextProvider
      lowestResolution={props.lowestResolution}
      defaultLimit={props.defaultLimit}
      snapSelection={props.snapSelection}
      enableChartStack={props.enableChartStack}
      enableHorizontalGrids={props.enableHorizontalGrids}
      enableVerticalGrids={props.enableVerticalGrids}
      enableMarkers={props.enableMarkers}
      onXAxisExtremesChange={props.onXAxisExtremesChange}
      status={props.status}
      selectedTheme={props.selectedTheme}>
      <div
        style={{
          position: 'absolute',
          top: 24,
          right: 16,
          zIndex: 999,
        }}>
        <Chart.ChartTimeRange />
      </div>
      {props.enableChartStack ? (
        <Slots.Slottable slottableChildren={props.children}>
          {(slots) => {
            const series = slots.getSlot('series-group');

            return (
              <Chart.ChartStackedInstances
                instanceWrapperComponent={StackableInstanceWrapper}
                headerInstanceInitialOptions={
                  Options.stackedHeaderInstanceInitialOptions
                }
                seriesInstanceInitialOptions={
                  Options.stackedSeriesInstanceInitialOptions
                }
                footerInstanceInitialOptions={
                  stackedFooterInstanceInitialOptions
                }
                constructorFunction={Highcharts.stockChart}>
                {series}
              </Chart.ChartStackedInstances>
            );
          }}
        </Slots.Slottable>
      ) : (
        <Chart.ChartOverlayInstance
          ref={ref}
          instanceWrapperComponent={StackableInstanceWrapper}
          initialOptions={overlayInstanceInitialOptions}
          constructorFunction={Highcharts.stockChart}>
          {props.children}
        </Chart.ChartOverlayInstance>
      )}
      {props.enableChartStack ? (
        <div className="ref-holder" style={{ display: 'none' }}>
          <Chart.ChartOverlayInstance
            ref={ref}
            instanceWrapperComponent={StackableInstanceWrapper}
            initialOptions={overlayInstanceInitialOptions}
            constructorFunction={Highcharts.stockChart}>
            {props.children}
          </Chart.ChartOverlayInstance>
        </div>
      ) : null}
    </RootContextProvider>
  );
});

_IliChartRoot.displayName = '_IliChartRoot';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Series Group Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
interface IliChartSeriesGroupProps {
  children: React.ReactNode;
}

const IliChartSeriesGroup = (
  props: IliChartSeriesGroupProps
): React.ReactElement => {
  return <Slots.Slot id="series-group">{props.children}</Slots.Slot>;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Chart Series
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type IliChartSeriesProps = IliChartSeriesOptions;

const IliChartSeries = (
  props: IliChartSeriesProps & StackableSeriesProps
): null => {
  const { t } = useGlobalization();
  const seriesRef = Chart.useSeries(props, 'IliChartSeries');
  const instanceRef = Chart.useInstance('IliChartSeries');
  const rootContext = useRootContext('IliChartSeries');

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series name
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    const names: Record<IliChartSeriesId, string> = {
      ili: t('ILI'),
      mnf: t('Minimum Nightly Flow'),
      massBalance: t('Mass Balance - Usage'),
    };

    seriesRef.current?.update({
      name: names[props.id],
    } as Highcharts.SeriesOptionsType);
  }, [seriesRef, props.id]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series color
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    const theme = rootContext.selectedTheme;
    const _colors = getThemeColorSchema(theme);
    const colors: Record<IliChartSeriesId, string> = {
      ili: theme === 'Default' ? '#00ABD1' : _colors[0],
      mnf: theme === 'Default' ? '#758D95' : _colors[1],
      massBalance: theme === 'Default' ? '#E56C43' : _colors[2],
    };

    seriesRef.current?.update({
      color: colors[props.id],
    } as Highcharts.SeriesOptionsType);
  }, [seriesRef, props.id, rootContext.selectedTheme]);

  /* * * * * * * * * * * *
   * Sets series data
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (
      props.stackedInstanceType === 'stacked-header' ||
      props.stackedInstanceType === 'stacked-footer'
    ) {
      seriesRef.current?.update({
        data: props.navigatorData,
        gapSize: DAILY_GAP_SIZE,
      } as Highcharts.SeriesOptionsType);
    } else {
      seriesRef.current?.update({
        data: props.data,
      } as Highcharts.SeriesOptionsType);
    }
  }, [
    props.data,
    props.navigatorData,
    props.stackedInstanceType,
    props.status,
    seriesRef,
  ]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Adds navigator data to series
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (props.stackedInstanceType === undefined) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      seriesRef.current?.update({
        navigatorOptions: {
          data: props.navigatorData,
          gapSize: DAILY_GAP_SIZE,
        },
      } as Highcharts.SeriesOptionsType);
    }
  }, [seriesRef, props.navigatorData, props.stackedInstanceType]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets overlay separate Y axis / stacked label
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (props.stackedInstanceType === 'stacked-series') {
      let title: string | undefined = undefined;

      const labels: Record<IliChartSeriesId, string> = {
        ili: 'Infrastructure Leakage Index (ILI)',
        mnf: 'Minimum Nightly Flow',
        massBalance: 'Mass Balance - Usage Volume',
      };

      title = `${labels[props.id]}`;
      if (props.id !== 'ili' && props.unit) {
        title += ` (${props.unit})`;
      }

      instanceRef.current?.yAxis[0]?.update({
        title: { text: title },
      });
    }
  }, [instanceRef, props.id, props.unit, props.stackedInstanceType]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series visibility
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    seriesRef.current?.update({
      visible: !props.hidden,
    } as Highcharts.SeriesOptionsType);
  }, [seriesRef, props.hidden]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Set stacked mode series status
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (props.stackedInstanceType === 'stacked-series') {
      if (props.status === 'loading') {
        instanceRef.current?.showLoading();
      } else if (props.status === 'rejected') {
        instanceRef.current?.showLoading(t('Failed to retrieve data'));
      } else {
        if (props.data === undefined || props.data.length === 0) {
          instanceRef.current?.showLoading(t('No Data'));
        } else {
          instanceRef.current?.hideLoading();
        }
      }
    }
  }, [instanceRef, t, props.stackedInstanceType, props.status, props.data]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  return null;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Stackable Instance Wrapper Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const StackableInstanceWrapper = (
  props: StackableInstanceWrapperProps
): React.ReactElement => {
  const { t } = useGlobalization();
  const rootContext = useRootContext('StackableInstanceWrapper');
  const instanceRef = Chart.useInstance('StackableInstanceWrapper');
  const instanceSeriesProps = Chart.useInstanceSeriesProps<IliChartSeriesProps>(
    'StackableInstanceWrapper'
  );

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets Zoom Options
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (
      props.stackedInstanceType === undefined ||
      props.stackedInstanceType === 'stacked-header'
    ) {
      const lowestResolution = rootContext.lowestResolution ?? 'RAW';
      const defaultLimit = rootContext.defaultLimit ?? 180;
      const zoomButtons = resolutionToZoomButtons(
        lowestResolution,
        defaultLimit
      );

      instanceRef.current?.update({
        rangeSelector: { buttons: zoomButtons },
      });
    }
  }, [
    instanceRef,
    props.stackedInstanceType,
    rootContext.lowestResolution,
    rootContext.defaultLimit,
  ]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets overlay main Y axis label
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (props.stackedInstanceType === undefined) {
      const title = t('Infrastructure Leakage Index (ILI)');

      instanceRef.current?.update({
        yAxis: { title: { text: title } },
      });
    }
  }, [instanceRef, props.stackedInstanceType]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets horizontal gridlines
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (
      props.stackedInstanceType === undefined ||
      props.stackedInstanceType === 'stacked-series'
    ) {
      instanceRef.current?.yAxis[0]?.update({
        gridLineWidth: rootContext.enableHorizontalGrids ? 1 : 0,
      });
    }
  }, [
    instanceRef,
    props.stackedInstanceType,
    rootContext.enableHorizontalGrids,
  ]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets vertical gridlines
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (
      props.stackedInstanceType === undefined ||
      props.stackedInstanceType === 'stacked-series'
    ) {
      instanceRef.current?.xAxis[0]?.update({
        gridLineWidth: rootContext.enableVerticalGrids ? 1 : 0,
      });
    }
  }, [instanceRef, props.stackedInstanceType, rootContext.enableVerticalGrids]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Set snap selection
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    const min = rootContext.snapSelection?.start;
    const max = rootContext.snapSelection?.end;
    const currentMin = instanceRef.current?.xAxis[0]?.min ?? undefined;
    const currentMax = instanceRef.current?.xAxis[0]?.max ?? undefined;

    if (min !== undefined || max !== undefined) {
      instanceRef.current?.xAxis[0]?.setExtremes(
        min ?? currentMin,
        max ?? currentMax
      );
    }
  }, [
    instanceRef,
    rootContext.snapSelection?.start,
    rootContext.snapSelection?.end,
  ]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Set overlay mode series status
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const { status, noData } = React.useMemo(() => {
    const status = instanceSeriesProps[0]?.status;
    const noData =
      (status === undefined || status === 'resolved') &&
      instanceSeriesProps.every(
        ({ data }) => data === undefined || data.length === 0
      );

    return { status, noData };
  }, [instanceSeriesProps]);

  React.useEffect(() => {
    if (props.stackedInstanceType === undefined) {
      if (status === 'loading') {
        instanceRef.current?.showLoading();
        instanceRef.current?.update({
          chart: {
            events: {
              render: function (this) {
                const container = document.getElementById(this?.container?.id);
                container
                  ?.getElementsByClassName('highcharts-scrollbar-thumb')[0]
                  ?.setAttribute('display', 'none');
                container
                  ?.getElementsByClassName('highcharts-scrollbar-rifles')[0]
                  ?.setAttribute('display', 'none');
              },
            },
          },
        });
      } else if (status === 'rejected') {
        instanceRef.current?.showLoading(t('Failed to retrieve data'));
      } else {
        if (noData) {
          instanceRef.current?.showLoading(t('No Data'));
        } else {
          instanceRef.current?.hideLoading();
          instanceRef.current?.update({
            chart: {
              events: {
                render: function (this) {
                  const container = document.getElementById(
                    this?.container?.id
                  );
                  container
                    ?.getElementsByClassName('highcharts-scrollbar-thumb')[0]
                    ?.removeAttribute('display');
                  container
                    ?.getElementsByClassName('highcharts-scrollbar-rifles')[0]
                    ?.removeAttribute('display');
                },
              },
            },
          });
        }
      }
    }
  }, [instanceRef, t, props.stackedInstanceType, status, noData]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets data markers
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (
      props.stackedInstanceType === undefined ||
      props.stackedInstanceType === 'stacked-series'
    ) {
      instanceRef.current?.update({
        plotOptions: {
          series: {
            marker: {
              enabled: rootContext.enableMarkers,
              radius: rootContext.enableMarkers ? 4 : 0,
            },
          },
        },
      });
    }
  }, [instanceRef, rootContext.enableMarkers, props.stackedInstanceType]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets Theme
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    const theme = rootContext.selectedTheme;
    const themeOptions = getTheme(theme ?? 'Default');
    instanceRef.current?.update({ ...themeOptions });
  }, [instanceRef, rootContext.selectedTheme]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  return <>{props.children}</>;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { IliChartRoot, IliChartSeries, IliChartSeriesGroup };

export type {
  IliChartRootProps,
  IliChartSeriesGroupProps,
  IliChartSeriesProps,
};
