import { CircularProgress, Grid } from '@mui/material';
import {
	DateTimeInputMode,
	StylovyzeFormV2,
	useIsFeatureEnabled,
	useUnits,
	useTzDateTime,
	useCompanyDateTime,
	useSettings,
} from '@innovyze/stylovyze';
import { TFunc, useGlobalization } from '@Translations';
import { Tab, TabPanel, TabWrapper, Tabs } from '@Components/Tabs';
import {
	a11yProps,
	fixupDateTimeForInputDateTime,
	getJustValues,
} from '@Utils';
import {
	refreshInsp,
	selectFullInspection,
	selectFullInspectionIsWaiting,
} from '@Selectors/fullInspection.selectors';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
	DateTimeProps,
	getChangedFormikFields,
	getDetailGrid,
	getDomainItems,
	NumericProps,
	processFormSubmit,
	UnitProps,
} from '@Utils/common.utils';
import {
	refreshInspection,
	updateInspection,
} from '@Actions/inspection.actions';
import ModifiedInfoCard from '@Components/ModifiedInfoCard/ModifiedInfoCard';
import { FormikValues } from 'formik';
import { InspectionStandard } from '@Types/inspection.types';

const EDITABLE_FIELDS = ['direction', 'startNodeRef', 'finishNodeRef'];

const NON_EDITABLE_FIELDS = ['uploadedBy', 'uploadDateTime', 'pipelineRef'];

const isEditableField = (fieldName: string, fullEdit: boolean) => {
	return fullEdit
		? !NON_EDITABLE_FIELDS.includes(fieldName)
		: EDITABLE_FIELDS.includes(fieldName);
};

const HIDDEN_FIELDS = ['uploadDateTime', 'scheduledDate'];

const isHiddenField = (fieldName: string, hideFields: boolean) => {
	return hideFields ? HIDDEN_FIELDS.includes(fieldName) : false;
};

const generalFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'uploadedBy', alias: t('Uploaded By') },
	{ name: 'uploadDateTime', alias: t('Upload Date') },
	{ name: 'scheduledDate', alias: t('Scheduled Date') },
	{ name: 'client', alias: t('Client') },
	{ name: 'surveyedBy', alias: t('Name of Surveyor') },
	// eslint-disable-next-line prettier/prettier, quotes
	{ name: 'clientsJobRef', alias: t("Client's Job Reference") },
	// eslint-disable-next-line prettier/prettier, quotes
	{ name: 'contractorsJobRef', alias: t("Contractor's Job Reference") },
	{ name: 'scheduledDate', alias: t('Scheduled Date') },
	{ name: 'date', alias: t('Date') },
	{ name: 'time', alias: t('Time') },
	{ name: 'useOfDrainSewer', alias: t('Use of Drain/Sewer') },
	{ name: 'typeOfDrainSewer', alias: t('Type of Drain/Sewer') },
	{ name: 'direction', alias: t('Direction') },
	{ name: 'precleaned', alias: t('Pre-cleaned') },
	{ name: 'criticalDrainSewer', alias: t('Critical/Strategic Drain/Sewer') },
	{ name: 'purposeOfInspection', alias: t('Purpose of Inspection') },
	{ name: 'inspectionStage', alias: t('Inspection Stage') },
	{ name: 'flowControlMeasures', alias: t('Flow Control Measures') },
	{ name: 'weather', alias: t('Weather') },
	{ name: 'temperature', alias: t('Temperature') },
	{ name: 'surveyedLength', alias: t('Surveyed Length') },
	{ name: 'methodOfInspection', alias: t('Method of Inspection') },
	{ name: 'standard', alias: t('Standard') },
	{ name: 'clientDefined1', alias: t('Client Defined 1') },
	{ name: 'clientDefined2', alias: t('Client Defined 2') },
	{ name: 'clientDefined3', alias: t('Client Defined 3') },
	{ name: 'clientDefined4', alias: t('Client Defined 4') },
	{ name: 'clientDefined5', alias: t('Client Defined 5') },
	{ name: 'clientDefined6', alias: t('Client Defined 6') },
	{ name: 'generalRemarks', alias: t('General Remarks') },
];

const locationFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'drainageArea', alias: t('Drainage Area') },
	{ name: 'divisionDistrict', alias: t('Division/District') },
	{ name: 'locationStreet', alias: t('Location (Street Name)') },
	{ name: 'locationTown', alias: t('Location (Town or Village)') },
	{ name: 'locationTypeCode', alias: t('Location Type Code') },
	{ name: 'landOwner', alias: t('Land Ownership') },
];

const pipeFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'upstreamManholeId', alias: t('Upstream Manhole Number') },
	{ name: 'downstreamManholeId', alias: t('Downstream Manhole Number') },
	{ name: 'pipelineRef', alias: t('Pipeline Length Reference') },
	{ name: 'heightDiameter', alias: t('Height/Diameter') },
	{ name: 'width', alias: t('Width') },
	{ name: 'pipeUnitLength', alias: t('Pipe Unit Length') },
	{ name: 'shape', alias: t('Shape') },
	{ name: 'material', alias: t('Material') },
	{ name: 'liningMaterial', alias: t('Lining Material') },
	{ name: 'liningType', alias: t('Lining Type') },
	{ name: 'yearConstructed', alias: t('Year Constructed') },
	{ name: 'lateralInspStart', alias: t('Lateral Inspection Start Point') },
	{
		name: 'longLocStartLat',
		alias: t('Longitudinal Location of Start of Lateral'),
	},
	{
		name: 'circLocStartLat',
		alias: t('Circumferencial Location of Start of Lateral'),
	},
];

const manholesFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'startNodeRef', alias: t('Start Node Reference') },
	{ name: 'depthAtStartNode', alias: t('Depth at Start Node') },
	{ name: 'startNodeGridRefX', alias: t('Start Node Coordinate X') },
	{ name: 'startNodeGridRefY', alias: t('Start Node Coordinate Y') },
	{ name: 'node1Ref', alias: t('Node 1 Reference') },
	{ name: 'node1GridRefX', alias: t('Node 1 Node Coordinate X') },
	{ name: 'node1GridRefY', alias: t('Node 1 Node Coordinate Y') },
	{ name: 'finishNodeRef', alias: t('Finish Node (Node 2 Reference)') },
	{ name: 'depthAtFinishNode', alias: t('Depth at Finish Node') },
	{ name: 'node2GridRefX', alias: t('Finish Node (Node 2) Coordinate X') },
	{ name: 'node2GridRefY', alias: t('Finish Node (Node 2) Coordinate Y') },
	{ name: 'node3Ref', alias: t('Node 3 Reference') },
	{ name: 'node3GridRefX', alias: t('Node 3 Node Coordinate X') },
	{ name: 'node3GridRefY', alias: t('Node 3 Node Coordinate Y') },
];

const mediaFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'videoImageStorage', alias: t('Video Image Storage Media') },
	{
		name: 'videoImageLocationSystem',
		alias: t('Video Image Location System'),
	},
	{ name: 'videoImageFormat', alias: t('Video Image Format') },
	{ name: 'videoImageFilename', alias: t('Video Image Filename') },
	{ name: 'videoVolumeRef', alias: t('Video Volume Reference') },
	{
		name: 'photographicStorageFormat',
		alias: t('Photographic Image Storage Format'),
	},
	{ name: 'photographicStorage', alias: t('Photographic Storage') },
	{ name: 'photographicVolume', alias: t('Photographic Volume Reference') },
];

const hardCodeUnitFields: { [key: string]: UnitProps } = {
	heightDiameter: { unit: 'mm', min: 0, step: '1' },
	width: { unit: 'mm', min: 0, step: '1' },
	depthAtStartNode: { unit: 'm', step: '1' },
	depthAtFinishNode: { unit: 'm', step: '1' },
	expectedLength: { unit: 'm', min: 0, step: '1' },
	surveyedLength: { unit: 'm', min: 0, step: '1' },
	pipeUnitLength: { unit: 'm', min: 0, step: '1' },
};

const hardCodeDateTimeFields: {
	[key: string]: DateTimeProps;
} = {
	uploadDateTime: {
		mode: DateTimeInputMode.DATE_TIME,
		field: 'uploadDateTime',
		useCompanyDateTime: true,
	},
	scheduledDate: {
		mode: DateTimeInputMode.DATE_TIME,
		field: 'scheduledDate',
	},
	date: { mode: DateTimeInputMode.DATE, field: 'surveyDateTime' },
	time: { mode: DateTimeInputMode.TIME, field: 'surveyDateTime' },
};

const hardCodeCurrencyFields: {
	[key: string]: NumericProps;
} = {};

const hardCodeNumericFields: {
	[key: string]: NumericProps;
} = {
	yearConstructed: { min: 0 },
	startNodeGridRefX: { step: 0.1 },
	startNodeGridRefY: { step: 0.1 },
	node1GridRefX: { step: 0.1 },
	node1GridRefY: { step: 0.1 },
	node2GridRefX: { step: 0.1 },
	node2GridRefY: { step: 0.1 },
	node3GridRefX: { step: 0.1 },
	node3GridRefY: { step: 0.1 },
};

const hardCodeBooleanFields: {
	[key: string]: boolean;
} = {};

const getDomainValues = (t: TFunc, fieldName: string) => {
	let domainValueDic: { [key: string]: string } = {};
	switch (fieldName.toLowerCase()) {
		case 'locationCode'.toLowerCase():
			domainValueDic = {
				RD: t('Road'),
				FWY: t('A footway beside a road'),
				VG: t('Verge'),
				PD: t('Other Pedestrian area'),
				FLD: t('Fields (farmland and public open space)'),
				PR: t('Property with Buildings'),
				GDN: t('Gardens (within private property)'),
				BLG: t('Under a permanent Building'),
				WLD: t('Woodland'),
				DIF: t(
					'Difficult access (motorway, railway, watercourse, inside building)',
				),
				WWY: t('Under a Waterway'),
				XS1: t('Left Outside Verge (adjacent to footway)'),
				XS2: t('Left Footway'),
				XS3: t('Left Verge/Verge'),
				XS4: t('Lane 1/Hard Shoulder'),
				XS5: t('Lane 2/Slow Lane'),
				XS6: t('Lane 3/Central Lane'),
				XS7: t('Lane 4/Fast Lane'),
				XS8: t('Right Verge/Central Reserve/Right Verge'),
				XS9: t('Right Footway'),
				XS0: t('Right Outside Verge (adjacent to footway) Not used'),
				XSE: t('Right turning lane'),
				XSQ: t('Slip Road'),
				XSR: t('Bus Lane'),
				XST: t('Crawler Lane'),
				XSW: t('Left Turning'),
				XSY: t('Any Other Option'),
			};
			break;
		case 'landObservation'.toLowerCase():
			domainValueDic = {
				PR: t('Private'),
				PU: t('Public'),
				X: t('Not Known'),
			};
			break;
		case 'useOfDrainSewer'.toLowerCase():
			domainValueDic = {
				C: t('Combined'),
				F: t('Foul'),
				S: t('Storm'),
				T: t('Trade Effluent'),
				W: t('Culverted Watercourse'),
				LD: t('Subsoil or Field Drainage'),
				Z: t('Other'),
			};
			break;
		case 'typeOfDrainSewer'.toLowerCase():
			domainValueDic = {
				A: t('Gravity Drain/Sewer'),
				B: t('Rising Main'),
				V: t('Vacuum'),
			};
			break;
		case 'direction'.toLowerCase():
			domainValueDic = {
				U: t('Survey Upstream'),
				D: t('Survey Downstream'),
				Z: t('Not Known'),
			};
			break;
		case 'shape'.toLowerCase():
			domainValueDic = {
				A: t('Arched'),
				B: t('Barrel'),
				C: t('Circular'),
				E: t('Egg-shaped'),
				H: t('Horseshoe'),
				O: t('Oval'),
				R: t('Rectangular'),
				T: t('Trapezoidal'),
				U: t('U-shaped with Flat Top'),
				K: t('Kerb Block'),
				CSC: t('Circular with Smaller Channel'),
				RSC: t('Rectangular with Smaller Channel'),
				Z: t('Other'),
			};
			break;
		case 'material'.toLowerCase():
			domainValueDic = {
				AC: t('Asbestos Cement'),
				BL: t('Bitumen (lining)'),
				BR: t('Brick'),
				CI: t('Cast Iron'),
				CL: t('Cement Mortar (lining)'),
				CO: t('Concrete'),
				CS: t('Concrete Segments'),
				DI: t('Ductile Iron'),
				EP: t('Epoxy'),
				FC: t('Fibre Cement'),
				FRP: t('Fibre Reinforced Plastics'),
				GI: t('Grey Cast Iron'),
				MAC: t('Masonry - Regular Courses'),
				MAR: t('Masonry - Randomly Coursed'),
				MX: t('Mixed Material Construction'),
				PVC: t('Polyvinyl Chloride'),
				PE: t('Polyethylene'),
				PF: t('Pitch Fibre'),
				PP: t('Polypropylene'),
				PS: t('Polyester'),
				RC: t('Reinforced Concrete'),
				SPC: t('Sprayed Concrete'),
				ST: t('Steel'),
				VC: t('Vitrified Clay'),
				X: t('Unidentified Material'),
				XI: t('Unidentified Type of Iron or Steel'),
				XP: t('Unidentified Type of Plastics'),
				Z: t('Other'),
			};
			break;
		case 'liningType'.toLowerCase():
			domainValueDic = {
				M: t('Lining Inserted During Manufacture'),
				SP: t('Sprayed Lining'),
				CIP: t('Cured-In=Place Lining'),
				SEG: t('Segmental Linings'),
				DP: t('Lining with Discrete Pipes'),
				CP: t('Lining with Continuous Pipes'),
				CF: t('Close Fit Lining'),
				SW: t('Spirally Wound Lining'),
				Z: t('Other'),
			};
			break;
		case 'preCleaned'.toLowerCase():
			domainValueDic = {
				Y: t('Yes'),
				N: t('No'),
				Z: t('Unknown'),
			};
			break;
		case 'purposeOfInspection'.toLowerCase():
			domainValueDic = {
				A: t('Investigation of known structural or service defects'),
				B: t('Investigation of infiltration problems'),
				C: t('Post completion inspection of repairs or renovations'),
				D: t('Pre-adoption survey prior to vesting as public sewer'),
				E: t('Post completion inspection of new sewers'),
				F: t(
					'Sample survey of sewers to determine asset condition of a sewer system',
				),
				G: t('Routine inspection of condition'),
				H: t('Investigation of a suspected operational problem'),
				I: t('Investment planning'),
				J: t('End of warranty period'),
				X: t('Other'),
			};
			break;
		case 'inspectionStage'.toLowerCase():
			domainValueDic = {
				A: t('Information provided by client'),
				B: t('Information provided by the inspector to client'),
				Z: t('Other'),
			};
			break;
		case 'flowControlMeasures'.toLowerCase():
			domainValueDic = {
				N: t('No flow control'),
				BL: t('Flows blocked upstream'),
				PB: t('Flows partially blocked upstream'),
				X: t('Other'),
			};
			break;
		case 'weather'.toLowerCase():
			domainValueDic = {
				D: t('No rain or snow'),
				R: t('Rain'),
				S: t('Melting snow or ice'),
			};
			break;
		case 'methodOfInspection'.toLowerCase():
			domainValueDic = {
				A: t('Direct Inspection (man entry)'),
				B: t('CCTV'),
				C: t('Inspection from manhole or inspection chamber only'),
			};
			break;
		case 'videoImageStorageMedia'.toLowerCase():
			domainValueDic = {
				VHS: t('VHS video cassette tape'),
				CD: t('CD'),
				DVD: t('DAD'),
				PHD: t('Portable hard drive'),
				X: t('Other'),
			};
			break;
		case 'videoImageFormat'.toLowerCase():
			domainValueDic = {
				F: t('Fixed to media type'),
				MPEG1: t('MPEG1'),
				MPEG2: t('MPEG2'),
				MPEG4: t('MPEG4'),
				AVI: t('AVI'),
				VOB: t('VOB'),
				DIVX: t('DIVX'),
				Z: t('Other'),
			};
			break;
		case 'photographicImageStorageFormat'.toLowerCase():
			domainValueDic = {
				A: t('Still photographs'),
				C: t('Windows Meta File (WMF)'),
				D: t('Graphics Image File (GIF)'),
				E: t('JPEG file'),
				F: t('TIF'),
				G: t('PNG'),
				X: t('Other'),
			};
			break;
		case 'nodeType'.toLowerCase():
			domainValueDic = {
				MH: t('Manhole'),
				IC: t('Inspection Chamber'),
				RE: t('Rodding Eye'),
				LH: t('Lamphole'),
				OF: t('Outfall'),
				BR: t('Major Connection without Manhole'),
				GY: t('Gully'),
				CP: t('Catchpit'),
				SK: t('Soakaway'),
				OS: t('Oil Separator'),
				RG: t('Running Trap'),
				BN: t('Buchan Trap'),
				WR: t('Winser Trap'),
				OC: t('Other Special Chamber'),
			};
			break;
		case 'verticalReferencePoint'.toLowerCase():
			domainValueDic = {
				I: t(
					'Invert lowest outgoing pipe in the manhole/inspection chamber',
				),
				C: t(
					'Cover (highest corner of frame or highest point on a circular frame)',
				),
				OD: t('National Datum'),
				LD: t('Local Datum'),
				Z: t('Other'),
			};
			break;
		case 'useManhole'.toLowerCase():
			domainValueDic = {
				C: t('Combined'),
				F: t('Foul'),
				S: t('Surface Water'),
				T: t('Trade Effluent'),
				W: t('Culverted Watercourse'),
				Z: t('Other'),
			};
			break;
		case 'shapeOfCover'.toLowerCase():
			domainValueDic = {
				R: t('Rectangular'),
				C: t('Circular'),
				T: t('Triangular'),
				OV: t('Oval'),
				HX: t('Hexagonal'),
				OC: t('Octagonal'),
				Z: t('Other'),
			};
			break;
		case 'shapeOfAccess'.toLowerCase():
			domainValueDic = {
				C: t('Circular'),
				HX: t('Hexagonal'),
				OC: t('Octagonal'),
				OV: t('Oval'),
				R: t('Rectangular'),
				T: t('Triangular'),
				Z: t('Other'),
			};
			break;
		case 'materialOfStep'.toLowerCase():
			domainValueDic = {
				AL: t('Aluminium'),
				GI: t('Galvanised Iron'),
				IR: t('Iron/Steel'),
				PL: t('Plastic'),
				PM: t('Metal Encapsulated in Plastic'),
				SS: t('Stainless Steel'),
			};
			break;
		case 'locationOfObservation'.toLowerCase():
			domainValueDic = {
				CF: t('Cover and Frame'),
				AC: t('Adjusting Construction'),
				S: t('Shaft'),
				T: t('Taper'),
				RS: t('Reducing Slab'),
				CH: t('Chamber'),
				L: t('Landing'),
				B: t('Benching'),
				CHL: t('Channel'),
				I: t('Invert'),
			};
			break;
		default:
			break;
	}

	return domainValueDic;
};

interface Props {
	onReRender?: () => void;
}

export const MSCCInspectionProps = ({ onReRender }: Props): JSX.Element => {
	const { t } = useGlobalization();
	const { getSystemValueFormatted } = useUnits();
	const dispatch = useDispatch();
	const { currentDateTime } = useCompanyDateTime();
	const formatDateUTC = useTzDateTime({ timeZone: 'UTC' }).formatDate;
	const { companySettings } = useSettings();

	const [tabValue, setTabValue] = React.useState(0);
	const isLoading = selectFullInspectionIsWaiting();
	const [editHeaderDetails, setEditHeaderDetails] = React.useState(false);
	const [formSubmitted, setFormSubmitted] = React.useState(false);
	const inspection = selectFullInspection();

	const origDetails = useMemo(() => {
		setFormSubmitted(false);

		const values = getJustValues(inspection, false);

		const keys = Object.keys(values);
		keys.forEach(key => {
			// Adjust any date time fields for timezone/dst offsets caused by the datetime control
			const field = Object.entries(hardCodeDateTimeFields).find(
				value => value[1].field === key,
			);
			if (field) {
				values[key] = fixupDateTimeForInputDateTime(
					values[key],
					field[1].useCompanyDateTime ? currentDateTime() : undefined,
				);
			}
		});

		return values;
	}, [inspection]);

	const viewDetails = useMemo(() => {
		const values = getJustValues(inspection, true);

		const keys = Object.keys(values);
		keys.forEach(key => {
			// Adjust any date time fields for timezone/dst offsets caused by the datetime control
			const field = Object.entries(hardCodeDateTimeFields).find(
				value => value[1].field === key,
			);
			if (field) {
				values[key] = fixupDateTimeForInputDateTime(
					values[key],
					field[1].useCompanyDateTime ? currentDateTime() : undefined,
				);
			}
		});

		return values;
	}, [inspection]);

	const inspUpdatestatus = refreshInsp();

	//feature to be behind a LaunchDarkley flag
	const isEditAllowedFlag = useIsFeatureEnabled(
		'info-360-edit-inspections-header',
	)
		? true
		: false;

	const isFullEditAllowedFlag = useIsFeatureEnabled(
		'info-360-edit-full-inspections',
	)
		? true
		: false;

	const isCreateAllowedFlag = useIsFeatureEnabled(
		'info-360-create-inspections',
	);

	const handleChangeTab = (event: unknown, newValue: number) => {
		setTabValue(newValue);
	};

	const handleFormSubmit = (data: FormikValues) => {
		// We should really used the touched state of a field but I cant get the Formik stuff to work properly
		const changes = getChangedFormikFields(origDetails, data);

		if (changes && Object.entries(changes).length !== 0) {
			setFormSubmitted(true);

			const allFields: { name: string; alias: string }[] = [
				...generalFields(t),
				...locationFields(t),
				...pipeFields(t),
				...manholesFields(t),
				...mediaFields(t),
			];

			const editedFieldsList = processFormSubmit(
				t,
				InspectionStandard.PACP,
				origDetails,
				changes,
				allFields,
				hardCodeDateTimeFields,
				hardCodeUnitFields,
				hardCodeCurrencyFields,
				hardCodeNumericFields,
				hardCodeBooleanFields,
				getSystemValueFormatted,
				formatDateUTC,
				companySettings,
				t('Date/Time of inspection'),
				{ date: 'date', time: 'time' },
			);

			dispatch(refreshInspection({ status: -1 })); //reset update flag and hide the controls
			dispatch(updateInspection(editedFieldsList)); //trigger update
		}
	};

	//when inspection has been updated, page needs to be refreshed to display latest details
	useEffect(() => {
		if (inspUpdatestatus === 200 && onReRender) onReRender();
	}, [inspUpdatestatus]);

	const general = (
		<ModifiedInfoCard
			title={t('General')}
			dataCy="modified-info-card-general"
			showEditBtn={
				origDetails['inspectionStatus'] !== 'approved' &&
				onReRender !== undefined
			}
			launchDarkleyFlag={isEditAllowedFlag}
			onEditClick={() => {
				setEditHeaderDetails(true);
			}}
			onCancelClick={() => {
				setEditHeaderDetails(false);
			}}
			inEditMode={editHeaderDetails}
			disabled={inspUpdatestatus === -1}>
			<Grid container>
				{getDetailGrid(
					t,
					generalFields(t),
					hardCodeUnitFields,
					hardCodeDateTimeFields,
					hardCodeCurrencyFields,
					hardCodeNumericFields,
					hardCodeBooleanFields,
					isEditableField,
					isFullEditAllowedFlag,
					isHiddenField,
					!isCreateAllowedFlag,
					getDomainValues,
					getDomainItems,
				)}
			</Grid>
		</ModifiedInfoCard>
	);

	const location = (
		<ModifiedInfoCard
			title={t('Location')}
			dataCy="modified-info-card-location"
			showEditBtn={
				origDetails['inspectionStatus'] !== 'approved' &&
				onReRender !== undefined
			}
			launchDarkleyFlag={isEditAllowedFlag}
			onEditClick={() => {
				setEditHeaderDetails(true);
			}}
			onCancelClick={() => {
				setEditHeaderDetails(false);
			}}
			inEditMode={editHeaderDetails}
			disabled={inspUpdatestatus === -1}>
			<Grid container>
				{getDetailGrid(
					t,
					locationFields(t),
					hardCodeUnitFields,
					hardCodeDateTimeFields,
					hardCodeCurrencyFields,
					hardCodeNumericFields,
					hardCodeBooleanFields,
					isEditableField,
					isFullEditAllowedFlag,
					undefined,
					undefined,
					getDomainValues,
					getDomainItems,
				)}
			</Grid>
		</ModifiedInfoCard>
	);

	const pipe = (
		<ModifiedInfoCard
			title={t('Construction')}
			dataCy="modified-info-card-construction"
			showEditBtn={
				origDetails['inspectionStatus'] !== 'approved' &&
				onReRender !== undefined
			}
			launchDarkleyFlag={isEditAllowedFlag}
			onEditClick={() => {
				setEditHeaderDetails(true);
			}}
			onCancelClick={() => {
				setEditHeaderDetails(false);
			}}
			inEditMode={editHeaderDetails}
			disabled={inspUpdatestatus === -1}>
			<Grid container>
				{getDetailGrid(
					t,
					pipeFields(t),
					hardCodeUnitFields,
					hardCodeDateTimeFields,
					hardCodeCurrencyFields,
					hardCodeNumericFields,
					hardCodeBooleanFields,
					isEditableField,
					isFullEditAllowedFlag,
					undefined,
					undefined,
					getDomainValues,
					getDomainItems,
				)}
			</Grid>
		</ModifiedInfoCard>
	);

	const manholes = (
		<ModifiedInfoCard
			title={t('Manholes')}
			dataCy="modified-info-card-manholes"
			showEditBtn={
				origDetails['inspectionStatus'] !== 'approved' &&
				onReRender !== undefined
			}
			launchDarkleyFlag={isEditAllowedFlag}
			onEditClick={() => {
				setEditHeaderDetails(true);
			}}
			onCancelClick={() => {
				setEditHeaderDetails(false);
			}}
			inEditMode={editHeaderDetails}
			disabled={inspUpdatestatus === -1}>
			<Grid container>
				{getDetailGrid(
					t,
					manholesFields(t),
					hardCodeUnitFields,
					hardCodeDateTimeFields,
					hardCodeCurrencyFields,
					hardCodeNumericFields,
					hardCodeBooleanFields,
					isEditableField,
					isFullEditAllowedFlag,
					undefined,
					undefined,
					getDomainValues,
					getDomainItems,
				)}
			</Grid>
		</ModifiedInfoCard>
	);

	const media = (
		<ModifiedInfoCard
			title={t('Media')}
			dataCy="modified-info-card-media"
			showEditBtn={
				origDetails['inspectionStatus'] !== 'approved' &&
				onReRender !== undefined
			}
			launchDarkleyFlag={isEditAllowedFlag}
			onEditClick={() => {
				setEditHeaderDetails(true);
			}}
			onCancelClick={() => {
				setEditHeaderDetails(false);
			}}
			inEditMode={editHeaderDetails}
			disabled={inspUpdatestatus === -1}>
			<Grid container>
				{getDetailGrid(
					t,
					mediaFields(t),
					hardCodeUnitFields,
					hardCodeDateTimeFields,
					hardCodeCurrencyFields,
					hardCodeNumericFields,
					hardCodeBooleanFields,
					isEditableField,
					isFullEditAllowedFlag,
					undefined,
					undefined,
					getDomainValues,
					getDomainItems,
				)}
			</Grid>
		</ModifiedInfoCard>
	);

	if (!isLoading && origDetails && !formSubmitted) {
		return (
			<StylovyzeFormV2
				mode={editHeaderDetails ? 'edit' : 'view'}
				initialValues={editHeaderDetails ? origDetails : viewDetails}
				enableReinitialize
				onSubmit={(data: FormikValues) => {
					setEditHeaderDetails(false);
					handleFormSubmit(data);
				}}>
				{({ handleSubmit }) => {
					return (
						<form onSubmit={handleSubmit}>
							<TabWrapper $height={683}>
								<Tabs
									orientation="vertical"
									variant="scrollable"
									value={tabValue}
									onChange={handleChangeTab}
									aria-label="manhole-attributes">
									<Tab
										label={t('General')}
										{...a11yProps(0)}
									/>
									<Tab
										label={t('Location')}
										{...a11yProps(1)}
									/>
									<Tab label={t('Pipe')} {...a11yProps(2)} />
									<Tab
										label={t('Manholes')}
										{...a11yProps(3)}
									/>
									<Tab label={t('Media')} {...a11yProps(4)} />
								</Tabs>
								<TabPanel value={tabValue} index={0}>
									{general}
								</TabPanel>
								<TabPanel value={tabValue} index={1}>
									{location}
								</TabPanel>
								<TabPanel value={tabValue} index={2}>
									{pipe}
								</TabPanel>
								<TabPanel value={tabValue} index={3}>
									{manholes}
								</TabPanel>
								<TabPanel value={tabValue} index={4}>
									{media}
								</TabPanel>
							</TabWrapper>
						</form>
					);
				}}
			</StylovyzeFormV2>
		);
	} else {
		return (
			<div style={{ padding: '25px' }}>
				<CircularProgress />
			</div>
		);
	}
};

export default MSCCInspectionProps;
