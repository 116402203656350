(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("highcharts"), require("uuid"), require("styled-components"), require("@innovyze/stylovyze"), require("react-i18next"), require("luxon"), require("i18next"), require("@mui/icons-material/KeyboardArrowUp"), require("@mui/icons-material/KeyboardArrowDown"), require("framer-motion"), require("@mui/material"), require("color"), require("rainbowvis.js"), require("immer"), require("axios"), require("browser-cookies"), require("axios-retry"), require("@mui/icons-material/"), require("lodash"), require("nanoid"), require("moment-timezone"), require("@mui/x-date-pickers-pro"), require("@mui/x-date-pickers-pro/AdapterLuxon"), require("@mui/material/styles"), require("@reduxjs/toolkit"), require("react-redux"), require("@innovyze/shared-utils"), require("mathjs"), require("redux"), require("redux-saga/effects"), require("@redux-saga/core/effects"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "highcharts", "uuid", "styled-components", "@innovyze/stylovyze", "react-i18next", "luxon", "i18next", "@mui/icons-material/KeyboardArrowUp", "@mui/icons-material/KeyboardArrowDown", "framer-motion", "@mui/material", "color", "rainbowvis.js", "immer", "axios", "browser-cookies", "axios-retry", "@mui/icons-material/", "lodash", "nanoid", "moment-timezone", "@mui/x-date-pickers-pro", "@mui/x-date-pickers-pro/AdapterLuxon", "@mui/material/styles", "@reduxjs/toolkit", "react-redux", "@innovyze/shared-utils", "mathjs", "redux", "redux-saga/effects", "@redux-saga/core/effects"], factory);
	else if(typeof exports === 'object')
		exports["summaryze"] = factory(require("react"), require("highcharts"), require("uuid"), require("styled-components"), require("@innovyze/stylovyze"), require("react-i18next"), require("luxon"), require("i18next"), require("@mui/icons-material/KeyboardArrowUp"), require("@mui/icons-material/KeyboardArrowDown"), require("framer-motion"), require("@mui/material"), require("color"), require("rainbowvis.js"), require("immer"), require("axios"), require("browser-cookies"), require("axios-retry"), require("@mui/icons-material/"), require("lodash"), require("nanoid"), require("moment-timezone"), require("@mui/x-date-pickers-pro"), require("@mui/x-date-pickers-pro/AdapterLuxon"), require("@mui/material/styles"), require("@reduxjs/toolkit"), require("react-redux"), require("@innovyze/shared-utils"), require("mathjs"), require("redux"), require("redux-saga/effects"), require("@redux-saga/core/effects"));
	else
		root["summaryze"] = factory(root["react"], root["highcharts"], root["uuid"], root["styled-components"], root["@innovyze/stylovyze"], root["react-i18next"], root["luxon"], root["i18next"], root["@mui/icons-material/KeyboardArrowUp"], root["@mui/icons-material/KeyboardArrowDown"], root["framer-motion"], root["@mui/material"], root["color"], root["rainbowvis.js"], root["immer"], root["axios"], root["browser-cookies"], root["axios-retry"], root["@mui/icons-material/"], root["lodash"], root["nanoid"], root["moment-timezone"], root["@mui/x-date-pickers-pro"], root["@mui/x-date-pickers-pro/AdapterLuxon"], root["@mui/material/styles"], root["@reduxjs/toolkit"], root["react-redux"], root["@innovyze/shared-utils"], root["mathjs"], root["redux"], root["redux-saga/effects"], root["@redux-saga/core/effects"]);
})(this, (__WEBPACK_EXTERNAL_MODULE__9155__, __WEBPACK_EXTERNAL_MODULE__9713__, __WEBPACK_EXTERNAL_MODULE__7315__, __WEBPACK_EXTERNAL_MODULE__8694__, __WEBPACK_EXTERNAL_MODULE__5836__, __WEBPACK_EXTERNAL_MODULE__5953__, __WEBPACK_EXTERNAL_MODULE__5796__, __WEBPACK_EXTERNAL_MODULE__9575__, __WEBPACK_EXTERNAL_MODULE__4386__, __WEBPACK_EXTERNAL_MODULE__7503__, __WEBPACK_EXTERNAL_MODULE__9286__, __WEBPACK_EXTERNAL_MODULE__4823__, __WEBPACK_EXTERNAL_MODULE__3075__, __WEBPACK_EXTERNAL_MODULE__5133__, __WEBPACK_EXTERNAL_MODULE__9470__, __WEBPACK_EXTERNAL_MODULE__2742__, __WEBPACK_EXTERNAL_MODULE__6326__, __WEBPACK_EXTERNAL_MODULE__6633__, __WEBPACK_EXTERNAL_MODULE__4351__, __WEBPACK_EXTERNAL_MODULE__6773__, __WEBPACK_EXTERNAL_MODULE__8199__, __WEBPACK_EXTERNAL_MODULE__6528__, __WEBPACK_EXTERNAL_MODULE__6321__, __WEBPACK_EXTERNAL_MODULE__4979__, __WEBPACK_EXTERNAL_MODULE__586__, __WEBPACK_EXTERNAL_MODULE__2096__, __WEBPACK_EXTERNAL_MODULE__2148__, __WEBPACK_EXTERNAL_MODULE__5628__, __WEBPACK_EXTERNAL_MODULE__7961__, __WEBPACK_EXTERNAL_MODULE__1954__, __WEBPACK_EXTERNAL_MODULE__8264__, __WEBPACK_EXTERNAL_MODULE__3756__) => {
return 