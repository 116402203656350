import React, { ReactElement } from 'react';
import styled from 'styled-components';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useGlobalization } from '../../../i18n';
import * as Collapsible from '.';

const CollapsibleTriggerStyles = {
  Root: styled.div`
    align-items: center;
    background-color: #f9fafb;
    display: flex;
    gap: 6px;
    padding: 6px 8px 6px 15px;
  `,
  Icon: styled.div`
    color: #758e94;
  `,
  Text: styled.div`
    color: #758e94;
    font-family:
      Roboto,
      Helvetica Neue,
      San Francisco,
      Segoe UI,
      sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
    font-size: 14px;
  `,
};

interface Props {
  text?: string;
}

export const CollapsibleTrigger = ({ text }: Props): ReactElement => {
  const { t } = useGlobalization();
  const context = Collapsible.useCollapsibleContext('SnapshotTrigger');
  const icon = context.open ? <ArrowUpIcon /> : <ArrowDownIcon />;

  return (
    <CollapsibleTriggerStyles.Root>
      <CollapsibleTriggerStyles.Text>
        {text ?? t('Snapshot')}
      </CollapsibleTriggerStyles.Text>
      <CollapsibleTriggerStyles.Icon>{icon}</CollapsibleTriggerStyles.Icon>
    </CollapsibleTriggerStyles.Root>
  );
};
