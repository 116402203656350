import { BoostOptions, numberFormat } from 'highcharts';
import { dataGroupingFunctions } from '../../../types/analyticFunction.types';
import { isSeriesVisible } from '../../../core/utils/hiddenSeries.utils';
import { Reading } from '../../../core/types/reading.types';
import { readingIndex } from '../../../core/utils/data.utils';

import type {
  MassBalanceDisplayOptions,
  MassBalanceSeries,
} from '../../../core/types/massBalance.types';
import type {
  Options,
  SeriesOptionsType,
  XAxisOptions,
  YAxisOptions,
} from 'highcharts';
import type { HiddenSeries, RangeSelection } from '../../../types/chartState';
import type { SensorDataRecords } from '../../../core/types/data.types';
import type { StackOptions } from '../../../core/components/StackedChart';
import { TFunc } from '../../../i18n/types';

export const massBalanceChartFont =
  'Roboto, Helvetica Neue, San Francisco, Segoe UI, sans-serif';

export const massBalanceChartPalette = {
  text: '#282930',
  secondaryText: '#758E94',
  mainBackground: '#FFFFFF',
  statsBackground: '#F9FAFB',
  inflow: '#00ABD1',
  outflow: '#758D95',
  storage: '#E56C43',
  usage: '#60DDFF',
  billing: '#83BD41',
  nrw: '#497F03',
  nrwRatio: '#EFB105',
};

export const massBalanceChannelToLegend = (
  t: TFunc
): Record<MassBalanceSeries['id'], string> => ({
  inflow: t('Inflow'),
  outflow: t('Outflow'),
  storage: t('Storage'),
  usage: t('Usage'),
  billing: t('Billing'),
  nrw: t('NRW'),
  nrwRatio: t('NRW%'),
});

export const massBalanceChannelToYAxisLabel = (
  t: TFunc
): Record<MassBalanceSeries['id'], string> => ({
  inflow: t('Inflow Volume'),
  outflow: t('Outflow Volume'),
  storage: t('Storage Volume'),
  usage: t('Usage Volume'),
  billing: t('Billing Volume'),
  nrw: t('NRW Volume'),
  nrwRatio: t('NRW%'),
});

export const getMassBalanceStats = (
  massBalanceSeries: MassBalanceSeries[],
  t: TFunc,
  sensorDataRecords?: SensorDataRecords,
  rangeSelection?: RangeSelection
): {
  key: string;
  label: string;
  value: string;
  hasNoData: boolean;
}[] => {
  const nrWRatioStats = {
    key: 'nrwRatio',
    label: massBalanceChannelToLegend(t)['nrwRatio'],
    value: 'Loading',
    hasNoData: true,
  };

  const asyncStats = massBalanceSeries
    .filter((series) => series.id !== 'nrwRatio') //calculate all except NRW%
    .map((series) => {
      const { sensorId, resolution } = series.dataSource;
      const asyncData = sensorDataRecords?.[sensorId]?.[resolution];
      const label = massBalanceChannelToLegend(t)[series.id];

      if (asyncData?.status !== 'resolved') {
        return {
          key: series.id,
          label,
          status: asyncData?.status ?? 'loading',
        };
      }

      if (asyncData.data.timestamps.length === 0) {
        return { key: series.id, label, status: 'rejected' };
      }

      const value = asyncData.data.timestamps.reduce((sum, timestamp) => {
        if (rangeSelection?.min !== undefined && rangeSelection.min > timestamp)
          return sum;
        if (rangeSelection?.max !== undefined && rangeSelection.max < timestamp)
          return sum;

        return sum + asyncData.data.measurements[timestamp]?.[0];
      }, 0);

      return {
        key: series.id,
        label,
        status: 'resolved',
        unit: asyncData.data.unit,
        value,
      };
    });

  const nrwValue = asyncStats.find((stats) => stats.key === 'nrw')?.value;
  const usageValue = asyncStats.find((stats) => stats.key === 'usage')?.value;

  if (nrwValue === undefined || usageValue === undefined || usageValue === 0) {
    nrWRatioStats.hasNoData = true;
    nrWRatioStats.value = t('No data available');
  } else {
    nrWRatioStats.hasNoData = false;
    nrWRatioStats.value = `${numberFormat((nrwValue / usageValue) * 100, 2)}%`;
  }

  return [
    ...asyncStats.map((stats) => {
      switch (stats.status) {
        case 'resolved':
          return {
            ...stats,
            hasNoData: false,
            value: `${numberFormat(stats.value!, 4)}${
              stats.unit ? ` ${stats.unit}` : ''
            }`,
          };
        case 'rejected':
          return {
            ...stats,
            hasNoData: true,
            value: t('No data available'),
          };
        default:
          return { ...stats, hasNoData: true, value: 'Loading' };
      }
    }),
    nrWRatioStats,
  ];
};

export const generateMassBalanceOptions = (
  sensorDataRecords: SensorDataRecords,
  isBoostModuleEnabled: boolean,
  massBalanceSeries: MassBalanceSeries[],
  hiddenSeries: HiddenSeries,
  t: TFunc,
  displayOptions?: MassBalanceDisplayOptions
): Options => {
  const usageSeries = massBalanceSeries.find((series) => series.id === 'usage');

  const usageDataRecord = usageSeries
    ? sensorDataRecords?.[usageSeries.dataSource?.sensorId]?.[
        usageSeries.dataSource?.resolution
      ]
    : null;

  const unit = usageDataRecord?.data?.unit ?? '';

  const boostOptions: BoostOptions = {
    enabled: isBoostModuleEnabled,
    useGPUTranslations: isBoostModuleEnabled,
  };

  const yAxisOptions: YAxisOptions[] = [
    {
      gridLineWidth: displayOptions?.showYGrid ? 1 : 0,
      opposite: false,
      title: { text: `Volume${unit ? ` (${unit})` : ''}` },
    },
    {
      opposite: true,
      gridLineWidth: displayOptions?.showYGrid ? 1 : 0,
      title: { text: 'NRW%' },
    },
  ];

  const xAxisOptions: XAxisOptions = {
    gridLineWidth: displayOptions?.showXGrid ? 1 : 0,
    type: 'datetime',
  };

  const seriesOptions: SeriesOptionsType[] = [];

  massBalanceSeries.forEach((series, seriesIndex) => {
    const { sensorId, resolution } = series.dataSource;

    const isNrwRatio = series.id === 'nrwRatio';

    const timestamps =
      sensorDataRecords?.[sensorId]?.[resolution]?.data?.timestamps ?? [];

    const measurements =
      sensorDataRecords?.[sensorId]?.[resolution]?.data?.measurements ?? {};

    seriesOptions.push({
      type: 'line',
      id: series.id,
      name: massBalanceChannelToLegend(t)[series.id],
      color: massBalanceChartPalette[series.id],
      yAxis: isNrwRatio && displayOptions?.overlay ? 1 : 0,
      custom: {
        resolution,
        sensorId,
        seriesIndex,
      },
      visible: isSeriesVisible(
        hiddenSeries,
        seriesIndex,
        series.id,
        massBalanceChannelToLegend(t)[series.id]
      ),
      data: timestamps.map((timestamp) => [
        timestamp,
        measurements[timestamp]?.[readingIndex[Reading['Close']]] ?? null,
      ]),
      dataGrouping: {
        enabled: !isBoostModuleEnabled,
        approximation: dataGroupingFunctions[Reading['Close']],
      },
      ...(isNrwRatio && {
        dashStyle: 'Dash',
      }),
    });
  });

  return {
    legend: { enabled: true },
    navigator: { enabled: true },
    rangeSelector: { enabled: true },
    boost: boostOptions,
    series: seriesOptions,
    xAxis: xAxisOptions,
    yAxis: yAxisOptions,
  };
};

export const generateMassBalanceStackOptions = (
  sensorDataRecords: SensorDataRecords,
  massBalanceSeries: MassBalanceSeries[],
  t: TFunc,
  displayOptions?: MassBalanceDisplayOptions
): StackOptions[] => {
  return massBalanceSeries.map((series) => {
    let yAxisLabel = massBalanceChannelToYAxisLabel(t)[series.id];
    const dataRecord =
      sensorDataRecords[series.dataSource.sensorId]?.[
        series.dataSource.resolution
      ];

    const unit = series.id === 'nrwRatio' ? undefined : dataRecord?.data?.unit;

    if (unit) {
      yAxisLabel += ` (${unit})`;
    }

    return {
      seriesId: series.id,
      options: {
        series: [
          {
            type: 'line',
            yAxis: 0,
          },
        ],
        yAxis: [
          {
            opposite: false,
            gridLineWidth: displayOptions?.showYGrid ? 1 : 0,
            title: { text: yAxisLabel },
          },
        ],
      },
    };
  });
};
