import { SENSOR_TYPE_UNITS_MAP, SensorType } from '@innovyze/shared-utils';
import {
  calculatePumpEfficiency,
  calculateSpecificEnergy,
} from '@innovyze/shared-utils/lib/pumpTools/PumpAnalytics';
import { ValueUnit } from '../../../types';

const getIsPowerUnit = (unit: string | undefined | null) => {
  if (unit === undefined || unit === null) {
    return false;
  }
  const powerUnits: unknown[] = SENSOR_TYPE_UNITS_MAP[SensorType.Power];
  const isPowerUnit = powerUnits.includes(unit);
  return isPowerUnit;
};

const hasValidValueAndUnit = (valueUnit: ValueUnit | undefined): boolean => {
  return (
    valueUnit?.value !== undefined &&
    valueUnit?.value !== null &&
    valueUnit?.unit !== undefined &&
    valueUnit?.unit !== null
  );
};

interface GetSpecificEnergyArgs {
  pumpFlow: ValueUnit;
  breakHP?: ValueUnit;
  seriesType: string;
}

export const getSpecificEnergy = ({
  pumpFlow,
  breakHP,
  seriesType,
}: GetSpecificEnergyArgs): ValueUnit => {
  if (
    !hasValidValueAndUnit(pumpFlow) ||
    !hasValidValueAndUnit(breakHP) ||
    pumpFlow?.value === 0 ||
    seriesType !== 'flow-pressure'
  ) {
    return { value: undefined, unit: undefined };
  }
  return calculateSpecificEnergy(
    pumpFlow.value ?? 0, // This is just to bypass the type check, the values are already validated
    pumpFlow.unit ?? '',
    breakHP?.value ?? 0,
    breakHP?.unit ?? ''
  );
};

interface GetPumpEfficiencyArgs {
  pumpFlow: ValueUnit;
  breakHP: ValueUnit;
  dischargePressure: ValueUnit;
  seriesType: string;
}
export const getPumpEfficiency = ({
  pumpFlow,
  breakHP,
  dischargePressure,
  seriesType,
}: GetPumpEfficiencyArgs): ValueUnit => {
  const isPowerUnit = getIsPowerUnit(dischargePressure?.unit);
  if (
    !hasValidValueAndUnit(pumpFlow) ||
    !hasValidValueAndUnit(breakHP) ||
    !hasValidValueAndUnit(dischargePressure) ||
    breakHP.value === 0 ||
    seriesType !== 'flow-pressure' ||
    isPowerUnit
  ) {
    return { value: undefined, unit: undefined };
  }
  return calculatePumpEfficiency(
    pumpFlow.value ?? 0, // This is just to bypass the type check, the values are already validated
    pumpFlow.unit ?? '',
    dischargePressure.value ?? 0,
    dischargePressure.unit ?? '',
    breakHP.value ?? 0,
    breakHP.unit ?? ''
  );
};
